import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Table,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input as RSInput
} from 'reactstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AddressesComponent = ({
  addressList,
  handleEdit,
  handleDelete,
  handleSetDefault,
  defaultAddressIndex,
  t,
  updateAddressList
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [initialAddress, setInitialAddress] = useState({
    street: '',
    city: '',
    postalCode: '',
    country: '',
    neighborhood: '',
    number: '',
    state: '',
    complement: ''
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const validationSchema = Yup.object().shape({
    postalCode: Yup.string().required(t('CEP é obrigatório')),
    street: Yup.string().required(t('Rua é obrigatória')),
    number: Yup.string().required(t('Número é obrigatório')),
    neighborhood: Yup.string().required(t('Bairro é obrigatório')),
    city: Yup.string().required(t('Cidade é obrigatória')),
    state: Yup.string().required(t('Estado é obrigatório')),
    complement: Yup.string()
  });

  const handleSaveAddress = (values) => {
    let updatedAddressList;
    let newDefaultIndex;
    if (isEditing && editIndex !== null) {
      updatedAddressList = addressList.map((address, index) =>
        index === editIndex ? values : address
      );
      newDefaultIndex = editIndex;
    } else {
      updatedAddressList = Array.isArray(addressList) ? [...addressList, values] : [values];
      newDefaultIndex = updatedAddressList.length - 1;
    }

    updateAddressList(updatedAddressList, newDefaultIndex);
    setIsEditing(false);
    setEditIndex(null);
    toggleModal();
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setIsEditing(true);
    setInitialAddress(addressList[index]);
    toggleModal();
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <div>
              <h5 className="text-muted d-flex justify-content-between">{t('Adresses')}</h5>
            </div>
            <Col md={12} className="d-flex justify-content-end">
              <FormGroup>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    setInitialAddress({
                      street: '',
                      city: '',
                      postalCode: '',
                      country: '',
                      neighborhood: '',
                      number: '',
                      state: '',
                      complement: ''
                    });
                    setIsEditing(false);
                    toggleModal();
                  }}>
                  {t('Adicionar Endereço')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Col>
            <div className="table-responsive mt-4">
              <Table className="table-light table-bordered condensed" style={{ marginBottom: 40 }}>
                <thead>
                  <tr>
                    <th>{t('Zip code')}</th>
                    <th>{t('Road')}</th>
                    <th>{t('Neighborhood')}</th>
                    <th>{t('Numbber')}</th>
                    <th>{t('City')}</th>
                    <th>{t('Complement')}</th>
                    <th>{t('Standard')}</th>
                    <th>{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {addressList &&
                    addressList.map((address, index) => (
                      <tr key={index}>
                        <td>{address.postalCode}</td>
                        <td>{address.street}</td>
                        <td>{address.neighborhood}</td>
                        <td>{address.number}</td>
                        <td>{address.city}</td>
                        <td>{address.complement}</td>
                        <td onClick={() => handleSetDefault(index)}>
                          {index === defaultAddressIndex ? (
                            <FaCheckCircle color="green" size={15} />
                          ) : (
                            <FaTimesCircle color="red" size={15} />
                          )}
                        </td>
                        <td>
                          <div>
                            <MdOutlineEdit
                              size={20}
                              style={{ marginRight: 9, color: '#0f52a4', cursor: 'pointer' }}
                              onClick={() => handleEditClick(index)}
                            />
                            <RiDeleteBin2Fill
                              size={20}
                              style={{ color: '#db0b0d', cursor: 'pointer' }}
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader>{isEditing ? t('Edit Address') : t('Add Adresse')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialAddress}
            validationSchema={validationSchema}
            onSubmit={handleSaveAddress}
            enableReinitialize>
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <FormGroup>
                  <Label for="postalCode">{t('Zip code')}</Label>
                  <RSInput
                    type="number"
                    name="postalCode"
                    id="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                  />
                  <ErrorMessage name="postalCode" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="street">{t('Road')}</Label>
                  <RSInput
                    type="text"
                    name="street"
                    id="street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                  />
                  <ErrorMessage name="street" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="number">{t('Number')}</Label>
                  <RSInput
                    type="number"
                    name="number"
                    id="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.number}
                  />
                  <ErrorMessage name="number" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="neighborhood">{t('Neighborhood')}</Label>
                  <RSInput
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.neighborhood}
                  />
                  <ErrorMessage name="neighborhood" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="city">{t('City')}</Label>
                  <RSInput
                    type="text"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  <ErrorMessage name="city" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="state">{t('State')}</Label>
                  <RSInput
                    type="text"
                    name="state"
                    id="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  />
                  <ErrorMessage name="state" component="div" className="text-danger" />
                </FormGroup>
                <FormGroup>
                  <Label for="complement">{t('Complement')}</Label>
                  <RSInput
                    type="text"
                    name="complement"
                    id="complement"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.complement}
                  />
                  <ErrorMessage name="complement" component="div" className="text-danger" />
                </FormGroup>
                <ModalFooter>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                  <Button type="button" color="secondary" onClick={toggleModal}>
                    {t('Cancel')}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(AddressesComponent);
