import {
  GET_TENANTS,
  GET_TENANTS_FAIL,
  GET_TENANTS_SUCCESS,
  ADD_NEW_TENANT,
  ADD_TENANT_SUCCESS,
  ADD_TENANT_FAIL,
  UPDATE_TENANT,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAIL,
  DELETE_TENANT,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_FAIL,

} from "./actionTypes"

export const getTenants = () => ({
  type: GET_TENANTS,
})

export const getTenantsSuccess = tenants => ({
  type: GET_TENANTS_SUCCESS,
  payload: tenants,
})

export const getTenantsFail = error => ({
  type: GET_TENANTS_FAIL,
  payload: error,
})

export const addNewTenant = tenant => ({
  type: ADD_NEW_TENANT,
  payload: tenant,
})

export const addTenantSuccess = tenant => ({
  type: ADD_TENANT_SUCCESS,
  payload: tenant,
})

export const addTenantFail = error => ({
  type: ADD_TENANT_FAIL,
  payload: error,
})

export const updateTenant = tenant => ({
  type: UPDATE_TENANT,
  payload: tenant,
})

export const updateTenantSuccess = tenant => ({
  type: UPDATE_TENANT_SUCCESS,
  payload: tenant,
})

export const updateTenantFail = error => ({
  type: UPDATE_TENANT_FAIL,
  payload: error,
})

export const deleteTenant = tenant => ({
  type: DELETE_TENANT,
  payload: tenant,
})

export const deleteTenantSuccess = tenant => ({
  type: DELETE_TENANT_SUCCESS,
  payload: tenant,
})

export const deleteTenantFail = error => ({
  type: DELETE_TENANT_FAIL,
  payload: error,
})

