/* IRONS */
export const GET_IRONS = "GET_IRONS"
export const GET_IRONS_SUCCESS = "GET_IRONS_SUCCESS"
export const GET_IRONS_FAIL = "GET_IRONS_FAIL"

/**
 * add IRON
 */
export const ADD_NEW_IRON = "ADD_NEW_IRON"
export const ADD_IRON_SUCCESS = "ADD_IRON_SUCCESS"
export const ADD_IRON_FAIL = "ADD_IRON_FAIL"

/**
 * Edit IRON
 */
export const UPDATE_IRON = "UPDATE_IRON"
export const UPDATE_IRON_SUCCESS = "UPDATE_IRON_SUCCESS"
export const UPDATE_IRON_FAIL = "UPDATE_IRON_FAIL"

/**
 * Delete IRON
 */
export const DELETE_IRON = "DELETE_IRON"
export const DELETE_IRON_SUCCESS = "DELETE_IRON_SUCCESS"
export const DELETE_IRON_FAIL = "DELETE_IRON_FAIL"

