import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { isEmpty, values } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import Message from "components/Message";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

import {
  getProfiles as onGetProfiles,
  addNewProfile as onAddNewProfile,
  updateProfile as onUpdateProfile,
  deleteProfile as onDeleteProfile,
} from "store/profiles/actions";

import { showMessage, hideMessage } from "store/message/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Profiles = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { profiles } = useSelector(state => ({
    profiles: state.Profile.profiles,
  }));

  const { error } = useSelector(state => ({error: state.Profile.error}));
    const [ messageAlert, setMessageAlert] = useState("");
    const [ typeMessage, setTypeMessage ] = useState("");

  const [modal, setModal] = useState(false);

  const [profileList, setProfileList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if ( error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(async () => {
    await dispatch(onGetProfiles());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(profiles)) {
      setProfileList(profiles);
    }
  }, [profiles]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (profile && profile.name) || '',
      description: (profile && profile.description) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      description: Yup.string().required("Please Enter With Description")
    }),
    onSubmit: (values) => {
        console.log('valores', values)
      if (isEdit) {
        const updateProfile = {
          id: profile ? profile.id : 0,
          name: values.name,
          description: values.description,
        };
        // update profile
        dispatch(onUpdateProfile(updateProfile));
        validation.resetForm();

          handleAsyncActions('Updated');

      } else {
        const newProfile = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          description: values["description"]
        };
        // save new profile
        dispatch(onAddNewProfile(newProfile));
        validation.resetForm();

      handleAsyncActions('Added');
      }
      toggle();
    },
  });

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: profiles.length, // replace later with size(orders),
    custom: true,
  };

  const ProfileColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
        dataField: "button-actions",
        text:"Action",
        formatter: (cellContent, profile) => (
            <div className="btn-group float-end gap-2">
            <Button
                type="button"
                color="success"
                size="sm"
                className=""
                onClick={() => handleModalPermissionClick(profile)}
              >
                <i className="fas fa-user-shield" />
              </Button>
              <Button
                type="button"
                color="warning"
                size="sm"
                className=""
                onClick={() => handleProfileClick(profile)}
              >
                <i className="fa fa-pen" />
              </Button>
              <Button
                type="button"
                color="danger"
                size="sm"
                className=""
                onClick={() => onClickDelete(profile)}
              >
                <i className="fa fa-trash" />
              </Button>
            </div>
          ),
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProfile(null);
    } else {
      setModal(true);
    }
  };

  const handleModalPermissionClick = (profile) => {
      history.push(`/profiles/${profile.id}/permissions`,)
  }

  const handleModalPlansClick = (profile) => {
    history.push(`/profiles/${profile.id}/plans`,)
}

  const handleProfileClick = (arg) => {
    const profile = arg;

    setProfile({
      id: profile.id,
      name: profile.name,
      description: profile.description,
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete profile
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (profile) => {
    setProfile(profile);
    setDeleteModal(true);
  };

  const handleDeleteProfile = () => {
    if (profile.id) {
      dispatch(onDeleteProfile(profile));
      onPaginationPageChange(1);
      setDeleteModal(false);

      handleAsyncActions('Deleted');
    }
  };


  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
        showingMessage(`Profile ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => { dispatch(hideMessage()) }, 2000)
  }

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setProfileList(
      profiles.filter(profile =>
        Object.keys(profile).some(key =>
          profile[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handleProfileClicks = () => {
    setProfileList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
        <Message title={messageAlert} type={typeMessage} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProfile}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <MetaTags>
          <title>Profile | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Profiles" breadcrumbItem="Profiles" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProfileColumns}
                    data={profiles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={profiles || []}
                        columns={ProfileColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleProfileClicks}>
                                    <i className="mdi mdi-plus me-1" />
                                    New Profile
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Profile"
                                      : "Add Profile"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                    >
                                      <Row >
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Name Profile")}</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ""}
                                              invalid={
                                                validation.touched.name && validation.errors.name ? true : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Description")}</Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.description || ""}
                                              invalid={
                                                validation.touched.description && validation.errors.description ? true : false
                                              }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-profile"
                                            >
                                              {props.t("Save")}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Profiles.propTypes = {
  profiles: PropTypes.array,
  onGetProfiles: PropTypes.func,
  onAddNewProfile: PropTypes.func,
  onDeleteProfile: PropTypes.func,
  onUpdateProfile: PropTypes.func,
};

export default Profiles;
