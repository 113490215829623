import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Message from 'components/Message';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getIrons as onGetIrons,
  addNewIron as onAddNewIron,
  updateIron as onUpdateIron,
  deleteIron as onDeleteIron
} from 'store/irons/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import ActionTable from 'components/ActionTable';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const Irons = (props) => {
  const dispatch = useDispatch();
  const { irons } = useSelector(function (state) {
    console.log(state);
    return {
      irons: state.Iron.irons
    };
  });

  const { error } = useSelector((state) => ({ error: state.Iron.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [ironList, setIronList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [iron, setIron] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    if (irons && !irons.length) {
      dispatch(onGetIrons());
    }
  }, [dispatch, irons]);

  useEffect(() => {
    setIronList(irons);
  }, [irons]);

  useEffect(() => {
    if (!isEmpty(irons)) {
      setIronList(irons);
    }
  }, [irons]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (iron && iron.name) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter With Iron Name')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateIron = {
          id: iron ? iron.id : 0,
          name: values.name
        };
        // update iron
        dispatch(onUpdateIron(updateIron));
        validation.resetForm();

        handleAsyncActions('Updated');
      } else {
        const newIron = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name']
        };
        // save new iron
        dispatch(onAddNewIron(newIron));
        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  //pagination customization
  const pageOptions = {

    totalSize: irons.length, // replace later with size(orders),
    custom: true
  };

  const IronColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      // Remova a linha hidden: true para exibir a coluna ID
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t("name")}`,
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: `${props.t("action")}`,

      formatter: (_cellContent, breed) => (
        <ActionTable
          editPermissions={['edit_irons']}
          deletePermissions={['destroy_irons']}
          handleDelete={() => handleDeleteIron(breed)}
          handleEdit={() => handleIronClick(breed)}
        />
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setIron(null);
    } else {
      setModal(true);
    }
  };

  const handleIronClick = (arg) => {
    const iron = arg;

    setIron({
      id: iron.id,
      name: iron.name
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteIron = (data) => {
    if (data.id) {
      dispatch(onDeleteIron(data));
      onPaginationPageChange(1);

      handleAsyncActions('Deleted');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Iron ${action} Successfully`, 'success');
    }
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setIronList(
      irons.filter((iron) =>
        Object.keys(iron).some((key) => iron[key].toLowerCase().includes(searchText.toLowerCase()))
      )
    );
  };

  const handleIronClicks = () => {
    setIronList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  /** set Date formate */
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />
        <MetaTags>
          <title>Iron | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t("Irons")} breadcrumbItem={props.t("Irons")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={IronColumns}
                    data={irons}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={irons || []}
                        columns={IronColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_irons']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleIronClicks}>
                                      <i className="mdi mdi-plus me-1" />
                                      {props.t("New Iron")}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <div className="float-sm-end">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div> */}
                              <Col xl="12">
                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns">
                                  <Table id="iron-1">
                                    <BootstrapTable
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={'table table-striped table-bordered'}
                                      keyField="id"
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      ref={node}
                                    />
                                  </Table>
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Iron' : 'Add Iron'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Name Iron")}</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-iron">
                                              {props.t("Save")}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </Col>
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Irons.propTypes = {
  irons: PropTypes.array,
  onGetIrons: PropTypes.func,
  onAddNewIron: PropTypes.func,
  onDeleteIron: PropTypes.func,
  onUpdateIron: PropTypes.func
};

export default withTranslation()(Irons);
