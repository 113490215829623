const ExcelJS = require('exceljs');

export function createWorkbook() {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'WshareFazendas';
  workbook.lastModifiedBy = 'WshareFazendas';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  return workbook;
}

export function createWorksheet(workbook, name) {
  const worksheet = workbook.addWorksheet(name);

  worksheet.headerFooter.differentFirst = true;
  worksheet.headerFooter.firstHeader = 'Hello Exceljs';
  worksheet.headerFooter.firstFooter = 'Hello World';
  return worksheet;
}
