export const URL_WORK_SPACE_NAME = 'nome-do-espaco';
export const URL_PERSONAL_DATA = 'dados-pessoais';
export const URL_ADDRESS_INFO = 'endereco';
export const URL_PAYMENT = 'pagamento';
export const URL_CONFIRM = 'confirmar';

export const URL_WORK_SPACE_NAME_INDEX = 0;
export const URL_PERSONAL_DATA_INDEX = 1;
export const URL_ADDRESS_INFO_INDEX = 2;
export const URL_PAYMENT_INDEX = 3;
export const URL_CONFIRM_INDEX = 4;

export const USE_CHECKOUT_STORAGE = '@payment/checkout';

export const getIndexUrls = () => ({
  [URL_WORK_SPACE_NAME]: URL_WORK_SPACE_NAME_INDEX,
  [URL_PERSONAL_DATA]: URL_PERSONAL_DATA_INDEX,
  [URL_ADDRESS_INFO]: URL_ADDRESS_INFO_INDEX,
  [URL_PAYMENT]: URL_PAYMENT_INDEX,
  [URL_CONFIRM]: URL_CONFIRM_INDEX
});

export const getUrls = () => ({
  [URL_WORK_SPACE_NAME_INDEX]: URL_WORK_SPACE_NAME,
  [URL_PERSONAL_DATA_INDEX]: URL_PERSONAL_DATA,
  [URL_ADDRESS_INFO_INDEX]: URL_ADDRESS_INFO,
  [URL_PAYMENT_INDEX]: URL_PAYMENT,
  [URL_CONFIRM_INDEX]: URL_CONFIRM
});

export const getPlansIndexName = {
  DAY: 'DAY',
  WEEKLY: 'WEEKLY',
  TRIALING: 'TRIALING',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const getPlansName = {
  DAY: 'Dia',
  WEEKLY: 'Semana',
  TRIALING: 'Trial',
  MONTH: 'Mês',
  YEAR: 'Ano'
};
