import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Breadcrumbs from 'components/Common/Breadcrumb';
import InventoryTable from './InvetoryTable';
import ModalInventory from './ModalInventory';
import { withTranslation } from 'react-i18next';

const Inventory = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t('Inventory')} | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Report')} breadcrumbItem={props.t('Inventory')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <InventoryTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Inventory.propTypes = {
  inventory: PropTypes.array,
  onGetInventory: PropTypes.func
};

export default withTranslation()(Inventory);

