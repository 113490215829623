import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator';
import { useParams, Link } from 'react-router-dom';

import Message from 'components/Message';
//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getPlanDetails as onGetPlanDetails,
  addNewPlanDetail as onAddNewPlanDetails,
  updatePlanDetail as onUpdatePlanDetail,
  deletePlanDetail as onDeletePlanDetail
} from 'store/plan-details/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { messages } from 'common/data';

const PlanDetails = (props) => {
  const dispatch = useDispatch();
  const { url } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const { planDetails } = useSelector(function (state, action) {

      return { planDetails: state.PlanDetails.planDetails }
  });

  const { error } = useSelector(function (state) {
    return { error: state.PlanDetails.error };
  });

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [planDetailList, setPlanDetailList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [planDetail, setPlanDetail] = useState(null);

  const { SearchBar } = Search;

  useEffect(() => {
    dispatch(onGetPlanDetails(url));
  }, [location]);

  useEffect(() => {
    if ( error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (planDetail && planDetail.name) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePlanDetail = {
          id: planDetail ? planDetail.id : 0,
          name: values.name
        };
        // update planDetail
        dispatch(onUpdatePlanDetail(url, updatePlanDetail));
        validation.resetForm();

        handleAsyncActions('Updated');
      } else {
        const newPlanDetail = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name']
        };
        // save new planDetail
        dispatch(onAddNewPlanDetails(url, newPlanDetail));
        validation.resetForm();

          handleAsyncActions('Added')

      }
      toggle();
    }
  });

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: 0, // replace later with size(orders),
    custom: true
  };

  const PlanDetailColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'button-actions',
      isDummyField: true,
      text: 'Action',
      formatter: (cellContent, planDetail) => (
        <div className="d-flex gap-3">
          <div className="btn-group float-end gap-2">
            <Button
              type="button"
              color="warning"
              size="sm"
              className=""
              onClick={() => handlePlanDetailClick(planDetail)}>
              <i className="fa fa-pen" />
            </Button>
            <Button
              type="button"
              color="danger"
              size="sm"
              className=""
              onClick={() => onClickDelete(planDetail)}>
              <i className="fa fa-trash" />
            </Button>
          </div>
        </div>
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPlanDetail(null);
    } else {
      setModal(true);
    }
  };

  const handlePlanDetailClick = (arg) => {
    const planDetail = arg;
    console.log(arg);

    setPlanDetail({
      id: planDetail.id,
      name: planDetail.name
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = (planDetail) => {
    setPlanDetail(planDetail);
    setDeleteModal(true);
  };

  const handleDeletePlanDetail = () => {
    if (planDetail.id) {
      dispatch(onDeletePlanDetail(url, planDetail));
      onPaginationPageChange(1);
      setDeleteModal(false);
      handleAsyncActions('Deleted');
    }
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
        showingMessage(`Plan Detail ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setPlanDetailList(
      planDetails.filter((planDetail) =>
        Object.keys(planDetail).some((key) =>
          planDetail[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handlePlanDetailClicks = () => {
    setPlanDetailList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  /** set Date formate */
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePlanDetail}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Plan Details | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Plan" breadcrumbItem={`Plan Details ${url}`} />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={PlanDetailColumns}
                    data={planDetails}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={planDetails || []}
                        columns={PlanDetailColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handlePlanDetailClicks}>
                                    <i className="mdi mdi-plus me-1" />
                                    {props.t("Details plan")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit PlanDetail' : 'Add PlanDetail'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Name")}</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-planDetail">
                                              {props.t("Save")}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col sm="6">
                                <Link
                                  to="/plans"
                                  className="btn text-muted d-none d-sm-inline-block btn-link">
                                  <i className="mdi mdi-arrow-left me-1" />
                                  {props.t("Back")}
                                </Link>
                              </Col>

                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PlanDetails.propTypes = {
  planDetails: PropTypes.array,
  onGetPlanDetails: PropTypes.func,
  onAddNewPlanDetail: PropTypes.func,
  onDeletePlanDetail: PropTypes.func,
  onUpdatePlanDetail: PropTypes.func
};

export default PlanDetails;
