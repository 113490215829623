import {

  GET_IRONS,
  GET_IRONS_FAIL,
  GET_IRONS_SUCCESS,
  ADD_NEW_IRON,
  ADD_IRON_SUCCESS,
  ADD_IRON_FAIL,
  UPDATE_IRON,
  UPDATE_IRON_SUCCESS,
  UPDATE_IRON_FAIL,
  DELETE_IRON,
  DELETE_IRON_SUCCESS,
  DELETE_IRON_FAIL,

} from "./actionTypes"

export const getIrons = () => ({
  type: GET_IRONS,
})

export const getIronsSuccess = irons => ({
  type: GET_IRONS_SUCCESS,
  payload: irons,
})

export const getIronsFail = error => ({
  type: GET_IRONS_FAIL,
  payload: error,
})

export const addNewIron = iron => ({
  type: ADD_NEW_IRON,
  payload: iron,
})

export const addIronSuccess = iron => ({
  type: ADD_IRON_SUCCESS,
  payload: iron,
})

export const addIronFail = error => ({
  type: ADD_IRON_FAIL,
  payload: error,
})

export const updateIron = iron => ({
  type: UPDATE_IRON,
  payload: iron,
})

export const updateIronSuccess = iron => ({
  type: UPDATE_IRON_SUCCESS,
  payload: iron,
})

export const updateIronFail = error => ({
  type: UPDATE_IRON_FAIL,
  payload: error,
})

export const deleteIron = iron => ({
  type: DELETE_IRON,
  payload: iron,
})

export const deleteIronSuccess = iron => ({
  type: DELETE_IRON_SUCCESS,
  payload: iron,
})

export const deleteIronFail = error => ({
  type: DELETE_IRON_FAIL,
  payload: error,
})

