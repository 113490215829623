import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import * as url from '../../helpers/url_helper';
import { showMessage, hideMessage } from 'store/message/actions';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback
} from 'reactstrap';

import Select from 'react-select';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Import Date Picker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Message from '../../components/Message';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getBreeds as onGetBreeds,
  getIrons as onGetIrons,
  getMovementTypes as onGetMovementTypes,
  getFarms as onGetFarms,
  addNewMovement as onAddNewMovement,
  getAnimalsByFarm as onGetAnimalsByFarm
} from 'store/actions';
import formatBytes from 'utils/formatBytes';
import { formatDate, removeDuplicates,formatDateByMoment } from 'utils/formats';
import axiosApi from 'helpers/api_helper';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';
import useMediaLibraryStore from 'zustand/media-library/file-upload-store';
import { withTranslation } from 'react-i18next';
import { sendBancoDadosPrice } from 'utils/inputMask';
import ReactInputMask from 'react-input-mask';

const configHeaderFile = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const MovementExit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { dataUpload } = useMediaLibraryStore();

  function handleChangeFilesMediaLibrary(dataUpload) {
    console.log({ dataUpload });
  }

  const [startDate, setStartDate] = useState(new Date());
  const [animalsInput, setAnimalsInput] = useState([]);

  const optionGroupEmpty = [{ label: '', options: [] }];

  const [isDropFile, setIsDropFile] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  const [selectedMovementType, setSelectedMovementType] = useState(null);
  const [optionGroupMovementType, setOptionGroupMovementType] = useState(optionGroupEmpty);

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [optionGroupFarm, setOptionGroupFarm] = useState(optionGroupEmpty);

  const [selectedDestinationFarm, setSelectedDestinationFarm] = useState(null);
  const [optionGroupDestinationFarm, setOptionGroupDestinationFarm] = useState(optionGroupEmpty);

  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [optionGroupAnimal, setOptionGroupAnimal] = useState(optionGroupEmpty);

  const [movement, setMovement] = useState(null);
  const [movementTypeId, setMovementTypeId] = useState(null);

  const { movementTypes } = useSelector((state) => ({
    movementTypes: state.MovementType.movementTypes
  }));
  const { farms } = useSelector((state) => ({ farms: state.Farm.farms }));

  const { animals } = useSelector((state) => ({ animals: state.Movement.animals }));

  const { error } = useSelector((state) => ({ error: state.Movement.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const initialAnimals = []
  const [filterAnimals, setFilterAnimals] = useState(initialAnimals);

  useEffect(async () => {
    await dispatch(onGetMovementTypes());
    await dispatch(onGetFarms());
  }, []);

  useEffect(() => {
    setOptionGroupFarm(formatDataForSelect('Farms', farms));
  }, [farms]);

  // useEffect(() => {
  //     setOptionGroupDestinationFarm(formatDataForSelect("Farms", farms));
  // }, [farms])

  useEffect(() => {
    setOptionGroupMovementType(
      formatDataForSelect(
        'Movement Types',
        movementTypes.filter((item) => item.operation == 'TS')
      )
    );
  }, [movementTypes]);

  useEffect(async () => {
    if (selectedFarm && selectedFarm.value > 0) {
      await dispatch(onGetAnimalsByFarm(selectedFarm.value));
    } else {
      setSelectedAnimal(optionGroupEmpty);
    }
  }, [selectedFarm]);

  useEffect(async () => {
    setOptionGroupDestinationFarm(optionGroupEmpty);
    setSelectedDestinationFarm(null);

    if (selectedFarm && selectedFarm.value > 0) {
      setOptionGroupDestinationFarm(
        formatDataForSelect(
          'Farms',
          farms.filter((item) => item.id !== selectedFarm.value)
        )
      );
    }
  }, [selectedFarm]);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    setOptionGroupAnimal([
      {
        label: `${props.t('Animals')}`,
        options: removeDuplicates(animals.filter((animal) => filterAnimalLogic(animal.id)).map((item) => {
          return {
            label: `${item.earring} - ${item.earring_eletric ? item.earring_eletric : ''}`,
            value: item.id
          };
        })
        )
      }
    ]);
  }, [animals, filterAnimals]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      collectName: (movement && movement.collectName) || '',
      Weight: (movement && movement.Weight) || '',
      weightTwo: (movement && movement.weightTwo) || ''
    },
    validationSchema: Yup.object({
      collectName: Yup.string()
    }),
    onSubmit: (values) => {
      if (isDropFile && selectedFiles.length <= 0) {
        showingMessage('Inserir arquivo obrigatório', 'warning');
        return;
      }

      if (animalsInput.length > 0) {
        for (var i = 0; animalsInput.length >= i; i++) {
          let animal = animalsInput[i];

          if (animal?.weight.length <= 0) {
            showingMessage('Preencha os campos do animal', 'warning');
            return;
          }
        }
      }

      if (!isDropFile && animalsInput.length <= 0) {
        showingMessage('Obrigatorio selecionar Importar ou Adicionar animal', 'warning');
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFiles[0]);
      formData.append('timestamp', (Date.now() / 1000) | 0);
      formData.append('movement_type_id', 7);
      formData.append('farm_id', selectedFarm.value);
      formData.append('destination_farm_id', selectedDestinationFarm.value);
      formData.append('movement_date', formatDateByMoment(startDate));
      formData.append('collect[name]', values.collectName);
      formData.append('collect[amount]', parseFloat(0));
      formData.append('collect[freight]', parseFloat(0));
      formData.append('collect[other_values]', parseFloat(0));
      formData.append('collect[arroba_price]', parseFloat(0));

      if (dataUpload.imagesForUpload.length) {
        dataUpload.imagesForUpload.map((dataFile) => formData.append(`fotos[]`, dataFile));
      }

      const animalsConvertPrice = animalsInput.map((row) => {
        row.weight = sendBancoDadosPrice(row.weight);
        return row;
      })


      if (!isDropFile) {
        formData.append('collect[animals]', JSON.stringify(animalsConvertPrice));
      } else {
        formData.append('file', selectedFiles[0]);
      }

      tryServerApi(formData, !isDropFile ? url.ADD_NEW_MOVEMENT : url.ADD_NEW_MOVEMENT_FILE);
    }
  });

  const tryServerApi = async (movementData, URL) => {
    try {
      const response = await axiosApi.post(URL, movementData, configHeaderFile);
      const { message } = response?.data || {};
      showingMessage(message || props.t('Transfer Created'), 'success');
      setTimeout(() => {
        history.push('/movements');
      }, 2000);
    } catch (error) {
      console.log(error);
      const { message } = error?.response?.data || {};
      showingMessage(message, 'warning');
    }
  };

  function formatDataForSelect(title, data = []) {
    if (data && data.length > 0) {
      return [
        {
          label: title,
          options: data.map((item) => {
            return { label: item.name, value: item.id };
          })
        }
      ];
    }
    return [
      {
        label: title,
        options: []
      }
    ];
  }

  // useEffect(() => {
  //     console.log('Animals2: ', animals);
  // }, [animals]);

  const startDateChange = (date) => {
    console.log('mudou data', date);
    validation.handleChange('movementDate');
    setStartDate(date);
  };

  const handleNumberChangeAdd = (idx, e) => {
    let newanimals = [...animalsInput];
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    newanimals[idx].weight = formattedValue;
    setAnimalsInput(newanimals);
    validation.handleChange({
      target: {
        name: `${idx}${e.target.name}`,
        value: formattedValue
      }
    });
  };


  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Transfer ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  // Function for Create Input Fields
  function handleAddFields() {
    let item1 = {
      animal_id: '',
      nickname: '',
      obs: '',
      weight: '',
      price: '',
      note: ''
    };
    setAnimalsInput([...animalsInput, item1]);
  }

  function handleAddWithouAnimalFields() {
    let item2 = {
      earring: '',
      nickname: '',
      obs: '',
      weightTwo: '',
      price: '',
      note: ''
    };
    setAnimalsInput([...animalsInput, item2]);
  }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    let newAnimals = [...animalsInput];
    let animalId = newAnimals[idx].animal_id;
    const animalFilter = filterAnimals.filter((animal) => animal !== animalId);
    setFilterAnimals(animalFilter);
    newAnimals.splice(idx, 1);
    setAnimalsInput(newAnimals);
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function handleSelectFarm(selectedFarm) {
    setSelectedFarm(selectedFarm);
  }

  function handleSelectDestinationFarm(selectedDestinationFarm) {
    setSelectedDestinationFarm(selectedDestinationFarm);
  }

  function handleSelectAnimal(selectedAnimal) {
    setSelectedAnimal(selectedAnimal);
  }

  function handleSelectMovementType(selectedMovementType) {
    setSelectedMovementType(selectedMovementType);
    setMovementTypeId(selectedMovementType.value);
  }

  function handleAddEarring(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].earring = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddPrice(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].price = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddWeight(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].weight = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddNote(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].note = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddAnimal(idx, value) {
    let newAnimals = [...animalsInput];
    const animalFilter = filterAnimals.filter((animal) => animal !== newAnimals[idx].animal_id);
    animalFilter.push(value);
    setFilterAnimals(animalFilter);
    newAnimals[idx].animal_id = value;
    setAnimalsInput(newAnimals);
  }

  const formatNumber = (value) => {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(cleanedValue / 100);
    return formattedValue;
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const handleDateChange = (e) => {
    setStartDate(e.target.value);
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const filterAnimalLogic = (row) => {
    if (!Array.isArray(filterAnimals)) {
      return true;
    }
    if (!filterAnimals.includes(row)) {
      return true;
    }

    return false
  }


  return (
    <>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />
        <MetaTags>
          <title>Create Movement | SistemasW - Fazenda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Transfer')} breadcrumbItem={props.t('Transfer')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t('Create New Movement')}</CardTitle>
                  <Form
                    id="formSubmit"
                    className="outer-repeater"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Collects')}</Label>
                                <Input
                                  id="collectName"
                                  name="collectName"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Entre com o nome da coleta')}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.collectName || ''}
                                  invalid={
                                    validation.touched.collectName && validation.errors.collectName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.collectName && validation.errors.collectName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.collectName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Farm Origin')}</Label>
                                <Select
                                  id="farmId"
                                  name="farmId"
                                  value={selectedFarm}
                                  onChange={handleSelectFarm}
                                  options={optionGroupFarm}
                                />
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Farm Destination')}</Label>
                                <Select
                                  id="farmId"
                                  name="farmId"
                                  value={selectedDestinationFarm}
                                  onChange={handleSelectDestinationFarm}
                                  options={optionGroupDestinationFarm}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Data')}</Label>
                                <ReactInputMask
                                  mask="99/99/9999"
                                  value={validation.values.movementDate || ''}
                                  onChange={handleDateChange}
                                  onBlur={validation.handleBlur}
                                  className="form-control">
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      id="movementDate"
                                      name="movementDate"
                                      placeholder="dd/mm/yyyy"
                                      invalid={
                                        validation.touched.movementDate &&
                                          validation.errors.movementDate
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                </ReactInputMask>
                                {validation.touched.movementDate &&
                                  validation.errors.movementDate ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.movementDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>

                        <div className="inner-repeater mb-4">
                          <div className="inner form-group mb-0">
                            <div>
                              <Label className="col-form-label col-lg-2">
                                {props.t('Add Animals')}
                              </Label>
                            </div>
                            <div className="inner col-lg-10 ml-md-auto" id="repeater">
                              {animalsInput.map((field, key) => (
                                <div
                                  key={key}
                                  id={'nested' + key}
                                  className="mb-3 row align-items-center">
                                  <FormGroup>
                                    <Row>
                                      {field.animal_id != undefined ? (
                                        <Col lg="2">
                                          <div className="md-3">
                                            <Label>{props.t('Animals')}</Label>
                                            <Select
                                              defaultValue={field.animal_id}
                                              value={selectedAnimal}
                                              onChange={(animal) => {
                                                handleSelectAnimal();
                                                handleAddAnimal(key, animal.value);
                                              }}
                                              options={optionGroupAnimal}
                                              classNamePrefix="select2-selection"
                                            />
                                          </div>
                                        </Col>
                                      ) : (
                                        ''
                                      )}

                                      {field.earring != undefined ? (
                                        <Col lg="2">
                                          <div className="md-3">
                                            <Label>{props.t('Earring')}</Label>

                                            <input
                                              type="text"
                                              className="inner form-control"
                                              defaultValue={field.earring}
                                              placeholder={props.t('Enter Earring')}
                                              onChange={() =>
                                                handleAddEarring(key, event.target.value)
                                              }
                                            />
                                          </div>
                                        </Col>
                                      ) : (
                                        ''
                                      )}
                                      <Col lg="2">
                                        <div className="md-3">
                                          <Label>{props.t('Weight')}</Label>
                                          <Input
                                            id="weight"
                                            name="weight"
                                            className="form-control"
                                            placeholder={props.t('Enter Weight')}
                                            value={validation.values[`${key}weight`]}
                                            onChange={(e) => handleNumberChangeAdd(key, e)}
                                            onBlur={validation.handleBlur}

                                            invalid={
                                              validation.touched[`${key}weight`] && validation.errors[`${key}weight`]
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched[`${key}weight`] &&
                                            validation.errors[`${key}weight`] ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors[`${key}weight`]}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>


                                      <Col lg="2">
                                        <div className="md-3">
                                          <Label>{props.t('Enter Note')}</Label>
                                          <input
                                            type="text"
                                            className="inner form-control"
                                            defaultValue={field.note}
                                            placeholder={props.t('Enter Note')}
                                            onChange={() => handleAddNote(key, event.target.value)}
                                          />
                                        </div>
                                      </Col>
                                      <Col lg="1">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <div style={{ height: 26 }}></div>
                                          <Button
                                            color="danger"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(key);
                                            }}
                                            block>
                                            <i className="mdi mdi-trash-can"></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </FormGroup>

                                  <FormGroup className=" row" row></FormGroup>
                                </div>
                              ))}
                            </div>
                          </div>

                          {isDropFile && (
                            <>
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t('Drop files here or click to upload')}.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Row>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}>
                                        <Row>
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col style={{ marginLeft: 20 }}>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold">
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{props.t(f.formattedSize)}</strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Row>
                                      </Card>
                                    );
                                  })}
                                </div>
                                {selectedFiles.length > 0 && (
                                  <Col md="2" style={{ marginTop: 10, marginBottom: 25 }}>
                                    <div className="md-4">
                                      <Button
                                        color="danger"
                                        className="inner form-control"
                                        onClick={() => {
                                          setselectedFiles([]);
                                          setIsDropFile(false);
                                        }}
                                        block>
                                        <i className="mdi mdi-trash-can"></i>
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </>
                          )}

                          <Row className="justify-content-start">
                            <Col lg="10">
                              <Button
                                color="success"
                                className="inner "
                                onClick={() => {
                                  isDropFile ? null : handleAddFields();
                                }}>
                                {props.t('Add Animals')}
                              </Button>

                              <Button
                                color="success"
                                className="inner"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  isDropFile ? null : handleAddWithouAnimalFields();
                                }}>
                                {props.t('Add Animals2')}
                              </Button>

                              <Button
                                color="success"
                                className="inner"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  animalsInput.length > 0 ? null : setIsDropFile((v) => !v);
                                }}>
                                {props.t('Import')}
                              </Button>
                              {isDropFile > 0 && (
                              <Button
                                color="primary"
                                className="inner"
                                style={{ marginLeft: 10 }}
                                onClick={()  =>  window.open(`${process.env.REACT_APP_SYSTEM_URL.replace('/api/v1','')}/import/iron_import_o.csv`)}
                               >
                                {props.t('Download Sheet')}
                              </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t('Photo gallery')}</CardTitle>
                  <FormGroup>
                    <Row form>
                      <MediaLibrary
                        resource={'collect'}
                        isSendForCreate
                        isEditableItemName
                        onChange={handleChangeFilesMediaLibrary}
                      />
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <div className="mt-4" style={{ textAlign: 'right' }}>
                        <button type="submit" form="formSubmit" className="btn btn-primary ">
                          {isDropFile ? 'Importar Arquivos' : 'Salvar'}
                        </button>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="text-left mt-4">
                        <button
                          className="btn btn-danger"
                          onClick={() => history.push('/movements')}>
                          {props.t('Cancel')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

MovementExit.propTypes = {
  breeds: PropTypes.array,
  irons: PropTypes.array,
  farms: PropTypes.array,
  animals: PropTypes.array,
  onGetBreeds: PropTypes.func,
  onGetIrons: PropTypes.func,
  onGetFarms: PropTypes.func,
  onGetAnimalsByFarm: PropTypes.func,
  addNewMovement: PropTypes.func
};
export default withTranslation()(MovementExit);
