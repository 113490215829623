import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Title from '../Title';
import { FormGroup, Input } from 'reactstrap';
import Footer from '../Footer';

import * as Yup from 'yup';

import useCheckoutStore from 'zustand/checkout';
import ReactInputMask from 'react-input-mask';

// Esquema de validação usando Yup
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[\w]+[\s][\w]+$/, 'Nome completo é obrigatório') // Garante nome e sobrenome
    .required('Nome completo é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  cpf: Yup.string()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .required('CPF é obrigatório')
});

const PersonalData = () => {
  const { setPersonalData, setStep, personalData, workspace } = useCheckoutStore();

  const handleSubmit = async (data, { setSubmitting }) => {
    // Se o CPF for válido, procede com a submissão dos dados
    setPersonalData(data);
    setStep(2);
  };

  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <Formik
        initialValues={{
          ...workspace,
          ...personalData
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema} // Adiciona o esquema de validação
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <Title title="Fale sobre você" />
              <div
                style={{
                  marginBottom: '1.5rem'
                }}>{` Preciso dos dados do responsável financeiro da fazenda https://${workspace.workspaceName}.wsharefazendas.com.br`}</div>
            </div>
            <FormGroup>
              <Field name="fullName">
                {({ field }) => (
                  <div>
                    <Input
                      {...field}
                      type="text"
                      color="muted"
                      placeholder="Nome completo do responsável"
                      maxLength={50}
                    />
                    <small style={{ marginTop: '7px', marginLeft: '3px' }}>
                      Nome completo do responsável da fazenda
                    </small>
                  </div>
                )}
              </Field>
              <ErrorMessage name="fullName" component="div" className="error" />
            </FormGroup>
            <FormGroup>
              <Field name="email">
                {({ field }) => (
                  <div>
                    <Input
                      {...field}
                      type="text"
                      color="muted"
                      placeholder="E-mail do responsável"
                      maxLength={30}
                    />
                    <small style={{ marginTop: '7px', marginLeft: '3px' }}>
                      Melhor e-mail para fazenda
                    </small>
                  </div>
                )}
              </Field>
              <ErrorMessage name="email" component="div" className="error" />
            </FormGroup>
            <FormGroup>
              <Field name="cpf">
                {({ field }) => (
                  <div>
                    <ReactInputMask {...field} mask="999.999.999-99">
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          type="text"
                          color="muted"
                          placeholder="CPF do responsável"
                        />
                      )}
                    </ReactInputMask>

                    {/* <Input
                      {...field}
                      type="text"
                      color="muted"
                      placeholder="CPF do responsável"
                      maxLength={14}
                    /> */}
                    <small style={{ marginTop: '7px', marginLeft: '3px' }}>
                      Documento CPF do responsável da fazenda
                    </small>
                  </div>
                )}
              </Field>
              <ErrorMessage name="cpf" component="div" className="error" />
            </FormGroup>
            <Footer textButton="Continuar" buttonPrevius={'<- Voltar'} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalData;
