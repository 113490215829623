import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchAnalytic from './Filters/DroopFilterAnalytical/SearchAnalytic';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import Loading from 'components/Loading';

import {
  currencyValueMask,
  decimalValueMask,
  formatPrice,
  formaIntBr,
  isObjectExport,
  intMask,
  formatDateBr
} from '../../../utils/inputMask';
import axiosApi from 'helpers/api_helper';

import Message from 'components/Message';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import {
  getAnalyticGains as onGetAnalyticGains,
  setisLoadingGains
} from 'store/reports/gains/actions';

import { showMessage, hideMessage } from 'store/message/actions';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import DroopFAnalytic from './Filters/DroopFilterAnalytical/DroopAnalytic';
import { withTranslation } from 'react-i18next';
import pdfSvg from './Icons/pdf.svg';
import sheetSvg from './Icons/sheet.svg';
import Logo from '../../../assets/images/wshare.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { createWorkbook, createWorksheet } from 'lib/exceljs/Services';

const AnalyticGains = (props) => {
  const dispatch = useDispatch();

  const [itens, setItens] = useState([]);

  const { analyticGains } = useSelector((state) => ({
    analyticGains: state.ReportGains.analyticGains
  }));

  const { analyticGainsFiltered } = useSelector((state) => ({
    analyticGainsFiltered: state.ReportGains.analyticFilteredGains
  }));

  const { isLoadingGains } = useSelector((state) => ({
    isLoadingGains: state.ReportGains.isLoadingGains
  }));

  const { analyticFiltered } = useSelector((state) => ({
    analyticFiltered: state.ReportGains.analyticFiltered
  }));

  const { error } = useSelector((state) => ({ error: state.ReportGains.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      // showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(setisLoadingGains(true));
    dispatch(onGetAnalyticGains());
  }, [dispatch]);

  useEffect(() => {
    if (analyticGains.itens && analyticGains.itens.length > 0) {
      if (analyticFiltered) {
        setItens(analyticGainsFiltered);
      } else {
        setItens(analyticGains.itens);
      }
    }
  }, [analyticGains, analyticGainsFiltered, analyticFiltered]);

  // Pagination customization
  const pageOptions = {
    totalSize: itens.length,
    custom: true
  };

  const AnalyticGainColumns = [
    {
      text: 'id',
      dataField: 'id',
      hidden: false,
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'earring',
      text: `${props.t('Earring')}`
    },
    {
      dataField: 'final_weight',
      text: `${props.t('Final weight')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.final_weight)}</>
    },
    {
      dataField: 'final_date',
      text: `${props.t('Final date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.final_date)}</>
    },
    {
      dataField: 'start_weight',
      text: `${props.t('Start weight')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.start_weight)}</>
    },
    {
      dataField: 'start_date',
      text: `${props.t('Start date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.start_date)}</>
    },
    {
      dataField: 'difference_days',
      text: `${props.t('Difference days')}`,
      formatter: (cellContent, row) => <>{formaIntBr(row.difference_days)}</>
    },
    {
      dataField: 'gains_kg',
      text: `${props.t('Gains kg')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.gains_kg)}</>
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Gains year arroba')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.gains_year_arroba)}</>
    },
    {
      dataField: 'note',
      text: `${props.t('Note')}`,
      formatter: (cellContent, row) => <>{row.note}</>
    },
    {
      dataField: 'collect_name',
      text: `${props.t('Collect')}`,
      formatter: (cellContent, row) => <>{row.collect_name}</>
    },
    {
      dataField: 'collect_name',
      text: `${props.t('Collect')}`,
      formatter: (cellContent, row) => <>{row.collect2_name}</>
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Actions')}`, // 'Ganho médio por gado em @ por ano',
      headerStyle: (colum, colIndex) => {
        return { textAlign: 'center' };
      },
      //   sort: true
      formatter: (cellContent, row) => (
        <div style={{ width: '90px' }}>
          <Row>
            <Col>
              <img
                src={pdfSvg}
                style={{ cursor: 'pointer' }}
                width={'32px'}
                height={'32px'}
                onClick={() => handleSaveAsPDF(row)}></img>
            </Col>
            <Col className="">
              <img
                src={sheetSvg}
                style={{ cursor: 'pointer' }}
                width={'32px'}
                height={'32px'}
                onClick={() => handleSaveAsXlsx(row)}></img>
            </Col>
          </Row>
        </div>
      )
    }
  ];

  const handleSaveAsXlsx = async (row) => {
    const dataHoraAtual = row.start_date ? new Date(row.start_date) : undefined;
    const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
    const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
    const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, `Relatório Análise Sintética ${row.collect_name}`);
    const response = await axiosApi.get(`reports/analytic-gains-report/${row.collect_id2}`);
    const { data } = response.data;
    worksheet.columns = [
      { header: `${props.t('Earring')}`, key: 'earring', width: 30 },
      { header: `${props.t('Final weight')}`, key: 'final_weight', width: 30 },
      { header: `${props.t('Final date')}`, key: 'final_date', width: 30 },
      { header: `${props.t('Start weight')}`, key: 'start_weight', width: 30 },
      { header: `${props.t('Start date')}`, key: 'start_date', width: 30 },
      { header: `${props.t('Difference days')}`, key: 'difference_days', width: 30 },
      { header: `${props.t('Gains kg')}`, key: 'gains_kg', width: 30 },
      { header: `${props.t('Gains year arroba')}`, key: 'gains_year_arroba', width: 30 }
    ];

    let bodyTemp = [];
    data.map((item, key) => {
      const itensTemp = item.itens.map((collect) => {
        const collectTemp = {
          earring: collect.earring,
          final_weight: formatPrice(collect.final_weight),
          final_date: formatDateBr(collect.final_date),
          start_weight: formatPrice(collect.start_weight),
          start_date: formatDateBr(collect.start_date),
          difference_days: formaIntBr(collect.difference_days),
          gains_kg: formatPrice(collect.gains_kg),
          gains_year_arroba: formatPrice(collect.gains_year_arroba)
        }
        return  collectTemp;
        
      });
      const subTotalTemp = Object.create([
        {
          earring: 'Subtotal',
          final_weight: '',
          final_date: '',
          start_weight: '',
          start_date: '',
          difference_days: formaIntBr(item.total_difference_days),
          gains_kg: formatPrice(item.total_gains_kg),
          gains_year_arroba: formatPrice(item.total_gains_year_arroba)
        }
      ]);

      bodyTemp = [...bodyTemp, ...itensTemp, ...subTotalTemp];
    });

    console.log(bodyTemp);

    bodyTemp.map((item) => worksheet.addRow(item));
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `analytic-${row.collect_name}-${ano}-${mes}-${dia}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleSaveAsPDF = (row) => {
    const doc = new jsPDF();
    const dataHoraAtual = row.start_date ? new Date(row.start_date) : undefined;
    const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
    const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
    const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';
    const logoImg = new Image();
    logoImg.onload = async function () {
      const width = 40; // Largura desejada em pixels
      const aspectRatio = logoImg.height / logoImg.width;
      const height = width * aspectRatio;
      doc.addImage(logoImg, 'PNG', 10, 10, width, height);
      // Adiciona a data com espaçamento
      const currentDate = formatDateBr(row.final_date);
      doc.text(`Data: ${currentDate}`, 134, 20);
      doc.setFontSize(13); // Define o tamanho da fonte para 13px
      const response = await axiosApi.get(`reports/analytic-gains-report/${row.collect_id2}`);
      const { data } = response.data;

      doc.text('Relatório Análise de Ganho', 105, 37, null, null, 'center');
      doc.text(`${row.collect2_name}`, 105, 43, null, null, 'center');
      let bodyTemp = [];
      data.map((item, key) => {
        console.log('item>>', item);
        const itensTemp = item.itens.map((collect) => {
          return [
            collect.earring,
            formatPrice(collect.final_weight),
            formatDateBr(collect.final_date),
            formatPrice(collect.start_weight),
            formatDateBr(collect.start_date),
            formaIntBr(collect.difference_days),
            formatPrice(collect.gains_kg),
            formatPrice(collect.gains_year_arroba)
          ];
        });
        const subTotalTemp = Object.create([
          [
            { content: 'Subtotal', styles: { fontStyle: 'bold', fontSize: 12 } },
            '',
            { content: '', styles: { fontStyle: 'bold' } },
            '',
            '',
            { content: formaIntBr(item.total_difference_days), styles: { fontStyle: 'bold' } },
            { content: formatPrice(item.total_gains_kg), styles: { fontStyle: 'bold' } },
            { content: formatPrice(item.total_gains_year_arroba), styles: { fontStyle: 'bold' } }
          ]
        ]);

        bodyTemp = [...bodyTemp, ...itensTemp, ...subTotalTemp];
      });
      console.log(bodyTemp);
      doc.autoTable({
        head: [
          [
            `${props.t('Earring')}`,
            `${props.t('Final weight')}`,
            `${props.t('Final date')}`,
            `${props.t('Start weight')}`,
            `${props.t('Start date')}`,
            `${props.t('Difference days')}`,
            `${props.t('Gains kg')}`,
            `${props.t('Gains year arroba')}`
          ]
        ],
        body: bodyTemp,
        startY: 50
      });
      doc.setProperties({
        title: `analytic-${ano}-${mes}-${dia}-${row.collect_name}.pdf`
      });
      doc.output('dataurlnewwindow');
      // doc.save(`synthetic-${ano}-${mes}-${dia}-${row.collect_name}.pdf`);
    };
    logoImg.src = Logo;
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  const handleTableChange = (type, { page, searchText }) => {
    // console.log('filter ',type)
    setAnalyticGainList(
      itens.filter((analyticGain) =>
        Object.keys(analyticGain).some((key) =>
          analyticGain[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>AnalyticGain | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Report')} breadcrumbItem={props.t('Analytic gains')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {isLoadingGains == true ? (
                    <Loading isLoading={isLoadingGains}></Loading>
                  ) : (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={AnalyticGainColumns}
                      data={itens}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={itens || []}
                          columns={AnalyticGainColumns}
                          bootstrap4
                          search>
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <DroopFAnalytic />
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive mb-0"
                                      data-pattern="priority-columns">
                                      <Table id="itens-1">
                                        <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={'table table-striped table-bordered'}
                                          keyField="id"
                                          {...toolkitProps.baseProps}
                                          onTableChange={handleTableChange}
                                          {...paginationTableProps}
                                          ref={node}
                                        />
                                      </Table>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                  <div className="pagination-size-dropdown">
                                    <SizePerPageDropdownStandalone
                                      onPageChange={(value) => console.log(value)}
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>

                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AnalyticGains.propTypes = {
  analyticGains: PropTypes.array,
  onGetAnalyticGains: PropTypes.func
};

export default withTranslation()(AnalyticGains);
