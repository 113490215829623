import {
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,

  GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS,
  GET_ROLE_PERMISSIONS_AVAILABLES_FAIL,

  ADD_ROLE_PERMISSION_SUCCESS,
  ADD_ROLE_PERMISSION_FAIL,
  
  DELETE_ROLE_PERMISSION_SUCCESS,
  DELETE_ROLE_PERMISSION_FAIL
} from "./actionTypes";

const INIT_STATE = {
  rolePermissions: [],
  permissionsAvailable: [],
  error: {},
};

const RolePermissions = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        state,
        rolePermissions: action.payload,
      };

    case GET_ROLE_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS:
      return {
        state,
        permissionsAvailable: action.payload,
      };

    case GET_ROLE_PERMISSIONS_AVAILABLES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        rolePermissions: [...state.rolePermissions, action.payload],
      };

    case ADD_ROLE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
          rolePermissions: state.rolePermissions.filter(
          rolePermissions => rolePermissions.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ROLE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RolePermissions;
