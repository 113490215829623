import { USE_CHECKOUT_STORAGE, getIndexUrls, getUrls } from 'constants/checkout';
import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCheckoutStore = create(
  persist(
    (set) => ({
      step: 0,
      maxStep: 6,
      setStep: (step) => set({ step }),

      workspace: {
        workspaceName: ''
      },
      setWorkspace: (workspace) => set({ workspace }),

      personalData: {
        fullName: '',
        email: '',
        cpf: ''
      },
      setPersonalData: (personalData) => set({ personalData }),

      address: {
        cep: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: ''
      },
      setAddress: (address) => set({ address }),

      payment: {
        cardNumber: '',
        cardHolder: '',
        expiryDate: '',
        cvv: '',
        type: 'credit_card',
        boleto_url: undefined,
        boleto_number: undefined
      },

      setPayment: (payment) => set({ payment })
    }),
    {
      name: 'checkout-storage', // unique name
      getStorage: () => sessionStorage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useCheckoutStore;
