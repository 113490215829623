import React, { useRef, useEffect, useCallback } from 'react';

import { Row, Col } from 'reactstrap';
import { useMediaLibraryStore } from '../../zustand/media-library/file-upload-store';
import MediaItem from './MediaItem';
import moment from 'moment';

const MediaLibrary = ({
  resource = false,
  resourceId = false,
  isListOfFile = false,
  isFileButtonUpload,
  isSendForCreate = false,
  isClean = false,
  isEditableItemName = false,
  isSelectFiles = true,
  collect = 'files',
  onChange = (dataUpload) => dataUpload
}) => {
  const mediaLibrary = useMediaLibraryStore();

  const {
    dataUpload,
    setDataUpload,
    addLibraries,
    cleanFiles,
    getLibraries,
    libraries,
    isLoading
  } = mediaLibrary;

  useEffect(() => {
    onChange(dataUpload);
  }, [dataUpload]);

  const tryListOfFile = useCallback(async () => {
    if (isListOfFile) {
      await getLibraries(resource, resourceId, collect);
    }
  }, [isListOfFile]);

  useEffect(() => {
    tryListOfFile();
  }, [tryListOfFile]);

  const provisioningSize = (sizeInByte) => {
    if (sizeInByte / 1024 < 1024) {
      return `${(sizeInByte / 1024).toFixed(0)}kb`;
    }

    if (sizeInByte / 1e6 >= 1) {
      return `${(sizeInByte / 1e6).toFixed(0)}mb`;
    }

    return `${(sizeInByte / 1024).toFixed(0)}kb`;
  };

  const selectFiles = (event) => {
    let images = [];
    let imagesForUpload = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
      imagesForUpload.push(event.target.files[i]);
    }

    const filesList = [];

    Array.from(event.target.files).forEach((file, index) => {
      filesList.push({
        name: file.name,
        type: file.type,
        size: file.size,
        sizeInKb: file.size / 1024,
        sizeInMb: file.size / 1e6,
        sizeInGb: file.size / 1e9,
        humanSize: provisioningSize(file.size),
        lastModifiedDate: moment(file.lastModified).format('DD/MM/YYYY HH:mm:ss'),
        lastModified: file.lastModified,
        url: images[index],
        webkitRelativePath: file.webkitRelativePath,
        classOfFile: file
      });
    });

    setDataUpload({
      progressInfos: [],
      message: [],
      selectedFiles: filesList,
      previewImages: images,
      imagesForUpload
    });
  };

  const uploadFiles = () => {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    setDataUpload({
      progressInfos: _progressInfos,

      message: []
    });

    if (isSendForCreate) {
      return;
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      upload(i, selectedFiles[i]);
    }
  };

  const upload = (idx, file) => {
    let _progressInfos = [...this.state.progressInfos]; // axiosApi

    addLibraries(
      file,
      (event) => {
        _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
        setDataUpload({
          ...dataUpload,
          progressInfos: _progressInfos
        });
      },
      resource,
      resourceId
    )
      .then(({ data }) => {
        let nextMessage = [...dataUpload.message, 'Uploaded the image successfully: ' + file.name];
        setDataUpload({
          ...dataUpload,
          message: nextMessage
        });

        return getLibraries();
      })
      .then((files) => {
        setDataUpload({
          ...dataUpload,
          imageInfos: files.data
        });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;

        let nextMessage = [...dataUpload.message, `Uploaded the image successfully: ${file.name}`];
        setDataUpload({
          ...dataUpload,
          progressInfos: _progressInfos,
          message: nextMessage
        });
      });
  };

  const { selectedFiles, progressInfos, message } = dataUpload;

  const ref = useRef(null);

  return (
    <div>
      <div className="row mb-3">
        {isSelectFiles && (
          <div className="col-8">
            <label className="btn btn-default p-0" style={{ display: 'none' }}>
              <input
                ref={ref}
                type="file"
                id="selectedFiles"
                multiple
                accept="image/*"
                onChange={selectFiles}
              />
            </label>
            <button
              className="btn btn-success btn-md mr-2"
              type="button"
              style={{ marginRight: 10 }}
              disabled={!selectedFiles}
              onClick={() => ref.current?.click()}
              color="primary">
              Selecionar fotos
            </button>
            {isClean && (
              <button
                className="btn btn-danger btn-md ml-2"
                disabled={!selectedFiles}
                type="button"
                onClick={() => cleanFiles()}
                color="primary">
                Limpar fotos
              </button>
            )}
          </div>
        )}

        {isFileButtonUpload && (
          <div className="col-4">
            <button
              className="btn btn-primary btn-lg"
              disabled={!selectedFiles}
              onClick={uploadFiles}>
              Enviar fotos
            </button>
          </div>
        )}
      </div>

      {progressInfos &&
        progressInfos.map((progressInfo, index) => (
          <div className="mb-2" key={index}>
            <span>{progressInfo.fileName}</span>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progressInfo.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progressInfo.percentage + '%' }}>
                {progressInfo.percentage}%
              </div>
            </div>
          </div>
        ))}

      {!isListOfFile && selectedFiles && (
        <Row>
          {selectedFiles.map((file, index) => {
            return (
              <Col md={6} lg={4} xs={1} key={index + 1}>
                <MediaItem
                  key={index}
                  imageName={file.name}
                  size={file.humanSize}
                  imageUrl={file.url}
                  isEditableInput={isEditableItemName}
                  imageLastModified={file.lastModifiedDate}
                />
              </Col>
            );
          })}
        </Row>
      )}

      {message.length > 0 && (
        <div className="alert alert-secondary" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}

      {isListOfFile && !isLoading && libraries?.length > 0 && (
        <Row>
          {libraries?.map((file, index) => {
            return (
              <Col md={6} lg={4} xs={1} key={index + 1}>
                <MediaItem
                  key={index}
                  imageName={file.name}
                  size={provisioningSize(file.size)}
                  imageUrl={file.original_url}
                  isEditableInput={false}
                  imageLastModified={file.updated_at}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default MediaLibrary;
