import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import './Footer.css';
import Logo from './../../../../assets/images/imgWshare/logo-h-wshare-branca.png'; // Ajuste o caminho para a sua logo

const Features = () => {
  const footerLinks = [
    // {
    //   title: "Company",
    //   links: [
    //     { title: "About Us", link: "#" },
    //     { title: "Features", link: "#" },
    //     { title: "Team", link: "#" },
    //     { title: "News", link: "#" },
    //     { title: "FAQs", link: "#" },
    //   ],
    // },
    // {
    //   title: "Resources",
    //   links: [
    //     { title: "Whitepaper", link: "#" },
    //     { title: "Token sales", link: "#" },
    //     { title: "Privacy Policy", link: "#" },
    //     { title: "Terms & Conditions", link: "#" },
    //   ],
    // },
    // {
    //   title: "Links",
    //   links: [
    //     { title: "Tokens", link: "#" },
    //     { title: "Roadmap", link: "#" },
    //     { title: "FAQs", link: "#" },
    //   ],
    // },
  ];

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          {/* <Row className="footer-content">
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key} className="footer-column">
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <Link to={Flink.link}>{Flink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
            <hr className="footer-border my-5" />
          */}


          <Row className="text-center">
          <Row className="footer-logo">
            <Col>
              <img src={Logo} alt="Logo" className="footer-logo-img" />
            </Col>
          </Row>
            <Col>
              <p className="footer-text">© {new Date().getFullYear()} Farm System WShare. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Features;