import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { useHistory, useParams } from "react-router-dom";

import { Modal } from "reactstrap";

import Message from "components/Message";

import { showMessage, hideMessage } from "store/message/actions";

import { useSelector, useDispatch } from "react-redux";

import {
    getRolePermissions as onGetRolePermissions,
    getRolePermissionsAvailable as onGetRolePermissionsAvailable,
    addNewRolePermission as onAddNewRolePermission,
    deleteRolePermission as onDeleteRolePermission,
} from "store/actions";

const ModalRolePermissions = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const { rolePermissions, permissionsAvailable } = useSelector( 
        function (state) {
        return {...state.RolesPermissions};
    });

    const [ selectedFilter, setSelectedFilter ] = useState([]);
    const [ permissionsAvailableList, setPermissionsAvailableList ] = useState([]);
    const [ rolePermissionsList, setRolePermissionsList ] = useState([]);
    
    const { error } = useSelector( function(state) {
        console.log(state);
        return ({error: state.RolesPermissions.error})
    });
    
    const [ messageAlert, setMessageAlert] = useState("");
    const [ typeMessage, setTypeMessage ] = useState("");

    useEffect(() => {
        if ( error != null && Object.keys(error).length !== 0 && error.response.data) {
          showingMessage(error.response.data.message, 'warning');
        }
      }, [error]);

    useEffect(() => {
        if (permissionsAvailable) {
            setPermissionsAvailableList(formatData(permissionsAvailable));
        }
    }, [permissionsAvailable]);

    useEffect(() => {
        if (rolePermissions) {
            setRolePermissionsList(formatData(rolePermissions));

            let availables = [];

            rolePermissions.forEach(permission => availables.push(permission.id));
            setSelectedFilter(availables);
        }
    }, [rolePermissions]);

    const formatData = argData => {
        const data = argData.map(item => {
            return { label: item.name, value: item.id };
        });
        return data;
    }

    useEffect( () => {
        dispatch(onGetRolePermissions(id));
        dispatch(onGetRolePermissionsAvailable(id));
    }, []);

    const handleAsyncActions = (action) => {
        if (error == null || Object.keys(error).length === 0) {
            showingMessage(`Permissions ${action} Successfully`, 'success');
        }
      };
    
      const showingMessage = (title, type) => {
        setMessageAlert(title);
        setTypeMessage(type);
    
        dispatch(showMessage());
        setTimeout(() => { dispatch(hideMessage()) }, 2000)
      }

    const onFilterChange = (selectedFilter) => {
        setSelectedFilter(selectedFilter);
    }

    const onHandleBackToRoles = () => {
        history.goBack();
    }

    const onHandleAddRolePermission = () => {
        console.log(selectedFilter);
        dispatch(onAddNewRolePermission(id, {"permissions" :selectedFilter}));
        handleAsyncActions('Applied');
        onHandleBackToRoles();
    }

    return(
        <>
        <Message title={messageAlert} type={typeMessage} />

        <Modal
            isOpen={true}
            backdrop={'static'}
            toggle={onHandleBackToRoles}
            id="staticBackdrop"
            >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Permissions List</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={onHandleBackToRoles}
                    aria-label="Close"
                    ></button>
            </div>
            <div className="modal-body">
                <p>Move one or more permissions to the right</p>
                { rolePermissionsList.length == 0 && permissionsAvailableList == 0 ?
                    <p>No have permissions! Add permissions.</p>
                    : <></>
                }
                <DualListBox
                 
                    filterPlaceholder="Search..."
                    options={[...rolePermissionsList, ...permissionsAvailableList]}
                    selected={selectedFilter}
                    onChange={(item) => onFilterChange(item)}
                    />

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={onHandleBackToRoles}
                    >
                    Close
                </button>
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={onHandleAddRolePermission}
                    >Apply</button>
            </div>
        </Modal>
        </>
    )
}

ModalRolePermissions.propTypes = {
    onGetRolePermissions: PropTypes.func,
    onGetRolePermissionsAvailable: PropTypes.func,
    addNewRolePermission: PropTypes.func,
    deleteRolePermission: PropTypes.func,
}

export default ModalRolePermissions
