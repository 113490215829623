import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import InputMask from 'react-input-mask'; // Importe o componente InputMask
import useCheckoutStore from 'zustand/checkout';
import Title from '../Title';
import Footer from '../Footer';
import { setDate } from 'date-fns';
import './index.css';

const Payment = () => {
  const { setPayment, setStep, payment } = useCheckoutStore();

  // Estado local para armazenar os valores do formulário
  const [formValues, setFormValues] = useState(payment);

  const handleSubmit = (data) => {
    setStep(4);
    setPayment(data);
  };

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string().required('Número do cartão é obrigatório'),
    cardHolder: Yup.string()
      .matches(/^[\w]+[\s][\w]+$/, 'Nome do titular do cartão é obrigatório') // Garante nome e sobrenome
      .required('Nome do titular do cartão é obrigatório'),
    expiryDate: Yup.string()
      .matches(/^\d{2}\/\d{2}$/, 'Formato inválido. Use DD/YY')
      .required('Data de expiração é obrigatória'),
    cvv: Yup.string()
      .matches(/^\d{3}$/, 'CVV inválido')
      .required('CVV é obrigatório')
  });

  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <Title
        title="Forma de pagamento"
        subTitle="escolha abaixo a forma que deseja pagar a assinatura do plano xxx"
      />
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                border: '1px dashed #005789',
                borderRadius: '7px',
                height: '50px',
                marginBottom: '2rem'
              }}>
              <div style={{ marginLeft: '20px', marginTop: '16px' }}>
                <Field type="radio" name="type" value="boleto" id="boleto" />
                <label htmlFor="boleto">
                  <p style={{ marginLeft: '5px' }}>Boleto</p>
                </label>
              </div>
              <div style={{ marginLeft: '20px', marginTop: '16px' }}>
                <Field type="radio" name="type" value="credit_card" id="cartao" />
                <label htmlFor="cartao">
                  <p style={{ marginLeft: '5px' }}>Cartão de Crédito</p>
                </label>
              </div>
            </div>
            <div>
              <Field name="type">
                {({ field }) => (
                  <div>
                    {field.value === 'boleto' ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          width="450px"
                          src="https://blog.tecnospeed.com.br/wp-content/uploads/2021/05/plugbank-boleto-bancario-tudo-que-voce-precisa-saber-1.png"
                        />
                      </div>
                    ) : (
                      <CardForm />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <Footer
              textButton="Continuar"
              buttonPrevius={'<- Voltar'}
              disabled={isSubmitting || !isValid}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const CardForm = () => {
  //const [cardType, setCardType] = useState(null);

  let cardType = '';

  const cardTypeRegex = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    amex: /^3[47]/,
    discover: /^6(?:011|5)/,
    diners: /^3(?:0[0-5]|[68])/,
    jcb: /^(?:2131|1800|35)/,
    elo: /^636[0-9]{13,16}$/
  };

  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value;
    let type = null;
    for (const [card, regex] of Object.entries(cardTypeRegex)) {
      if (regex.test(cardNumber)) {
        type = card;
        break;
      }
    }
    // setCardType(type);
    cardType = type;
    console.log(type);
  };

  return (
    <div>
      <FormGroup>
        <Label for="cardNumber">Número do Cartão</Label>
        <Field name="cardNumber">
          {({ field, form }) => (
            <>
              <InputMask
                {...field}
                onChange={(e) => {
                  handleCardNumberChange(e);
                  form.handleChange(e);
                  e.target.style.backgroundImage = `url(/creditCards/${cardType}.png)`;
                  e.target.style.backgroundRepeat = 'no-repeat';
                  e.target.style.backgroundSize = 'containt';
                  e.target.style.backgroundPosition = 'right center';
                  e.target.style.paddingLeft = '0.7rem';
                }}
                data-lpignore={true}
                mask="9999 9999 9999 9999" // Máscara para o número do cartão
              >
                {(inputProps) => <Input {...inputProps} data-lpignore={true} type="text" />}
              </InputMask>
              <ErrorMessage name="cardNumber" component="div" className="error" />
            </>
          )}
        </Field>
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="expiryDate">Data de Expiração</Label>
            <Field name="expiryDate">
              {({ field, form }) => (
                <>
                  <InputMask
                    {...field}
                    type="text"
                    name="expiryDate"
                    id="expiryDate"
                    mask="99/99" // Máscara para a data (DD/YY)
                    maskChar={null} // Evita que o caractere da máscara seja exibido enquanto digita
                    onChange={(e) => form.handleChange(e)}
                    style={{
                      paddingLeft: '0.7rem',
                      width: '100%',
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                      height: '2.5rem'
                    }}
                  />
                  <ErrorMessage name="expiryDate" component="div" className="error" />
                </>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="cvv">CVV</Label>
            <Field name="cvv">
              {({ field, form }) => (
                <>
                  <InputMask
                    {...field}
                    type="text"
                    name="cvv"
                    id="cvv"
                    mask="999" // Máscara para o CVV (três dígitos)
                    maskChar={null} // Evita que o caractere da máscara seja exibido enquanto digita
                    onChange={(e) => form.handleChange(e)}
                    style={{
                      paddingLeft: '0.7rem',
                      width: '100%',
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                      height: '2.5rem'
                    }}
                  />
                  <ErrorMessage name="cvv" component="div" className="error" />
                </>
              )}
            </Field>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="cardHolder">Nome do Titular do Cartão</Label>
        <Field name="cardHolder">
          {({ field, form }) => (
            <>
              <Input
                {...field}
                type="text"
                name="cardHolder"
                id="cardHolder"
                onChange={(e) => form.handleChange(e)}
                style={{
                  paddingLeft: '0.7rem',
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  height: '2.5rem'
                }}
              />
              <ErrorMessage name="cardHolder" component="div" className="error" />
            </>
          )}
        </Field>
      </FormGroup>
    </div>
  );
};

export default Payment;
