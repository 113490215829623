import React, { useEffect } from 'react'

import Header from "./components/Header"
import Content from './components/Content'
import { useParams } from 'react-router-dom'


const Checkout = () => {
  
  const params = useParams()

  useEffect(() => {
    console.log({params})
  }, [params])
  return (
    <div style={{ background: "#fff"}}>
      <Header />
      <Content />
    </div>
  )
}

export default Checkout