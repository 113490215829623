import {
  GET_BREEDS_SUCCESS,
  GET_BREEDS_FAIL,
  ADD_BREED_SUCCESS,
  ADD_BREED_FAIL,
  UPDATE_BREED_SUCCESS,
  UPDATE_BREED_FAIL,
  DELETE_BREED_SUCCESS,
  DELETE_BREED_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  breeds: [],
  error: {},
};

const Breed = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BREEDS_SUCCESS:
      return {
        ...state,
        breeds: action.payload,
      };

    case GET_BREEDS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_BREED_SUCCESS:
      return {
        ...state,
        breeds: [...state.breeds, action.payload],
      };

    case ADD_BREED_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_BREED_SUCCESS:
      return {
        ...state,
        breeds: state.breeds.map(breed =>
          breed.id.toString() === action.payload.id.toString()
            ? { breed, ...action.payload }
            : breed
        ),
      };

    case UPDATE_BREED_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_BREED_SUCCESS:
      return {
        ...state,
        breeds: state.breeds.filter(
          breed => breed.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BREED_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Breed;
