import {
  GET_IRONS_SUCCESS,
  GET_IRONS_FAIL,
  ADD_IRON_SUCCESS,
  ADD_IRON_FAIL,
  UPDATE_IRON_SUCCESS,
  UPDATE_IRON_FAIL,
  DELETE_IRON_SUCCESS,
  DELETE_IRON_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  irons: [],
  error: {},
};

const Iron = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_IRONS_SUCCESS:
      return {
        ...state,
        irons: action.payload,
      };

    case GET_IRONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_IRON_SUCCESS:
      return {
        ...state,
        irons: [...state.irons, action.payload],
      };

    case ADD_IRON_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_IRON_SUCCESS:
      return {
        ...state,
        irons: state.irons.map(iron =>
          iron.id.toString() === action.payload.id.toString()
            ? { iron, ...action.payload }
            : iron
        ),
      };

    case UPDATE_IRON_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_IRON_SUCCESS:
      return {
        ...state,
        irons: state.irons.filter(
          iron => iron.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_IRON_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Iron;
