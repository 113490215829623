import { getPlansIndexName, getPlansName } from 'constants/checkout';
import React, { useMemo } from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

import { usePlansStore } from 'zustand/plans/plans-store';

const PlanCard = () => {
  const { plan } = usePlansStore();

  const price = useMemo(() => {
    if (plan) {
      return `R$ ${plan.data.price} / ${getPlansName[plan.data.period]}`;
    }

    return '';
  }, [plan]);

  return (
    <div>
      <Card>
        <CardBody>
          <h4>{plan?.data.name}</h4>
          <ul>
            {plan?.data.details.map((detail, key) => (
              <li key={key} style={{ color: '#68717A' }}>
                {detail.name}
              </li>
            ))}
          </ul>
        </CardBody>
        <CardText>
          <h5 style={{ color: '#ABB5BE', marginLeft: '1rem', marginTop: '-1.19rem' }}>{price}</h5>
        </CardText>
      </Card>
    </div>
  );
};

export default PlanCard;
