import {

  GET_ROLE_USERS,
  GET_ROLE_USERS_SUCCESS,
  GET_ROLE_USERS_FAIL,

  GET_ROLE_USERS_AVAILABLES,
  GET_ROLE_USERS_AVAILABLES_SUCCESS,
  GET_ROLE_USERS_AVAILABLES_FAIL,

  ADD_ROLE_USER,
  ADD_ROLE_USER_SUCCESS,
  ADD_ROLE_USER_FAIL,

  DELETE_ROLE_USER,
  DELETE_ROLE_USER_SUCCESS,
  DELETE_ROLE_USER_FAIL
} from "./actionTypes"


export const getRoleUsers = (userId) => ({
  type: GET_ROLE_USERS,
  userId: userId,
})

export const getRoleUsersSuccess = roleUser => ({
  type: GET_ROLE_USERS_SUCCESS,
  payload: roleUser,
})

export const getRoleUsersFail = error => ({
  type: GET_ROLE_USERS_FAIL,
  payload: error,
})

export const getRoleUsersAvailable = (userId) => ({
  type: GET_ROLE_USERS_AVAILABLES,
  userId: userId,
})

export const getRoleUsersAvailablesSuccess = roleUserAvailable => ({
  type: GET_ROLE_USERS_AVAILABLES_SUCCESS,
  payload: roleUserAvailable,
})

export const getRoleUsersAvailablesFail = error => ({
  type: GET_ROLE_USERS_AVAILABLES_FAIL,
  payload: error,
})

export const addNewRoleUser = (userId, roleUser) => ({
  type: ADD_ROLE_USER,
  userId: userId,
  payload: roleUser,
})

export const addNewRoleUserSuccess = roleUser => ({
  type: ADD_ROLE_USER_SUCCESS,
  payload: roleUser,
})

export const addNewRoleUserFail = error => ({
  type: ADD_ROLE_USER_FAIL,
  payload: error,
})

export const deleteRoleUser = (userId, roleUser) => ({
  type: DELETE_ROLE_USER,
  userId: userId,
  payload: roleUser,
})

export const deleteRoleUserSuccess = roleUser => ({
  type: DELETE_ROLE_USER_SUCCESS,
  payload: roleUser,
})

export const deleteRoleUserFail = error => ({
  type: DELETE_ROLE_USER_FAIL,
  payload: error,
})

