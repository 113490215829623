import create from 'zustand';
import axiosApi from 'helpers/api_helper';

export class FarmService {
  async get(filter) {
    const response = await axiosApi.get(`/farms`, { params: filter });
    return response.data.data ?? [];
  }

  async getById(id) {
    const response = await axiosApi.get(`/farms/${id}`);
    return response.data.data ?? {};
  }
}

export class IronService {
  async get(filter) {
    const response = await axiosApi.get(`/irons`, { params: filter });
    return response.data.data ?? [];
  }

  async getById(id) {
    const response = await axiosApi.get(`/irons/${id}`);
    return response.data.data ?? {};
  }
}

export class BreedService {
  async get(filter) {
    const response = await axiosApi.get(`/breeds`, { params: filter });
    return response.data.data ?? [];
  }

  async getById(id) {
    const response = await axiosApi.get(`/breeds/${id}`);
    return response.data.data ?? {};
  }
}


export const farmsService = new FarmService();
export const breedsService = new BreedService();
export const ironsService = new IronService();

export const useFarmsStore = create((set) => ({
  isLoading: false,

  farms: [],
  irons: [],
  breed: [],
  allBreeds: [],
  allIrons: [],
  allFarms: [],
  farm: null,
  irons: null,
  breed: null,


  getAllFarms: async () => {
    set({ isLoading: true });
    const allFarms = await farmsService.get();
    set({ allFarms, isLoading: false });
  },

  getFarms: async (filter) => {
    set({ isLoading: true });
    const farms = await farmsService.get(filter);
    set({ farms, isLoading: false });
  },

  getFarmsById: async (id) => {
    set({ isLoading: true });
    const farm = await farmsService.getById(id);
    set({ farm, isLoading: false });
  },

  getAllBreed: async () => {
    set({ isLoading: true });
    const allBreeds = await breedsService.get();
    set({ allBreeds, isLoading: false });
  },

  getBreed: async (filter) => {
    set({ isLoading: true });
    const Breed = await breedsService.get(filter);
    set({ Breed, isLoading: false });
  },

  getBreedById: async (id) => {
    set({ isLoading: true });
    const breed = await breedsService.getById(id);
    set({ breed, isLoading: false });
  },

  getAllIron: async () => {
    set({ isLoading: true });
    const allIrons = await ironsService.get();
    set({ allIrons, isLoading: false });
  },

  getIron: async (filter) => {
    set({ isLoading: true });
    const Iron = await ironsService.get(filter);
    set({ Iron, isLoading: false });
  },

  getIronById: async (id) => {
    set({ isLoading: true });
    const iron = await ironsService.getById(id);
    set({ iron, isLoading: false });
  },


}));

export default farmsService;
