import React, { useState } from 'react';
import Breadcrumb from 'components/Common/Breadcrumb';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import CardsComponent from './Card/CardsComponent';
import Addresses from './Addresses';
import './plan.css';

const Assinatura = (props) => {
  const [cardList, setCardList] = useState([]);
  const [defaultCardIndex, setDefaultCardIndex] = useState(null);

  const [addressList, setAddressList] = useState([]);
  const [defaultAddressIndex, setDefaultAddressIndex] = useState(null);

  const handleEditCard = (index) => {
    // Implementação da função de edição do cartão
  };

  const handleDeleteCard = (index) => {
    const updatedCardList = [...cardList];
    updatedCardList.splice(index, 1); // Remove o cartão da lista
    setCardList(updatedCardList);

    // Verifica se o cartão excluído era o padrão
    if (index === defaultCardIndex) {
      setDefaultCardIndex(null); // Remove a referência do cartão padrão
    }
  };

  const handleSetDefaultCard = (index) => {
    setDefaultCardIndex(index); // Define o índice do cartão padrão
  };

  const updateCardList = (updatedList, defaultIndex) => {
    setCardList(updatedList);
    setDefaultCardIndex(defaultIndex);
  };

  const handleEditAddress = (index) => {
    // Implementação da função de edição do endereço
  };

  const handleDeleteAddress = (index) => {
    const updatedAddressList = [...addressList];
    updatedAddressList.splice(index, 1); // Remove o endereço da lista
    setAddressList(updatedAddressList);

    // Verifica se o endereço excluído era o padrão
    if (index === defaultAddressIndex) {
      setDefaultAddressIndex(null); // Remove a referência do endereço padrão
    }
  };

  const handleSetDefaultAddress = (index) => {
    setDefaultAddressIndex(index); // Define o índice do endereço padrão
  };

  const updateAddressList = (updatedList, defaultIndex) => {
    setAddressList(updatedList);
    setDefaultAddressIndex(defaultIndex);
  };

  return (
    <div className="page-content">
      <Col xs="12">
        <Breadcrumb title={props.t('Subscription')} breadcrumbItem={props.t('Subscription')} />
        <Container fluid className="plan-container">
          <Row className="justify-content-center">
            <Col md={8} lg={12}>
              <Card className="plan-card shadow-lg">
                <CardBody className="text-center">
                  <div className="plan-header">
                    <small className="plan-type">{props.t('Smart')}</small>
                    <CardTitle className="plan-title">{props.t('Plano Mensal')}</CardTitle>
                    <div>
                      <h1>
                        <strong className='plan-price'>R$</strong>
                        150,00
                      </h1>
                    </div>
                  </div>
                  <Row className="plan-details">
                    <Col md={4} className="mb-4">
                      <FormGroup className="plan-form-group">
                        <Label for="valor" className="plan-label">
                          {props.t('Value')}
                        </Label>
                        <Input
                          type="text"
                          id="valor"
                          value="R$ 150,00"
                          readOnly
                          className="plan-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} className="mb-4">
                      <FormGroup className="plan-form-group">
                        <Label for="dataDoPagamento" className="plan-label">
                          {props.t('Pay day')}
                        </Label>
                        <Input
                          type="date"
                          id="dataDoPagamento"
                          value="2024-01-10"
                          readOnly
                          className="plan-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} className="mb-4">
                      <FormGroup className="plan-form-group">
                        <Label for="dataDoVencimento" className="plan-label">
                          {props.t('Due date')}
                        </Label>
                        <Input
                          type="date"
                          id="dataDoVencimento"
                          value="2024-02-10"
                          readOnly
                          className="plan-input"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col md={12}>
                      <Link to="/landing">
                        <Button type="button" color="primary" className="plan-button">
                          {props.t('Update plan')}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="container">
          <CardsComponent
            cardList={cardList}
            handleEdit={handleEditCard}
            handleDelete={handleDeleteCard}
            handleSetDefault={handleSetDefaultCard}
            defaultCardIndex={defaultCardIndex}
            t={props.t}
            updateCardList={updateCardList}
          />
        </div>
        <div className="container">
          <Addresses
            addressList={addressList}
            handleEdit={handleEditAddress}
            handleDelete={handleDeleteAddress}
            handleSetDefault={handleSetDefaultAddress}
            defaultAddressIndex={defaultAddressIndex}
            t={props.t}
            updateAddressList={updateAddressList}
          />
        </div>
      </Col>
    </div>
  );
};

export default withRouter(withTranslation()(Assinatura));
