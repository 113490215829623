import { call, put, takeEvery } from "redux-saga/effects";

// MovementType Redux States
import {
  GET_MOVEMENT_TYPES,
  ADD_NEW_MOVEMENT_TYPE,
  UPDATE_MOVEMENT_TYPE,
  DELETE_MOVEMENT_TYPE,
} from "./actionTypes";
import {
  getMovementTypesFail,
  getMovementTypesSuccess,
  addMovementTypeFail,
  addMovementTypeSuccess,
  updateMovementTypeSuccess,
  updateMovementTypeFail,
  deleteMovementTypeSuccess,
  deleteMovementTypeFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getMovementTypes,
  addNewMovementType,
  updateMovementType,
  deleteMovementType,
} from "helpers/backend_fazenda_helper";

function* onGetMovementTypes() {
  try {
    const response = yield call(getMovementTypes);
    yield put(getMovementTypesSuccess(response.data));
  } catch (error) {
    yield put(getMovementTypesFail(error));
  }
}

function* onUpdateMovementType({ payload: movementType }) {
  try {
    const response = yield call(updateMovementType, movementType);
    yield put(updateMovementTypeSuccess(response.data));

  } catch (error) {
    yield put(updateMovementTypeFail(error));
  }
}

function* onDeleteMovementType({ payload: movementType }) {
  try {
    const response = yield call(deleteMovementType, movementType);
    yield put(deleteMovementTypeSuccess(response.data));
  } catch (error) {
    yield put(deleteMovementTypeFail(error));
  }
}

function* onAddNewMovementType({ payload: movementType }) {
  try {
    const response = yield call(addNewMovementType, movementType);
    yield put(addMovementTypeSuccess(response.data));
  } catch (error) {
    yield put(addMovementTypeFail(error));
  }
}

function* movementTypeSaga() {
  yield takeEvery(GET_MOVEMENT_TYPES, onGetMovementTypes);
  yield takeEvery(ADD_NEW_MOVEMENT_TYPE, onAddNewMovementType);
  yield takeEvery(UPDATE_MOVEMENT_TYPE, onUpdateMovementType);
  yield takeEvery(DELETE_MOVEMENT_TYPE, onDeleteMovementType);
}

export default movementTypeSaga;
