import Breadcrumb from "components/Common/Breadcrumb";
import DocumentPrint from "components/DocumentPrint";
import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';

import { Table, Container, Row, Col, Card, CardBody, Button, FormText, CardTitle } from "reactstrap";


const ProgressAnalysis = (props) => {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log(handlePrint)
  return (
    <div className="page-content" >
      <Breadcrumb title="Earring" breadcrumbItem="Earring-analysis" />
      <Container fluid style={{ alignItems: 'center' }}>
        <Card >
          <Row className="mb-1">
            <Col sm="12" style={{ top: "35px", right: "15px" }}>
              <div className="text-sm-end ">
                <Button
                  type="button"
                  color="success"
                  className="btn-rounded  mb-2 me-2"
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <DocumentPrint ref={componentRef}>
              <FormText style={{ textAlign: "center" }}>
                <CardTitle>
                  <strong>Análise Progresso - </strong>
                  <strong>Frigrofico Frisa - </strong>
                  <strong>14/11/2023</strong>
                </CardTitle>
              </FormText>
              <CardBody>
                <Col sm="12" >
                  <Table responsive bordered style={{ textAlign: "right" }}>
                    <thead>
                      <tr>
                        <th>
                          Cod
                        </th>
                        <th>
                          Brinco
                        </th>
                        <th>
                          Último Peso
                        </th>
                        <th>
                          Dt Último
                          Peso
                        </th>
                        <th>
                          Peso de
                          compra
                        </th>
                        <th>
                          Data
                          Compra
                        </th>
                        <th>
                          Diferença
                          de Dias
                        </th>
                        <th>
                          Ganho em
                          kg
                        </th>
                        <th>
                          Ganho por
                          ano @
                        </th>
                        <th>
                          Informações adicionais
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="DocumentPrint">
                          37
                        </th>
                        <td>
                          0689
                        </td>
                        <td>
                          485
                        </td>
                        <td>
                          14/11/23
                        </td>
                        <td>
                          232,50
                        </td>
                        <td>
                          24/11/21
                        </td>
                        <td>
                          720
                        </td>
                        <td>
                          252,50
                        </td>
                        <td>
                          4,21
                        </td>
                        <td>
                          Bonanza 62 bois
                        </td>
                      </tr>
                      <tr style={{ backgroundColor: "#d9e1df" }}>
                        <td colSpan={2} style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                          <strong>
                            Subtotal
                          </strong>
                        </td>
                        <td colSpan={1} style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                          <strong>
                            6.0012
                          </strong>
                        </td>
                        <td colSpan={4} style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                          <strong>
                            6.0012
                          </strong>
                        </td>
                        <td colSpan={1} style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                          <strong>
                            6.0012
                          </strong>
                        </td>
                        <td colSpan={1} style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                          <strong>
                            6.0012
                          </strong>
                        </td>
                        <td style={{ backgroundColor: "#d9e1df", padding: "2px" }}>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </CardBody>
            </DocumentPrint>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default ProgressAnalysis;