import {
  GET_KPICARDS,
  GET_KPICARDS_FAIL,
  GET_KPICARDS_SUCCESS,
  ADD_NEW_KPICARD,
  ADD_KPICARD_SUCCESS,
  ADD_KPICARD_FAIL,
  UPDATE_KPICARD,
  UPDATE_KPICARD_SUCCESS,
  UPDATE_KPICARD_FAIL,
  DELETE_KPICARD,
  DELETE_KPICARD_SUCCESS,
  DELETE_KPICARD_FAIL,

} from "./actionTypes"

export const getKpiCards = () => ({
  type: GET_KPICARDS,
})

export const getKpiCardsSuccess = kpiCards => ({
  type: GET_KPICARDS_SUCCESS,
  payload: kpiCards,
})

export const getKpiCardsFail = error => ({
  type: GET_KPICARDS_FAIL,
  payload: error,
})

export const addNewKpiCard = kpiCard => ({
  type: ADD_NEW_KPICARD,
  payload: kpiCard,
})

export const addKpiCardSuccess = kpiCard => ({
  type: ADD_KPICARD_SUCCESS,
  payload: kpiCard,
})

export const addKpiCardFail = error => ({
  type: ADD_KPICARD_FAIL,
  payload: error,
})

export const updateKpiCard = kpiCard => ({
  type: UPDATE_KPICARD,
  payload: kpiCard,
})

export const updateKpiCardSuccess = kpiCard => ({
  type: UPDATE_KPICARD_SUCCESS,
  payload: kpiCard,
})

export const updateKpiCardFail = error => ({
  type: UPDATE_KPICARD_FAIL,
  payload: error,
})

export const deleteKpiCard = kpiCard => ({
  type: DELETE_KPICARD,
  payload: kpiCard,
})

export const deleteKpiCardSuccess = kpiCard => ({
  type: DELETE_KPICARD_SUCCESS,
  payload: kpiCard,
})

export const deleteKpiCardFail = error => ({
  type: DELETE_KPICARD_FAIL,
  payload: error,
})

