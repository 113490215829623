import React from 'react';

// eslint-disable-next-line react/display-name
const DocumentPrint = React.forwardRef((_props, ref) => {
  return (
    <div style={{ padding: '20px' }} ref={ref}>
      {_props.children}
    </div>
  );
});

export default DocumentPrint;
