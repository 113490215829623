import {
  GET_PLANS_DETAILS_SUCCESS,
  GET_PLANS_DETAILS_FAIL,
  ADD_PLAN_DETAILS_SUCCESS,
  ADD_PLAN_DETAILS_FAIL,
  UPDATE_PLAN_DETAILS_SUCCESS,
  UPDATE_PLAN_DETAILS_FAIL,
  DELETE_PLAN_DETAILS_SUCCESS,
  DELETE_PLAN_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  planDetails: [],
  error: {},
};

const PlanDetails = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PLANS_DETAILS_SUCCESS:
      return {
        state,
        planDetails: action.payload,
      };

    case GET_PLANS_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        planDetails: [...state.planDetails, action.payload],
      };

    case ADD_PLAN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        planDetails: state.planDetails.map(planDetails =>
          planDetails.id.toString() === action.payload.id.toString()
            ? { planDetails, ...action.payload }
            : planDetails
        ),
      };

    case UPDATE_PLAN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
          planDetails: state.planDetails.filter(
          planDetails => planDetails.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PLAN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PlanDetails;
