import React, { useMemo } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import logo from '../../../../assets/images/wshare.png';
import LogoPagarme from '../../../../assets/images/pagarme02.jpeg';
import useScreenSize from 'hooks/useScreenSize';

const Header = () => {
  const screenSize = useScreenSize();

  const calculateHeight = (width, aspectRatio) => {
    return (width / aspectRatio).toFixed(2);
  };

  const aspectRatio1 = 230 / 120; 
  const aspectRatio2 = 130 / 240;

  const logoStyle1 = useMemo(() => {
    return {
      width: screenSize.width < 1100 ? '100%' : '190px',
      height: screenSize.width < 1100 ? calculateHeight(screenSize.width, aspectRatio1) : 'auto',
    };
  }, [screenSize]);

  const logoStyle2 = useMemo(() => {
    return {
      width: screenSize.width < 1100 ? '100%' : '130px',
      height: screenSize.width < 1100 ? calculateHeight(screenSize.width, aspectRatio2) : 'auto',
    };
  }, [screenSize]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingLeft: '30px', paddingRight: '30px' }}>
      <div style={{ width: `${screenSize.width < 1100 ? '100%' : '1100px'}`, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={logo} style={logoStyle1} alt="Imagem 1" />
        <img src={LogoPagarme} style={logoStyle2} />
      </div>
    </div>
  );
};

export default Header;
