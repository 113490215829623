import { call, put, takeEvery } from "redux-saga/effects";

// Breed Redux States
import {
  GET_BREEDS,
  ADD_NEW_BREED,
  UPDATE_BREED,
  DELETE_BREED,
} from "./actionTypes";
import {
  getBreedsFail,
  getBreedsSuccess,
  addBreedFail,
  addBreedSuccess,
  updateBreedSuccess,
  updateBreedFail,
  deleteBreedSuccess,
  deleteBreedFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getBreeds,
  addNewBreed,
  updateBreed,
  deleteBreed,
} from "helpers/backend_fazenda_helper";

function* onGetBreeds() {
  try {
    const response = yield call(getBreeds);
    yield put(getBreedsSuccess(response.data));
  } catch (error) {
    yield put(getBreedsFail(error));
  }
}

function* onUpdateBreed({ payload: breed }) {
  try {
    const response = yield call(updateBreed, breed);
    yield put(updateBreedSuccess(response.data));
  } catch (error) {
    yield put(updateBreedFail(error));
  }
}

function* onDeleteBreed({ payload: breed }) {
  try {
    const response = yield call(deleteBreed, breed);
    console.log("response", response)
    yield put(deleteBreedSuccess(response.data));
  } catch (error) {
    console.log("error", error)
    yield put(deleteBreedFail(error));
  }
}

function* onAddNewBreed({ payload: breed }) {
  try {
    const response = yield call(addNewBreed, breed);
    console.log("onAddNewBreed", response)

    yield put(addBreedSuccess(response.data));
  } catch (error) {
    yield put(addBreedFail(error));
  }
}

function* breedSaga() {
  yield takeEvery(GET_BREEDS, onGetBreeds);
  yield takeEvery(ADD_NEW_BREED, onAddNewBreed);
  yield takeEvery(UPDATE_BREED, onUpdateBreed);
  yield takeEvery(DELETE_BREED, onDeleteBreed);
}

export default breedSaga;
