import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import axiosApi from '../../helpers/api_helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Message from '../../components/Message';

import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  FormFeedback,
  Input,
  FormGroup
} from 'reactstrap';
import Dropzone from 'react-dropzone';

// Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import {
  importMovement as onImportMovement,
  getMovementTypes as onGetMovementTypes,
  getFarms as onGetFarms
} from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { showMessage, hideMessage } from 'store/message/actions';
import { currencyMask } from 'utils/inputMask';

const ImportMovements = () => {
  const dispatch = useDispatch();

  const optionGroupEmpty = [
    {
      label: 'Breeds',
      options: []
    }
  ];

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [movement, setMovement] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [movementTypeId, setMovementTypeId] = useState(null);

  const [selectedMovementType, setSelectedMovementType] = useState(null);
  const [optionGroupMovementType, setOptionGroupMovementType] = useState(optionGroupEmpty);

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [optionGroupFarm, setOptionGroupFarm] = useState(optionGroupEmpty);

  const { movementTypes } = useSelector((state) => ({
    movementTypes: state.MovementType.movementTypes
  }));
  const { farms } = useSelector((state) => ({ farms: state.Farm.farms }));

  const { error } = useSelector((state) => ({ error: state.Movement.error }));

  const { file } = useSelector((state) => ({ file: state.Movement.file }));
  const [movementDate, setsTartDate] = useState(new Date());

  const formatDate = (date: Date) => {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    const year = date.getFullYear();
    return [year, month, day].join('-');
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  useEffect(() => {
    dispatch(onGetMovementTypes());
    dispatch(onGetFarms());
  }, []);

  const movementDateChange = (date) => {
    setsTartDate(date);
  };

  function handleSelectFarm(selectedFarm) {
    setSelectedFarm(selectedFarm);
  }

  function handleSelectMovementType(selectedMovementType) {
    setSelectedMovementType(selectedMovementType);
    setMovementTypeId(selectedMovementType.value);
  }

  useEffect(() => {
    console.log('file: ', file);
    console.log('error: ', error);
  }, [file, error]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  useEffect(() => {
    setOptionGroupFarm(formatDataForSelect('Farms', farms));
  }, [farms]);

  useEffect(() => {
    setOptionGroupMovementType(
      formatDataForSelect(
        'Movement Types',
        movementTypes.filter((item) => item.operation == 'E')
      )
    );
  }, [movementTypes]);

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const sendFile = (movementData) => {
    const formData = new FormData();
    formData.append('file', selectedFiles[0]);
    formData.append('timestamp', (Date.now() / 1000) | 0);

    formData.append('movement_type_id', movementData.movement_type_id);
    formData.append('farm_id', movementData.farm_id);
    formData.append('movement_date', formatDate(movementDate));
    formData.append('collect[name]', movementData.name);
    formData.append('collect[amount]', movementData.amount);
    formData.append('collect[freight]', movementData.freight);
    formData.append('collect[other_values]', movementData.other_values);
    formData.append('collect[arroba_price]', movementData.arroba_price);

    axiosApi
      .post('/movements/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        showingMessage(response.data.message, 'success');
      })
      .catch(function (error) {
        showingMessage(error.message, 'warning');
      });
    // dispatch(onImportMovement(formData));
  };

  function formatDataForSelect(title, data = []) {
    if (data && data.length > 0) {
      return [
        {
          label: title,
          options: data.map((item) => {
            return { label: item.name, value: item.id };
          })
        }
      ];
    }
    return [
      {
        label: title,
        options: []
      }
    ];
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      collectName: (movement && movement.collectName) || '',
      amount: (movement && movement.amount) || '',
      freight: (movement && movement.freight) || '',
      other_values: (movement && movement.other_values) || '',
      arroba_price: (movement && movement.arroba_price) || ''
    },
    validationSchema: Yup.object({
      collectName: Yup.string().required('Please Enter Your collect name '),
      amount: Yup.string().required('Please Enter Your collect amount '),
      freight: Yup.string().required('Please Enter Your collect freight '),
      other_values: Yup.string().required('Please Enter Your collect other_values '),
      arroba_price: Yup.string().required('Please Enter Your collect arroba_price ')
    }),
    onSubmit: (values) => {
      const newMovement = {
        movement_type_id: movementTypeId,
        farm_id: selectedFarm.value,
        movement_date: formatDate(movementDate),
        name: values.collectName,
        amount: values.amount,
        freight: values.freight,
        other_values: values.other_values,
        arroba_price: values.arroba_price
      };

      console.log('newMovement', newMovement);
      sendFile(newMovement);
      // dispatch(onAddNewMovement(newMovement));
      // validation.resetForm();
      // history.push("/movements");
      // handleAsyncActions('Added');
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />
        <MetaTags>
          <title>Form File Upload | Farm SystemW</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Movements" breadcrumbItem="Importar Movimentações" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Importar Movimentações</CardTitle>
                  <CardSubtitle className="mb-3">
                    {' '}
                    Arraste o arquivo ou clique duas vezes.
                  </CardSubtitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <FormGroup>
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label htmlFor="collectName">Colletc</Label>
                            <Input
                              id="collectName"
                              name="collectName"
                              type="text"
                              className="form-control"
                              placeholder="Entre com o nome da coleta..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.collectName || ''}
                              invalid={
                                validation.touched.collectName && validation.errors.collectName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.collectName && validation.errors.collectName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.collectName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Farm</Label>
                            <Select
                              id="farmId"
                              name="farmId"
                              value={selectedFarm}
                              onChange={handleSelectFarm}
                              options={optionGroupFarm}
                            />
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Type</Label>
                            <Select
                              id="movementTypeId"
                              name="movementTypeId"
                              value={selectedMovementType}
                              onChange={handleSelectMovementType}
                              options={optionGroupMovementType}
                            />
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Data</Label>

                            <Col className="pr-0">
                              <DatePicker
                                id="movementDate"
                                name="movementDate"
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                type="date"
                                selected={movementDate}
                                onChange={movementDateChange}
                              />
                            </Col>
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label htmlFor="collectName">Amount</Label>
                            <Input
                              id="amount"
                              name="amount"
                              type="text"
                              className="form-control"
                              placeholder="Enter amount the collect..."
                              onChange={(e) => validation.handleChange(currencyMask(e))}
                              onBlur={validation.handleBlur}
                              value={validation.values.amount || ''}
                              invalid={
                                validation.touched.amount && validation.errors.amount ? true : false
                              }
                            />
                            {validation.touched.amount && validation.errors.amount ? (
                              <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label htmlFor="collectName">Freight</Label>
                            <Input
                              id="freight"
                              name="freight"
                              type="text"
                              className="form-control"
                              placeholder="Enter freight the collect..."
                              onChange={(e) => validation.handleChange(currencyMask(e))}
                              onBlur={validation.handleBlur}
                              value={validation.values.freight || ''}
                              invalid={
                                validation.touched.freight && validation.errors.freight
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.freight && validation.errors.freight ? (
                              <FormFeedback type="invalid">
                                {validation.errors.freight}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label htmlFor="collectName">Other_values</Label>
                            <Input
                              id="other_values"
                              name="other_values"
                              type="text"
                              className="form-control"
                              placeholder="Enter other_values the collect..."
                              onChange={(e) => validation.handleChange(currencyMask(e))}
                              onBlur={validation.handleBlur}
                              value={validation.values.other_values || ''}
                              invalid={
                                validation.touched.other_values && validation.errors.other_values
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.other_values && validation.errors.other_values ? (
                              <FormFeedback type="invalid">
                                {validation.errors.other_values}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mb-3">
                            <Label htmlFor="collectName">Arroba_price</Label>
                            <Input
                              id="arroba_price"
                              name="arroba_price"
                              type="text"
                              className="form-control"
                              placeholder="Enter arroba_price the collect..."
                              onChange={(e) => validation.handleChange(currencyMask(e))}
                              onBlur={validation.handleBlur}
                              value={validation.values.arroba_price || ''}
                              invalid={
                                validation.touched.arroba_price && validation.errors.arroba_price
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.arroba_price && validation.errors.arroba_price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.arroba_price}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + '-file'}>
                            <div className="p-2">
                              <Row className="align-items-center">
                                {/* <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                </Col> */}
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <div className="text-center mt-4">
                      <button type="submit" className="btn btn-primary ">
                        Importar Arquivos
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ImportMovements.propTypes = {
  onImportMovement: PropTypes.func,
  onGetMovementTypes: PropTypes.func,
  onGetFarms: PropTypes.func
};

export default ImportMovements;
