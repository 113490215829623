import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// get farms
export const getFarms = () => get(url.GET_FARMS);

// add farm
export const addNewFarm = farm => post(url.ADD_NEW_FARM, farm);

// update farm
export const updateFarm = farm => put(`${url.UPDATE_FARM}/${farm.id}`, farm);

// delete farm
export const deleteFarm = farm =>
  del(`${url.DELETE_FARM}/${farm.id}`, { headers: { farm } });
//Fim Farm

// get breeds
export const getBreeds = () => get(url.GET_BREEDS);

// add breed
export const addNewBreed = breed => post(url.ADD_NEW_BREED, breed);

// update breed
export const updateBreed = breed => put(`${url.UPDATE_BREED}/${breed.id}`, breed);

// delete breed
export const deleteBreed = breed =>
  del(`${url.DELETE_BREED}/${breed.id}`, { headers: { breed } });
//Fim breed


// get irons
export const getIrons = () => get(url.GET_IRONS);

// add iron
export const addNewIron = iron => post(url.ADD_NEW_IRON, iron);

// update iron
export const updateIron = iron => put(`${url.UPDATE_IRON}/${iron.id}`, iron);

// delete iron
export const deleteIron = iron =>
  del(`${url.DELETE_IRON}/${iron.id}`, { headers: { iron } });
//Fim iron



// get movementTypes
export const getMovementTypes = () => get(url.GET_MOVEMENT_TYPES);

// add movementType
export const addNewMovementType = movementType => post(url.ADD_NEW_MOVEMENT_TYPE, movementType);

// update movementType
export const updateMovementType = movementType => put(`${url.UPDATE_MOVEMENT_TYPE}/${movementType.id}`, movementType);

// delete movementType
export const deleteMovementType = movementType =>
  del(`${url.DELETE_MOVEMENT_TYPE}/${movementType.id}`, { headers: { movementType } });
//Fim iron

//Inicio Movements
// get movements
export const getMovements = () => get(url.GET_MOVEMENTS);

// add movement
export const addNewMovement = movement => post(url.ADD_NEW_MOVEMENT, movement);

// add movement file
const config = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}
export const addNewMovementFile = movementFile => post(url.ADD_NEW_MOVEMENT_FILE, movementFile, config)

// update movement
export const updateMovement = movement => put(`${url.UPDATE_MOVEMENT}/${movement.id}`, movement);

// delete movement
export const deleteMovement = movement =>
  del(`${url.DELETE_MOVEMENT}/${movement.id}`, { headers: { movement } });
//Fim movement

// add movement
export const addNewMonitoring = (movement) => post(`${url.GET_FARMS}/${movement.farm_id}/monitoring`, movement);

// get tenants
export const getTenants = () => get(url.GET_TENANTS);

// add tenant
export const addNewTenant = tenant => post(url.ADD_NEW_TENANT, tenant);

// update tenant
export const updateTenant = tenant => put(`${url.UPDATE_TENANT}/${tenant.id}`, tenant);

// delete tenant
export const deleteTenant = tenant =>
    del(`${url.DELETE_TENANT}/${tenant.id}`, { headers: { tenant } });
//Fim Tenant

// get plans
export const getPlans = () => get(url.GET_PLANS);

// add plan
export const addNewPlan = plan => post(url.ADD_NEW_PLAN, plan);

// update plan
export const updatePlan = plan => put(`${url.UPDATE_PLAN}/${plan.id}`, plan);

// delete plan
export const deletePlan = plan =>
    del(`${url.DELETE_PLAN}/${plan.id}`, { headers: { plan } });
//Fim Plan

// get PlanDetails
export const getPlanDetails = urlPlan => get(`${url.GET_PLANS}/${urlPlan}/details`);

// add planDetails
export const addNewPlanDetails = (urlPlan, planDetails) =>
    post(`${url.ADD_NEW_PLAN}/${urlPlan}/details`, planDetails);

// update planDetails
export const updatePlanDetails = (urlPlan, planDetails) =>
    put(`${url.UPDATE_PLAN}/${urlPlan}/details/${planDetails.id}`, planDetails);

// delete planDetails
export const deletePlanDetails = (urlPlan, planDetails) =>
    del(`${url.UPDATE_PLAN}/${urlPlan}/details/${planDetails.id}`, { headers: { planDetails } });
//Fim PlanDetails

// get profiles
export const getProfiles = () => get(url.GET_PROFILES);

// add profile
export const addNewProfile = profile => post(url.ADD_NEW_PROFILE, profile);

// update profile
export const updateProfile = profile => put(`${url.UPDATE_PROFILE}/${profile.id}`, profile);

// delete profile
export const deleteProfile = profile =>
    del(`${url.DELETE_PROFILE}/${profile.id}`, { headers: { profile } });
//Fim Profile

//##############################################
// existe outro user na linha 377

// get users
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(`${url.UPDATE_USER}/${user.id}`, user);

// delete user
export const deleteUser = user =>
    del(`${url.DELETE_USER}/${user.id}`, { headers: { user } });
//Fim User

//##############################################



// get permissions
export const getPermissions = () => get(url.GET_PERMISSIONS);

// add permission
export const addNewPermission = permission => post(url.ADD_NEW_PERMISSION, permission);

// update permission
export const updatePermission = permission => put(`${url.UPDATE_PERMISSION}/${permission.id}`, permission);

// delete permission
export const deletePermission = permission =>
    del(`${url.DELETE_PERMISSION}/${permission.id}`, { headers: { permission } });
//Fim Permission

// get roles
export const getRoles = () => get(url.GET_ROLES);

// add role
export const addNewRole = role => post(url.ADD_NEW_ROLE, role);

// update role
export const updateRole = role => put(`${url.UPDATE_ROLE}/${role.id}`, role);

// delete role
export const deleteRole = role =>
    del(`${url.DELETE_ROLE}/${role.id}`, { headers: { role } });
//Fim Role


// get profilePermissionAvailables
export const getProfilePermissionsAvailable = (profileId) => get(`admin/profiles/${profileId}/permissions/create`);

// get profilePermission
export const getProfilePermissions = (profileId) => get(`admin/profiles/${profileId}/permissions`);

// add profilePermission
export const addNewProfilePermission = (profileId, payload) =>
    post(`/admin/profiles/${profileId}/permissions`, payload);

// delete profilePermission
export const deleteProfilePermission = (profileId, permissionId) =>
    del(`/admin/profiles/${profileId}/permission/${permissionId}/detach`, { headers: { profileId, permissionId } });

//Fim profilePermission


// get rolePermissionAvailables
export const getRolePermissionsAvailable = (roleId) => get(`admin/roles/${roleId}/permissions/create`);

// get rolePermission
export const getRolePermissions = (roleId) => get(`admin/roles/${roleId}/permissions`);

// add rolePermission
export const addNewRolePermission = (roleId, payload) =>
    post(`/admin/roles/${roleId}/permissions`, payload);

// delete rolePermission
export const deleteRolePermission = (roleId, permissionId) =>
    del(`/admin/roles/${roleId}/permission/${permissionId}/detach`, { headers: { roleId, permissionId } });

//Fim rolePermission



// get messages
export const getAnimalsByFarm = (farmId) =>
  get(`${url.GET_FARMS}/${farmId}/animals`);


//Inicio Animals
// get animals
export const getAnimals = () => get('qqqqq');

// update animal
export const updateAnimal = animal => put(`${url.UPDATE_ANIMAL}/${animal.id}`, animal);


// // get plans
// export const getPlans = () => get(url.GET_PLANS);

// // add plan
// export const addNewPlan = plan => post(url.ADD_NEW_PLAN, plan);

// // update plan
// export const updatePlan = plan => put(`${url.UPDATE_PLAN}/${plan.id}`, plan);

// // delete plan
// export const deletePlan = plan =>
//   del(`${url.DELETE_PLAN}/${plan.id}`, { headers: { plan } });
// //Fim plan



// Login Method
const postLogin = data => post(url.POST_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (data) => {

  return post(url.POST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// // get contacts
// export const getUsers = () => get(url.GET_USERS);

// // add user
// export const addNewUser = user => post(url.ADD_NEW_USER, user);

// // update user
// export const updateUser = user => put(url.UPDATE_USER, user);

// // delete user
// export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};
