import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_TENANTS,
  ADD_NEW_TENANT,
  UPDATE_TENANT,
  DELETE_TENANT,
} from "./actionTypes";
import {
  getTenantsFail,
  getTenantsSuccess,
  addTenantFail,
  addTenantSuccess,
  updateTenantSuccess,
  updateTenantFail,
  deleteTenantSuccess,
  deleteTenantFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getTenants,
  addNewTenant,
  updateTenant,
  deleteTenant,
} from "helpers/backend_fazenda_helper";

function* onGetTenants() {
  try {
    const response = yield call(getTenants);
    console.log('TenantSaga', response)
    yield put(getTenantsSuccess(response.data));
  } catch (error) {
    yield put(getTenantsFail(error));
  }
}

function* onUpdateTenant({ payload: tenant }) {
  try {
    const response = yield call(updateTenant, tenant);
    yield put(updateTenantSuccess(response.data));
  } catch (error) {
    yield put(updateTenantFail(error));
  }
}

function* onDeleteTenant({ payload: tenant }) {
  try {
    const response = yield call(deleteTenant, tenant);
    yield put(deleteTenantSuccess(response.data));
  } catch (error) {
    yield put(deleteTenantFail(error));
  }
}

function* onAddNewTenant({ payload: tenant }) {
  try {
    const response = yield call(addNewTenant, tenant);
    yield put(addTenantSuccess(response.data));
  } catch (error) {
    yield put(addTenantFail(error));
  }
}

function* tenantSaga() {
  yield takeEvery(GET_TENANTS, onGetTenants);
  yield takeEvery(ADD_NEW_TENANT, onAddNewTenant);
  yield takeEvery(UPDATE_TENANT, onUpdateTenant);
  yield takeEvery(DELETE_TENANT, onDeleteTenant);
}

export default tenantSaga;
