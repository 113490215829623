import React from "react"
import PropTypes from "prop-types";
import CapacidadeRadial from "./CapacidadeRadial";
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"
import { decimalValueMask } from "utils/inputMask";

const CapacidadeFazendas = ({ farm }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{farm.name}</CardTitle>

          <Row>
          <Col sm="6">
              <div className="mt-4 mt-sm-0">
              <CapacidadeRadial title="Ocupado Inferior" atual={farm.ocupacao_atual_minima} />
              </div>
            </Col>

            <Col sm="6">
              <div className="mt-4 mt-sm-0">
              <CapacidadeRadial title="Ocupado Superior" atual={farm.ocupacao_atual_maxima}/>
              </div>
            </Col>
          </Row>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
              <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Qtd de Alqueirão</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{decimalValueMask(farm.quantity_land)}</h5>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Qtd Não Reservado</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{decimalValueMask(farm.terra_reservada)}</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Uteis para Pastagem (ha)</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{decimalValueMask(farm.hectares_uteis)}</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Capatidade Minima</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{decimalValueMask(farm.capatidade_minima)}</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Capatidade Maxima</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{decimalValueMask(farm.capatidade_maxima)}</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

CapacidadeFazendas.propTypes = {
    farm: PropTypes.object,
};

export default CapacidadeFazendas
