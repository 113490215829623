import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_ROLE_USERS,
  GET_ROLE_USERS_AVAILABLES,
  ADD_ROLE_USER,
  DELETE_ROLE_USER,
} from "./actionTypes";

import {
  getRoleUsersSuccess,
  getRoleUsersFail,

  getRoleUsersAvailablesSuccess,
  getRoleUsersAvailablesFail,

  addNewRoleUserSuccess,
  addNewRoleUserFail,

  deleteRoleUserSuccess,
  deleteRoleUserFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getRoleUsers,
  getRoleUsersAvailable,
  addNewRoleUser,
  deleteRoleUser,
} from "helpers/backend_fazenda_helper";

function* onGetRoleUsers({userId}) {
  try {
    const response = yield call(getRoleUsers, userId);
    yield put(getRoleUsersSuccess(response.data));
  } catch (error) {
    yield put(getRoleUsersFail(error));
  }
}

function* onGetRoleUsersAvailable({userId}) {
  try {
    const response = yield call(getRoleUsersAvailable, userId);
    yield put(getRoleUsersAvailablesSuccess(response.data));
  } catch (error) {
    yield put(getRoleUsersAvailablesFail(error));
  }
}

function* onDeleteRoleUser({userId, roleId, payload}) {
  try {
    const response = yield call(deleteRoleUser, userId, roleId, payload);
    yield put(deleteRoleUserSuccess(response.data));
  } catch (error) {
    yield put(deleteRoleUserFail(error));
  }
}

function* onAddNewRoleUser({userId, payload}) {
  try {
    const response = yield call(addNewRoleUser, userId, payload);
    yield put(addNewRoleUserSuccess(response.data));
  } catch (error) {
    yield put(addNewRoleUserFail(error));
  }
}

function* RoleUsersSaga() {
  yield takeEvery(GET_ROLE_USERS, onGetRoleUsers);
  yield takeEvery(GET_ROLE_USERS_AVAILABLES, onGetRoleUsersAvailable);
  yield takeEvery(ADD_ROLE_USER, onAddNewRoleUser);
  yield takeEvery(DELETE_ROLE_USER, onDeleteRoleUser);
}

export default RoleUsersSaga;
