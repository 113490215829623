import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_PROFILES,
  ADD_NEW_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
} from "./actionTypes";
import {
  getProfilesFail,
  getProfilesSuccess,
  addProfileFail,
  addProfileSuccess,
  updateProfileSuccess,
  updateProfileFail,
  deleteProfileSuccess,
  deleteProfileFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProfiles,
  addNewProfile,
  updateProfile,
  deleteProfile,
} from "helpers/backend_fazenda_helper";

function* onGetProfiles() {
  try {
    const response = yield call(getProfiles);
    yield put(getProfilesSuccess(response.data));
  } catch (error) {
    yield put(getProfilesFail(error));
  }
}

function* onUpdateProfile({ payload: profile }) {
  try {
    console.log('saga', profile);
    const response = yield call(updateProfile, profile);
    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    yield put(updateProfileFail(error));
  }
}

function* onDeleteProfile({ payload: profile }) {
  try {
    const response = yield call(deleteProfile, profile);
    yield put(deleteProfileSuccess(response.data));
  } catch (error) {
    yield put(deleteProfileFail(error));
  }
}

function* onAddNewProfile({ payload: profile }) {
  try {
    const response = yield call(addNewProfile, profile);
    yield put(addProfileSuccess(response.data));
  } catch (error) {
    yield put(addProfileFail(error));
  }
}

function* profileSaga() {
  yield takeEvery(GET_PROFILES, onGetProfiles);
  yield takeEvery(ADD_NEW_PROFILE, onAddNewProfile);
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile);
  yield takeEvery(DELETE_PROFILE, onDeleteProfile);
}

export default profileSaga;
