import Breadcrumb from "components/Common/Breadcrumb";
import DocumentPrint from "components/DocumentPrint";
import { useReactToPrint } from 'react-to-print';

import React, { useRef } from "react";

import { Table, Container, Row, Col, CardBody, Card, Button, CardHeader, CardTitle, FormText } from "reactstrap";


const ProgressAnalysis = (props) => {

  const componentRef = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="page-content" >
      <Col xs="12">
        <Breadcrumb title="Analysis" breadcrumbItem="Progress-analysis" />
        <Container fluid style={{ alignItems: 'center', width: 'auto' }}>
          <Row>
            <Col sm="12" >
              <Card>
                <Row className="mb-1">
                  <Col sm="12" style={{ top: "35px", right: "15px" }}>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        onClick={handlePrint}>
                        Imprimir
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <DocumentPrint ref={componentRef}>
                    <FormText style={{ textAlign: "center" }}>
                      <CardTitle>
                        <strong>Análise Progresso - </strong>
                        <strong>Frigrofico Frisa - </strong>
                        <strong>14/11/2023</strong>
                      </CardTitle>
                    </FormText>
                    <CardBody>
                      <Table style={{ backgroundColor: "white" }} bordered>
                        <tbody>
                          <tr>
                            <th scope="row">
                              Dias da medição total
                            </th>
                            <td>
                              34.202
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Peso total em kg - final
                            </th>
                            <td>
                              25.228,00
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Peso total em kg - inicial
                            </th>
                            <td>
                              9.088,50
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Diferença peso total final menos peso total inicial
                            </th>
                            <td>
                              16.139,50
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Quantidade de gados total 46,00
                            </th>
                            <td>
                              46,00
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Quantidade de gados com brinco 46,00
                            </th>
                            <td>
                              46,00
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Peso total em @ - Final com brinco
                            </th>
                            <td>
                              840,93
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Peso total em @ - Inicial com brinco
                            </th>
                            <td>
                              302,95
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Ganho total peso final @ menos inicial @ com brinco
                            </th>
                            <td>
                              537,98
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                            </th>
                            <td>
                            </td>
                          </tr>
                          <tr className="table-light">
                            <th scope="row">
                              Ganho médio por gado em @ por dia

                            </th>
                            <td>
                              0,02
                            </td>
                          </tr>
                          <tr className="table-light">
                            <th scope="row">
                              Ganho médio por gado em @ por mês
                            </th>
                            <td>
                              0,47
                            </td>
                          </tr>
                          <tr className="table-light">
                            <th scope="row">
                              Ganho médio por gado em @ por ano
                            </th>
                            <td>
                              5,69
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </DocumentPrint>
                </Row>

              </Card>
            </Col>
          </Row>

        </Container>
      </Col>

    </div>
  )
}

export default ProgressAnalysis;