import create from 'zustand';
import axiosApi from 'helpers/api_helper';

export class Service {
  async get() {
    const response = await axiosApi.get(`/user`);
    return response.data ?? {};
  }
}

export const service = new Service();

export const useProfileStore = create((set) => ({
  isLoading: false,
  openModal: false,

  user: null,

  get: async () => {
    set({ isLoading: true });
    const user = await service.get();
    set({ user, isLoading: false });
  },

  setOpenModal: (openModal) => set({openModal})

}));

export default service;
