import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MetaTags, { ReactTitle } from 'react-meta-tags';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback
} from 'reactstrap';
import Select from 'react-select';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Message from 'components/Message';

// action
import { registerUser, apiError, getPlans as onGetPlans } from '../../store/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useParams } from 'react-router-dom';

// import images
import profileImg from '../../assets/images/profile-img.png';
import logoImg from '../../assets/images/imgWshare/profile-wshare-escura.png';

const Register = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [optionGroupPlan, setOptionGroupPlan] = useState([{ label: 'Breeds', options: [] }]);

  const plans = useSelector((state) => state.Plan.plans);

  const { user, registrationError, loading, error } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
    error: state.Account.error
  }));

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(apiError(''));
  }, []);

  useEffect(() => {
    dispatch(onGetPlans());
  }, []);

  useEffect(() => {
    setOptionGroupPlan(formatDataForSelect('Plan', plans));

    plans.map((item) => {
      if (item.id == id) return setSelectedPlan([{ label: item.name, value: item.id }]);
    });
  }, [plans]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      planId: id || '',
      cnpj: null || '',
      companyName: null || '',
      name: null || '',
      email: null || '',
      password: null || '',
      passwordConfirmation: null || ''
    },
    validationSchema: Yup.object({
      planId: Yup.number().required('Please Enter Your plan_id'),
      cnpj: Yup.string().required('Please Enter Your CNPJ'),
      companyName: Yup.string().required('Please Enter Your Company Name'),
      name: Yup.string().required('Please Enter Your Username'),
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
      passwordConfirmation: Yup.string().required('Please Confirm Your Password')
    }),
    onSubmit: (values) => {
      let valuesRegister = {
        plan_id: values['planId'],
        cnpj: values['cnpj'],
        company_name: values['companyName'],
        name: values['name'],
        email: values['email'],
        password: values['password'],
        password_confirmation: values['passwordConfirmation']
      };

      dispatch(registerUser(valuesRegister, props.history));
      if (!registrationError) {
        // validation.resetForm();
      }
      handleAsyncActions('Registered');
    }
  });

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };
  // handleValidSubmit
  const handleValidSubmit = (values) => {
    dispatch(registerUser(values));
  };

  function handleSelectPlan(selectedPlan) {
    setSelectedPlan(selectedPlan);
  }

  function formatDataForSelect(title, data = []) {
    if (data && data.length > 0) {
      return [
        {
          label: title,
          options: data.map((item) => {
            return { label: item.name, value: item.id };
          })
        }
      ];
    }
    return [
      {
        label: title,
        options: []
      }
    ];
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Farm SystemW</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Seja bem vindo ao SistemaW Fazendas</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      {user && user ? (
                        <Alert color="success">Register User Successfully</Alert>
                      ) : null}

                      {error && typeof error === 'string' ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <div className="mb-3">
                          <Label className="form-label">Plan</Label>
                          <Input
                            disabled={id ?? true}
                            name="planId"
                            type="select"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.planId || ''}
                            invalid={
                              (validation.touched.planId && validation.errors.planId) ||
                              registrationError.plan_id
                                ? true
                                : false
                            }>
                            <option value="">Selecione um tipo</option>
                            {plans.map((plan) => (
                              <option key={plan.id} value={plan.id}>
                                {plan.name}
                              </option>
                            ))}
                          </Input>
                          {(validation.touched.planId && validation.errors.planId) ||
                          registrationError.plan_id ? (
                            <FormFeedback type="invalid">
                              {registrationError.plan_id ?? validation.errors.planId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="mb-3">
                        <Label className="form-label">Plan</Label>
                        <Input
                          name="planId"
                          type="text"
                          readOnly
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.planId || ''}
                          invalid={(validation.touched.planId && validation.errors.planId) ||registrationError.planId ? true : false}
                        />
                        {(validation.touched.planId && validation.errors.planId) ||registrationError.planId ? (
                          <FormFeedback type="invalid">{registrationError.planId ?? validation.errors.planId}</FormFeedback>
                        ) : null}
                      </div> */}

                      <div className="mb-3">
                        <Label className="form-label">CNPJ or CPF</Label>
                        <Input
                          name="cnpj"
                          type="text"
                          placeholder="Insert CNPJ or CPF"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cnpj || ''}
                          invalid={
                            (validation.touched.cnpj && validation.errors.cnpj) ||
                            registrationError.cnpj
                              ? true
                              : false
                          }
                        />
                        {(validation.touched.cnpj && validation.errors.cnpj) ||
                        registrationError.cnpj ? (
                          <FormFeedback type="invalid">
                            {registrationError.cnpj ?? validation.errors.cnpj}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Tenant')}</Label>
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Insert Tenant name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ''}
                          invalid={
                            (validation.touched.companyName && validation.errors.companyName) ||
                            registrationError.company_name
                              ? true
                              : false
                          }
                        />
                        {(validation.touched.companyName && validation.errors.companyName) ||
                        registrationError.company_name ? (
                          <FormFeedback type="invalid">
                            {registrationError.company_name ?? validation.errors.companyName}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Name')}</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ''}
                          invalid={validation.touched.name && validation.errors.name ? true : false}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Email')}</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Insert your email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            (validation.touched.email && validation.errors.email) ||
                            registrationError.email
                              ? true
                              : false
                          }
                        />
                        {(validation.touched.email && validation.errors.email) ||
                        registrationError.email ? (
                          <FormFeedback type="invalid">
                            {registrationError.email ?? validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Password')}</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Insert your Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            (validation.touched.password && validation.errors.password) ||
                            registrationError.password
                              ? true
                              : false
                          }
                        />
                        {(validation.touched.password && validation.errors.password) ||
                        registrationError.password ? (
                          <FormFeedback type="invalid">
                            {registrationError.password ? (
                              <div>
                                <ul>
                                  {registrationError.password.map((item) => (
                                    <li key={item}>{item}</li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              validation.errors.password
                            )}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Password confirmation')}</Label>
                        <Input
                          name="passwordConfirmation"
                          type="password"
                          placeholder="Confirm Your Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirmation || ''}
                          invalid={
                            validation.touched.passwordConfirmation &&
                            validation.errors.passwordConfirmation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.passwordConfirmation &&
                        validation.errors.passwordConfirmation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.passwordConfirmation}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-primary btn-block " type="submit">
                          {props.t('Register')}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          {props.t(
                            'By registering you agree to the terms the SistemasW Fazendas WShare'
                          )}{' '}
                          <Link to="#" className="text-primary">
                            {props.t('Terms of Use')}
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t('Already have an account')} ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} {props.t('Farm SystemW WShare')}{' '}
                  <i className="mdi mdi-heart text-danger" /> {props.t('Developed by WShare')}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;

Register.propTypes = {
  history: PropTypes.object
};
