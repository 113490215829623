/* BREEDS */
export const GET_BREEDS = "GET_BREEDS"
export const GET_BREEDS_SUCCESS = "GET_BREEDS_SUCCESS"
export const GET_BREEDS_FAIL = "GET_BREEDS_FAIL"

/**
 * add BREED
 */
export const ADD_NEW_BREED = "ADD_NEW_BREED"
export const ADD_BREED_SUCCESS = "ADD_BREED_SUCCESS"
export const ADD_BREED_FAIL = "ADD_BREED_FAIL"

/**
 * Edit BREED
 */
export const UPDATE_BREED = "UPDATE_BREED"
export const UPDATE_BREED_SUCCESS = "UPDATE_BREED_SUCCESS"
export const UPDATE_BREED_FAIL = "UPDATE_BREED_FAIL"

/**
 * Delete BREED
 */
export const DELETE_BREED = "DELETE_BREED"
export const DELETE_BREED_SUCCESS = "DELETE_BREED_SUCCESS"
export const DELETE_BREED_FAIL = "DELETE_BREED_FAIL"

