import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

import SearchSynthetic from './SearchSynthetic';
import { withTranslation } from 'react-i18next';

function DroopSynthetic(props) {
  const [open, setOpen] = useState(false);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <i className="bx bx-search-alt search-icon" />{props.t("Search Filter")}</AccordionHeader>
          <AccordionBody accordionId="1">
            <SearchSynthetic />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default withTranslation()(DroopSynthetic);
