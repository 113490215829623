import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_FARMS,
  ADD_NEW_FARM,
  UPDATE_FARM,
  DELETE_FARM,
} from "./actionTypes";
import {
  getFarmsFail,
  getFarmsSuccess,
  addFarmFail,
  addFarmSuccess,
  updateFarmSuccess,
  updateFarmFail,
  deleteFarmSuccess,
  deleteFarmFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getFarms,
  addNewFarm,
  updateFarm,
  deleteFarm,
} from "helpers/backend_fazenda_helper";

function* onGetFarms() {
  try {
    const response = yield call(getFarms);
    yield put(getFarmsSuccess(response.data));
  } catch (error) {
    yield put(getFarmsFail(error));
  }
}

function* onUpdateFarm({ payload: farm }) {
  try {
    const response = yield call(updateFarm, farm);
    yield put(updateFarmSuccess(response.data));
  } catch (error) {
    yield put(updateFarmFail(error));
  }
}

function* onDeleteFarm({ payload: farm }) {
  try {
    const response = yield call(deleteFarm, farm);
    yield put(deleteFarmSuccess(response.data));
  } catch (error) {
    yield put(deleteFarmFail(error));
  }
}

function* onAddNewFarm({ payload: farm }) {
  try {
    const response = yield call(addNewFarm, farm);
    yield put(addFarmSuccess(response.data));
  } catch (error) {
    yield put(addFarmFail(error));
  }
}

function* farmSaga() {
  yield takeEvery(GET_FARMS, onGetFarms);
  yield takeEvery(ADD_NEW_FARM, onAddNewFarm);
  yield takeEvery(UPDATE_FARM, onUpdateFarm);
  yield takeEvery(DELETE_FARM, onDeleteFarm);
}

export default farmSaga;
