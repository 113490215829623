import { SHOW_MESSAGE, HIDE_MESSAGE } from "./actionTypes"

const INIT_STATE = {
  showMessage: false,
  error: {},
}

const messages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        showMessage: true,
      }

    case HIDE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }

    default:
      return state
  }
}

export default messages
