import { call, put, takeEvery } from 'redux-saga/effects';

// Ecommerce Redux States
import { GET_PLANS, ADD_NEW_PLAN, UPDATE_PLAN, DELETE_PLAN, ATTACH_PLAN_PROFILE } from './actionTypes';
import {
  getPlansFail,
  getPlansSuccess,
  addPlanFail,
  addPlanSuccess,
  updatePlanSuccess,
  updatePlanFail,
  deletePlanSuccess,
  deletePlanFail,
  attachPlanProfileSuccess,
  attachPlanProfileFail,

} from './actions';

//Include Both Helper File with needed methods
import { getPlans, addNewPlan, updatePlan, deletePlan, attachPlanProfile } from 'helpers/backend_fazenda_helper';

function* onGetPlans() {
  try {
    const response = yield call(getPlans);
    yield put(getPlansSuccess(response.data));
  } catch (error) {
    yield put(getPlansFail(error));
  }
}

function* onUpdatePlan({ payload: plan }) {
  try {
    const response = yield call(updatePlan, plan);
    yield put(updatePlanSuccess(response.data));
  } catch (error) {
    yield put(updatePlanFail(error));
  }

}

function* onAttachPlanProfile({ planId,payload }) {
    try {
      const response = yield call(attachPlanProfile, planId,payload);
      yield put(attachPlanProfileSuccess());
    } catch (error) {
      yield put(attachPlanProfileFail(error));
    }
  }

function* onDeletePlan({ payload: plan }) {
  try {
    const response = yield call(deletePlan, plan);
    yield put(deletePlanSuccess(response.data));
  } catch (error) {
    yield put(deletePlanFail(error));
  }
}

function* onAddNewPlan({ payload: plan }) {
  try {
    const response = yield call(addNewPlan, plan);
    yield put(addPlanSuccess(response.data));
  } catch (error) {
    yield put(addPlanFail(error));
  }
}

function* planSaga() {
  yield takeEvery(GET_PLANS, onGetPlans);
  yield takeEvery(ADD_NEW_PLAN, onAddNewPlan);
  yield takeEvery(UPDATE_PLAN, onUpdatePlan);
  yield takeEvery(DELETE_PLAN, onDeletePlan);
  yield takeEvery(ATTACH_PLAN_PROFILE, onAttachPlanProfile);
}

export default planSaga;
