/* KPICARDS */
export const GET_KPICARDS = "GET_KPICARDS"
export const GET_KPICARDS_SUCCESS = "GET_KPICARDS_SUCCESS"
export const GET_KPICARDS_FAIL = "GET_KPICARDS_FAIL"

/**
 * add KPICARD
 */
export const ADD_NEW_KPICARD = "ADD_NEW_KPICARD"
export const ADD_KPICARD_SUCCESS = "ADD_KPICARD_SUCCESS"
export const ADD_KPICARD_FAIL = "ADD_KPICARD_FAIL"

/**
 * Edit KPICARD
 */
export const UPDATE_KPICARD = "UPDATE_KPICARD"
export const UPDATE_KPICARD_SUCCESS = "UPDATE_KPICARD_SUCCESS"
export const UPDATE_KPICARD_FAIL = "UPDATE_KPICARD_FAIL"

/**
 * Delete KPICARD
 */
export const DELETE_KPICARD = "DELETE_KPICARD"
export const DELETE_KPICARD_SUCCESS = "DELETE_KPICARD_SUCCESS"
export const DELETE_KPICARD_FAIL = "DELETE_KPICARD_FAIL"

