import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Message from 'components/Message';

import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getBreeds as onGetBreeds,
  addNewBreed as onAddNewBreed,
  updateBreed as onUpdateBreed,
  deleteBreed as onDeleteBreed
} from 'store/breeds/actions';

import { showMessage, hideMessage } from 'store/message/actions';

import { useSelector, useDispatch } from 'react-redux';
import ActionTable from 'components/ActionTable';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const Breeds = (props) => {
  const dispatch = useDispatch();

  const { breeds } = useSelector((state) => ({
    breeds: state.Breed.breeds
  }));

  const [deleteModal, setDeleteModal] = useState(false);

  const { error } = useSelector((state) => ({ error: state.Breed.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [breedList, setBreedList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [breed, setBreed] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    if (breeds && !breeds.length) {
      dispatch(onGetBreeds());
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (breed && breed.name) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBreed = {
          id: breed ? breed.id : 0,
          name: values.name
        };

        dispatch(onUpdateBreed(updateBreed));
        validation.resetForm();

        handleAsyncActions('Updated');
      } else {
        const newBreed = {
          name: values['name']
        };

        dispatch(onAddNewBreed(newBreed));
        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  const pageOptions = {
    totalSize: breeds.length, // replace later with size(orders),
    custom: true
  };


  const BreedColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,

      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,

      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: `${props.t('action')}`,

      formatter: (_cellContent, breed) => (
        <ActionTable
          editPermissions={['edit_breads']}
          deletePermissions={['destroy_breads']}
          handleDelete={() => handleDeleteBreed(breed)}
          handleEdit={() => handleBreedClick(breed)}
        />
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setBreed(null);
    } else {
      setModal(true);
    }
  };

  const handleBreedClick = (arg) => {
    const breed = arg;

    setBreed({
      id: breed.id,
      name: breed.name
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteBreed = (data) => {
    if (data.id) {
      dispatch(onDeleteBreed(data));
      onPaginationPageChange(1);
      setDeleteModal(false);

      handleAsyncActions('Deleted');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Breed ${action} Successfully`, 'success');
    }
  };

  const { SearchBar } = Search;

  const handleTableChange = (type, { page, searchText }) => {
    setBreedList(
      breeds.filter((breed) =>
        Object.keys(breed).some((key) =>
          breed[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handleBreedClicks = () => {
    setBreedList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />
      <div className="page-content">
        <MetaTags>
          <title>Breeds | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Breeds')} breadcrumbItem={props.t('Breeds')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={BreedColumns}
                    data={breeds}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={breeds || []}
                        columns={BreedColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_breads']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleBreedClicks}>
                                      <i className="mdi mdi-plus me-1" />
                                      {props.t('New Breed')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <div className="float-sm-end">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div> */}
                              <Col xl="12">

                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns">
                                  <Table id="bread-1">
                                    <BootstrapTable
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={'table table-striped table-bordered'}
                                      keyField="id"
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      ref={node}
                                    />
                                  </Table>
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Breed' : 'Add Breed'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Name Breed')}
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-breed">
                                              {props.t('Save')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </Col>
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Breeds.propTypes = {
  breeds: PropTypes.array,
  onGetBreeds: PropTypes.func,
  onAddNewBreed: PropTypes.func,
  onDeleteBreed: PropTypes.func,
  onUpdateBreed: PropTypes.func
};

export default withTranslation()(Breeds);
