import {
  GET_PROFILES,
  GET_PROFILES_FAIL,
  GET_PROFILES_SUCCESS,
  ADD_NEW_PROFILE,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  DELETE_PROFILE,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAIL,

} from "./actionTypes"

export const getProfiles = () => ({
  type: GET_PROFILES,
})

export const getProfilesSuccess = profiles => ({
  type: GET_PROFILES_SUCCESS,
  payload: profiles,
})

export const getProfilesFail = error => ({
  type: GET_PROFILES_FAIL,
  payload: error,
})

export const addNewProfile = profile => ({
  type: ADD_NEW_PROFILE,
  payload: profile,
})

export const addProfileSuccess = profile => ({
  type: ADD_PROFILE_SUCCESS,
  payload: profile,
})

export const addProfileFail = error => ({
  type: ADD_PROFILE_FAIL,
  payload: error,
})

export const updateProfile = profile => ({
  type: UPDATE_PROFILE,
  payload: profile,
})

export const updateProfileSuccess = profile => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: profile,
})

export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
})

export const deleteProfile = profile => ({
  type: DELETE_PROFILE,
  payload: profile,
})

export const deleteProfileSuccess = profile => ({
  type: DELETE_PROFILE_SUCCESS,
  payload: profile,
})

export const deleteProfileFail = error => ({
  type: DELETE_PROFILE_FAIL,
  payload: error,
})

