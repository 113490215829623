export const USE_TENANT_STORAGE = '@tenant/storage';

export const STATUS_TENANT = {
  undefined: -3,
  notExists: -2,
  notPayment: -1,
  loading: 0,
  exists: 1
};

export const SYSTEM_URL = process.env.SYSTEM_URL;

export const ENABLE_PATH_ROOT_URL = ['/landing', '/checkout'];
