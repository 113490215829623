import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import Message from 'components/Message';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from 'components/Common/DeleteModal';
import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser
} from 'store/users/actions';

import { showMessage, hideMessage } from 'store/message/actions';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useProfileStore } from 'zustand/profile/profile-store';

const Users = (props) => {
  const profileStore = useProfileStore();
  const urlLocation = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { users } = useSelector((state) => ({
    users: state.User.users
  }));

  const { error } = useSelector((state) => ({ error: state.User.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(null);
  const { user: authUser } = useProfileStore();

  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  useEffect(() => {
    if (!authUser || !profileStore.openModal) {
      return null;
    }
    switch (queryParams?.edit) {
      case 'profile':
        handleUserClick(authUser.user);
        break;
      default:
        break;
    }
  }, [authUser, profileStore.openModal]);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setUserList(users);
    }
  }, [users]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (user && user.name) || '',
      email: (user && user.email) || '',
      password: (user && user.password) || '',
      active: user && user.active == 1 ? 1 : user && user.active == 0 ? 0 : 1
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name'),
      email: Yup.string().required('Please Enter Your email'),
      active: Yup.boolean().required('Please Enter Your Active')
    }),
    onSubmit: (values) => {
      console.log(isEdit);

      if (isEdit) {
        const updateUser = {
          id: user ? user.id : 0,
          name: values.name,
          email: values.email,
          password: values.password,
          active: values.active
        };

        dispatch(onGetUsers());
        validation.resetForm();

        dispatch(onUpdateUser(updateUser));

        handleAsyncActions('Updated');

        if (queryParams?.edit == 'profile') {
          profileStore.get();
          history.push('/users');
        }
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name'],
          email: values['email'],
          password: values['password'],
          active: values['active']
        };

        dispatch(onAddNewUser(newUser));

        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  // Pagination customization
  const pageOptions = {
    totalSize: users.length, // replace later with size(orders),
    custom: true
  };

  const UserColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,
      sort: true
    },
    {
      dataField: 'email',
      text: `${props.t('email')}`,
      sort: true
    },
    {
      dataField: 'active',
      text: `${props.t('status')}`,
      sort: true,
      formatter: (cellContent, row) => (row.active == 1 ? <>Ativo</> : <>Inativo</>)
    },
    {
      dataField: 'button-actions',
      text: `${props.t('action')}`,
      formatter: (cellContent, user) => (
        <div className="btn-group float-end gap-2">
          <Can permissions={['edit_users']}>
            <Button
              type="button"
              color="success"
              size="sm"
              className=""
              onClick={() => handleModalUserRoleClick(user)}>
              <i className="fas fa-user-shield" />
            </Button>
          </Can>
          <Can permissions={['edit_users']}>
            <Button
              type="button"
              color="warning"
              size="sm"
              className=""
              onClick={() => handleUserClick(user)}>
              <i className="fa fa-pen" />
            </Button>
          </Can>
          <Can permissions={['destroy_users']}>
            <Button
              type="button"
              color="danger"
              size="sm"
              className=""
              onClick={() => onClickDelete(user)}>
              <i className="fa fa-trash" />
            </Button>
          </Can>
        </div>
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      profileStore.setOpenModal(true);
      setModal(false);
      setUser(null);
    } else {
      profileStore.setOpenModal(false);
      setModal(true);
    }
  };

  const handleUserClick = (user) => {
    setUser({
      id: user.id,
      name: user.name,
      email: user.email,
      active: user.active
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  // Delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (user) => {
    setUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (user.id) {
      dispatch(onDeleteUser(user));
      onPaginationPageChange(1);
      setDeleteModal(false);

      handleAsyncActions('Deleted');
    }
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`User ${action} Successfully`, 'success');
      dispatch(onGetUsers());
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList(
      users.filter((user) =>
        Object.keys(user).some((key) => user[key].toLowerCase().includes(searchText.toLowerCase()))
      )
    );
  };

  const handleModalUserRoleClick = (user) => {
    history.push(`/users/${user.id}/roles`);
  };

  const handleUserClicks = () => {
    setUserList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Master CRM</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Users" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Message
                    messageAlert={messageAlert}
                    typeMessage={typeMessage}
                    onDismiss={() => setMessageAlert('')}
                  />

                  <PaginationProvider pagination={paginationFactory(pageOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userList || []}
                        columns={UserColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <Can permissions={['create_users']}>
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <Button
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={handleUserClicks}>
                                      <i className="mdi mdi-plus me-1" />
                                      New User
                                    </Button>
                                  </div>
                                </Can>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <div className="d-inline-flex flex-wrap gap-2 mb-2">
                                    <Can permissions={['read_users']}>
                                      <div className="me-2">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                        </div>
                                      </div>
                                    </Can>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              {/* <div className="float-sm-end">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div> */}
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </Col>
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                  <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteUser}
                    onCloseClick={() => setDeleteModal(false)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? 'Edit User' : 'Add User'}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                <Row form>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        invalid={
                          validation.touched.password && validation.errors.password ? true : false
                        }
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="d-block mb-3">Status</Label>
                      <div className="form-check form-check-inline">
                        <Input
                          type="radio"
                          id="active1"
                          name="active"
                          className="form-check-input"
                          value="1"
                          checked={validation.values.active === 1}
                          onChange={validation.handleChange}
                        />
                        <Label className="form-check-label" htmlFor="active1">
                          Active
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          type="radio"
                          id="active0"
                          name="active"
                          className="form-check-input"
                          value="0"
                          checked={validation.values.active === 0}
                          onChange={validation.handleChange}
                        />
                        <Label className="form-check-label" htmlFor="active0">
                          Inactive
                        </Label>
                      </div>
                      {validation.touched.active && validation.errors.active ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.active}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button type="submit" color="success">
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onDeleteUser: PropTypes.func
};

export default withTranslation()(Users);
