import React, { useState } from "react"
import PropTypes from 'prop-types'
import SweetAlert from "react-bootstrap-sweetalert"

import { useSelector } from "react-redux"

const Message = props => {

    const isShow = useSelector((state) => state.Message.showMessage);

    return (
        <React.Fragment>
            {isShow && (
                <SweetAlert
                    title={props.title}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    type={props.type}
                    onConfirm={() => {return false}}
                    ></SweetAlert>
            )}
        </React.Fragment>
    )
}

Message.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string
  }

  export default Message
