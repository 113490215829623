/* PERMISSIONS */
export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS"
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL"

/**
 * add PERMISSION
 */
export const ADD_NEW_PERMISSION = "ADD_NEW_PERMISSION"
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS"
export const ADD_PERMISSION_FAIL = "ADD_PERMISSION_FAIL"

/**
 * Edit PERMISSION
 */
export const UPDATE_PERMISSION = "UPDATE_PERMISSION"
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS"
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL"

/**
 * Delete PERMISSION
 */
export const DELETE_PERMISSION = "DELETE_PERMISSION"
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS"
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL"

