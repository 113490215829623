import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { Modal } from "reactstrap";

import Message from "components/Message";

import { showMessage, hideMessage } from "store/message/actions";

import { useSelector, useDispatch } from "react-redux";

import { useHistory, useParams } from 'react-router-dom';

import {
    getProfilePermissions as onGetProfilePermissions,
    getProfilePermissionsAvailable as onGetProfilePermissionsAvailable,
    addNewProfilePermission as onAddNewProfilePermission,
    deleteProfilePermission as onDeleteProfilePermission,
} from "store/actions";

const ModalPermissions = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const { profilePermissions, permissionsAvailable } = useSelector( 
        function (state) {
        return {...state.ProfilePermissions};
    });

    const [ selectedFilter, setSelectedFilter ] = useState([]);
    const [ permissionsAvailableList, setPermissionsAvailableList ] = useState([]);
    const [ profilePermissionsList, setProfilePermissionsList ] = useState([]);
    
    const { error } = useSelector( function(state) {
        return ({error: state.ProfilePermissions.error})
    });
    
    const [ messageAlert, setMessageAlert] = useState("");
    const [ typeMessage, setTypeMessage ] = useState("");

    useEffect(() => {
        if ( error != null && Object.keys(error).length !== 0 && error.response.data) {
          showingMessage(error.response.data.message, 'warning');
        }
      }, [error]);

    useEffect(() => {
        if (permissionsAvailable && permissionsAvailable.permissions) {
            setPermissionsAvailableList(formatData(permissionsAvailable.permissions));
        }
    }, [permissionsAvailable]);

    useEffect(() => {
        if (profilePermissions && profilePermissions.permissions) {
            setProfilePermissionsList(formatData(profilePermissions.permissions));

            let availables = [];

            profilePermissions.permissions.forEach(permission => availables.push(permission.id));
            setSelectedFilter(availables);
        }
    }, [profilePermissions]);

    const formatData = argData => {
        const data = argData.map(item => {
            return { label: item.name, value: item.id };
        });
        return data;
    }

    useEffect( () => {
        dispatch(onGetProfilePermissions(id));
        dispatch(onGetProfilePermissionsAvailable(id));
    }, []);

    const handleAsyncActions = (action) => {
        if (error == null || Object.keys(error).length === 0) {
            showingMessage(`Permissions ${action} Successfully`, 'success');
        }
      };
    
      const showingMessage = (title, type) => {
        setMessageAlert(title);
        setTypeMessage(type);
    
        dispatch(showMessage());
        setTimeout(() => { dispatch(hideMessage()) }, 2000)
      }

    const onFilterChange = (selectedFilter) => {
        setSelectedFilter(selectedFilter);
    }

    const onHandleBackToProfiles = () => {
        history.goBack();
    }

    const onHandleAddProfilePermission = () => {
        console.log(selectedFilter);
        dispatch(onAddNewProfilePermission(id, {"permissions" :selectedFilter}));
        handleAsyncActions('Applied');
    }

    return(
        <>
        <Message title={messageAlert} type={typeMessage} />

        <Modal
            isOpen={true}
            backdrop={'static'}
            toggle={onHandleBackToProfiles}
            id="staticBackdrop"
            >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Permissions List</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={onHandleBackToProfiles}
                    aria-label="Close"
                    ></button>
            </div>
            <div className="modal-body">
                <p>Move one or more permissions to the right</p>
                { profilePermissionsList.length == 0 && permissionsAvailableList == 0 ?
                    <p>No have permissions! Add permissions.</p>
                    : <></>
                }
                <DualListBox
                 
                    filterPlaceholder="Search..."
                    options={[...profilePermissionsList, ...permissionsAvailableList]}
                    selected={selectedFilter}
                    onChange={(item) => onFilterChange(item)}
                    />

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={onHandleBackToProfiles}
                    >
                    Close
                </button>
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={onHandleAddProfilePermission}
                    >Apply</button>
            </div>
        </Modal>
        </>
    )
}

ModalPermissions.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    onGetProfilePermissions: PropTypes.func,
    onGetProfilePermissionsAvailable: PropTypes.func,
    addNewProfilePermission: PropTypes.func,
    deleteProfilePermission: PropTypes.func,
}

export default ModalPermissions
