import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_PERMISSIONS,
  ADD_NEW_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
} from "./actionTypes";
import {
  getPermissionsFail,
  getPermissionsSuccess,
  addPermissionFail,
  addPermissionSuccess,
  updatePermissionSuccess,
  updatePermissionFail,
  deletePermissionSuccess,
  deletePermissionFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getPermissions,
  addNewPermission,
  updatePermission,
  deletePermission,
} from "helpers/backend_fazenda_helper";

function* onGetPermissions() {
  try {
    const response = yield call(getPermissions);
    yield put(getPermissionsSuccess(response.data));
  } catch (error) {
    yield put(getPermissionsFail(error));
  }
}

function* onUpdatePermission({ payload: permission }) {
  try {
    const response = yield call(updatePermission, permission);
    yield put(updatePermissionSuccess(response.data));
  } catch (error) {
    yield put(updatePermissionFail(error));
  }
}

function* onDeletePermission({ payload: permission }) {
  try {
    const response = yield call(deletePermission, permission);
    yield put(deletePermissionSuccess(response.data));
  } catch (error) {
    yield put(deletePermissionFail(error));
  }
}

function* onAddNewPermission({ payload: permission }) {
  try {
    const response = yield call(addNewPermission, permission);
    yield put(addPermissionSuccess(response.data));
  } catch (error) {
    yield put(addPermissionFail(error));
  }
}

function* permissionSaga() {
  yield takeEvery(GET_PERMISSIONS, onGetPermissions);
  yield takeEvery(ADD_NEW_PERMISSION, onAddNewPermission);
  yield takeEvery(UPDATE_PERMISSION, onUpdatePermission);
  yield takeEvery(DELETE_PERMISSION, onDeletePermission);
}

export default permissionSaga;
