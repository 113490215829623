/* FARMS */
export const GET_FARMS = "GET_FARMS"
export const GET_FARMS_SUCCESS = "GET_FARMS_SUCCESS"
export const GET_FARMS_FAIL = "GET_FARMS_FAIL"

/**
 * add FARM
 */
export const ADD_NEW_FARM = "ADD_NEW_FARM"
export const ADD_FARM_SUCCESS = "ADD_FARM_SUCCESS"
export const ADD_FARM_FAIL = "ADD_FARM_FAIL"

/**
 * Edit FARM
 */
export const UPDATE_FARM = "UPDATE_FARM"
export const UPDATE_FARM_SUCCESS = "UPDATE_FARM_SUCCESS"
export const UPDATE_FARM_FAIL = "UPDATE_FARM_FAIL"

/**
 * Delete FARM
 */
export const DELETE_FARM = "DELETE_FARM"
export const DELETE_FARM_SUCCESS = "DELETE_FARM_SUCCESS"
export const DELETE_FARM_FAIL = "DELETE_FARM_FAIL"

