import {
  GET_FARMS,
  GET_FARMS_FAIL,
  GET_FARMS_SUCCESS,
  ADD_NEW_FARM,
  ADD_FARM_SUCCESS,
  ADD_FARM_FAIL,
  UPDATE_FARM,
  UPDATE_FARM_SUCCESS,
  UPDATE_FARM_FAIL,
  DELETE_FARM,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAIL,

} from "./actionTypes"

export const getFarms = () => ({
  type: GET_FARMS,
})

export const getFarmsSuccess = farms => ({
  type: GET_FARMS_SUCCESS,
  payload: farms,
})

export const getFarmsFail = error => ({
  type: GET_FARMS_FAIL,
  payload: error,
})

export const addNewFarm = farm => ({
  type: ADD_NEW_FARM,
  payload: farm,
})

export const addFarmSuccess = farm => ({
  type: ADD_FARM_SUCCESS,
  payload: farm,
})

export const addFarmFail = error => ({
  type: ADD_FARM_FAIL,
  payload: error,
})

export const updateFarm = farm => ({
  type: UPDATE_FARM,
  payload: farm,
})

export const updateFarmSuccess = farm => ({
  type: UPDATE_FARM_SUCCESS,
  payload: farm,
})

export const updateFarmFail = error => ({
  type: UPDATE_FARM_FAIL,
  payload: error,
})

export const deleteFarm = farm => ({
  type: DELETE_FARM,
  payload: farm,
})

export const deleteFarmSuccess = farm => ({
  type: DELETE_FARM_SUCCESS,
  payload: farm,
})

export const deleteFarmFail = error => ({
  type: DELETE_FARM_FAIL,
  payload: error,
})

