import {

  GET_ANIMALS,
  GET_ANIMALS_FAIL,
  GET_ANIMALS_SUCCESS,
  UPDATE_ANIMAL,
  UPDATE_ANIMAL_SUCCESS,
  UPDATE_ANIMAL_FAIL,


} from "./actionTypes"

export const getAnimals = () => ({
  type: GET_ANIMALS,
})

export const getAnimalsSuccess = animals => ({
  type: GET_ANIMALS_SUCCESS,
  payload: animals,
})

export const getAnimalsFail = error => ({
  type: GET_ANIMALS_FAIL,
  payload: error,
})

export const updateAnimal = animal => ({
  type: UPDATE_ANIMAL,
  payload: animal,
})

export const updateAnimalSuccess = animal => ({
  type: UPDATE_ANIMAL_SUCCESS,
  payload: animal,
})

export const updateAnimalFail = error => ({
  type: UPDATE_ANIMAL_FAIL,
  payload: error,
})



