import {
  GET_ANIMALS_SUCCESS,
  GET_ANIMALS_FAIL,
  UPDATE_ANIMAL_SUCCESS,
  UPDATE_ANIMAL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  animals: [],
  error: {},
};

const Animal = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ANIMALS_SUCCESS:
      return {
        ...state,
        animals: action.payload,
      };

    case GET_ANIMALS_FAIL:
      return {
        ...state,
        error: action.payload,
      };



    case UPDATE_ANIMAL_SUCCESS:
      return {
        ...state,
        animals: state.animals.map(animal =>
          animal.id.toString() === action.payload.id.toString()
            ? { animal, ...action.payload }
            : animal
        ),
      };

    case UPDATE_ANIMAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default Animal;
