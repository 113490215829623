import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { decimalValueMask } from "utils/inputMask";

const CapacidadeRadial = ({title, atual}) => {
    let colorDanger = "#f46a6a";
    let colorWarning = "#f1b44c";
    let color = "#556ee6";

    if( atual >= 20 && atual < 92){
        color = colorWarning;


    }
    if(atual >= 92 ){
        color = colorDanger;
    }


  const series = [atual];
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: function (e) {
              return decimalValueMask(e) + "%";
            },
          },
        },
      },
    },
    colors: [color],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.20,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 75, 91]
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [title],
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="200"
      className="apex-charts"
    />
  );
};

CapacidadeRadial.propTypes = {
    title: PropTypes.string.isRequired,
    max: PropTypes.number,
    atual: PropTypes.number,
};

export default CapacidadeRadial;
