export const ENTRADA = 'E';
export const MOVIMENTO_OPERACAO_SAIDA = 'S';
export const MOVIMENTO_OPERACAO_TRANSFERENCIA_ENTRADA = 'TE';
export const MOVIMENTO_OPERACAO_TRANSFERENCIA_SAIDA = 'TS';
export const MOVIMENTO_OPERACAO_AVALIACAO = 'AV';

export const MOVIMENTO_CREATED_ENTRADA = 'Criado';
export const MOVIMENTO_CREATED_SAIDA = 'Criado';
export const MOVIMENTO_CREATED_TRANSFERENCIA_ENTRADA = 'Criado';
export const MOVIMENTO_CREATED_TRANSFERENCIA_SAIDA = 'Criado';
export const MOVIMENTO_CREATED_AVALIACAO = 'Criado';

export const MOVIMENTO_ID_COMPRA_ANIMAIS = 1;
export const MOVIMENTO_ID_ENTRADA_DOACAO_ANIMAIS = 2;
export const MOVIMENTO_ID_VENDA_ANIMAIS = 3;
export const MOVIMENTO_ID_ENTRADA_TRANSFERENCIA = 4;
export const MOVIMENTO_ID_SAIDA_TRANSFERENCIA = 5;
export const MOVIMENTO_ID_FALECIMENTO_ANIMAIS = 6;
export const MOVIMENTO_ID_PERDA_ANIMAIS = 7;
export const MOVIMENTO_ID_ROUBO_ANIMAIS = 8;
export const MOVIMENTO_ID_SAIDA_DOACAO_ANIMAIS = 9;
export const MOVIMENTO_ID_AVALIACAO_SEMANAL = 10;
export const MOVIMENTO_ID_NASCIMENTO_ANIMAIS = 11;
export const MOVIMENTO_ID_SALDO_INICIAL = 12;
