import {
  GET_TENANTS_SUCCESS,
  GET_TENANTS_FAIL,
  ADD_TENANT_SUCCESS,
  ADD_TENANT_FAIL,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAIL,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  tenants: [],
  error: {},
};

const Tenant = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: action.payload,
      };

    case GET_TENANTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TENANT_SUCCESS:
      return {
        ...state,
        tenants: [...state.tenants, action.payload],
      };

    case ADD_TENANT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        tenants: state.tenants.map(tenant =>
          tenant.id === action.payload.id.toString()
            ? { tenant, ...action.payload }
            : tenant
        ),
      };

    case UPDATE_TENANT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TENANT_SUCCESS:
      return {
        ...state,
        tenants: state.tenants.filter(
          tenant => tenant.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TENANT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Tenant;
