import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import Message from 'components/Message';
import Breadcrumbs from 'components/Common/Breadcrumb';
import DeleteModal from 'components/Common/DeleteModal';
import {
  getRoles as onGetRoles,
  addNewRole as onAddNewRole,
  updateRole as onUpdateRole,
  deleteRole as onDeleteRole
} from 'store/roles/actions';
import { showMessage, hideMessage } from 'store/message/actions';
import { useSelector, useDispatch } from 'react-redux';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const Roles = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { roles } = useSelector((state) => ({
    roles: state.Role.roles
  }));

  const { error } = useSelector((state) => ({ error: state.Role.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(roles)) {
      setRoleList(roles);
    }
  }, [roles]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (role && role.name) || '',
      description: role && role.description
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name'),
      description: Yup.string().required('Please Enter With Description')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateRole = {
          id: role ? role.id : 0,
          name: values.name,
          description: values.description
        };
        dispatch(onUpdateRole(updateRole));
        validation.resetForm();
        handleAsyncActions('Updated');
      } else {
        const newRole = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name'],
          description: values['description']
        };
        dispatch(onAddNewRole(newRole));
        validation.resetForm();
        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  const pageOptions = {
    totalSize: roles.length,
    custom: true
  };

  const RoleColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: false,
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,
      sort: true
    },
    {
      dataField: 'description',
      text: `${props.t('description')}`,
      sort: true
    },
    {
      dataField: 'button-actions',
      text: `${props.t('action')}`,
      formatter: (cellContent, role) => (
        <div className="btn-group float-end gap-2">
          <Can permissions={['edit_role_permissions']}>
            <Button
              type="button"
              color="success"
              size="sm"
              className=""
              onClick={() => handleModalRolePermissionClick(role)}>
              <i className="fas fa-user-shield" />
            </Button>
          </Can>
          <Can permissions={['edit_roles']}>
            <Button
              type="button"
              color="warning"
              size="sm"
              className=""
              onClick={() => handleRoleClick(role)}>
              <i className="fa fa-pen" />
            </Button>
          </Can>
          <Can permissions={['destroy_roles']}>
            <Button
              type="button"
              color="danger"
              size="sm"
              className=""
              onClick={() => onClickDelete(role)}>
              <i className="fa fa-trash" />
            </Button>
          </Can>
        </div>
      )
    }
  ];

  const toggle = () => {
    setModal(!modal);
    if (!modal) setRole(null);
  };

  const handleRoleClick = (arg) => {
    setRole(arg);
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (role) => {
    setRole(role);
    setDeleteModal(true);
  };

  const handleDeleteRole = () => {
    if (role.id) {
      dispatch(onDeleteRole(role));
      onPaginationPageChange(1);
      setDeleteModal(false);
      handleAsyncActions('Deleted');
    }
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Profile ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);
    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  const handleTableChange = (type, { page, searchText }) => {
    setRoleList(
      roles.filter((role) =>
        Object.keys(role).some((key) => role[key].toLowerCase().includes(searchText.toLowerCase()))
      )
    );
  };

  const handleModalRolePermissionClick = (role) => {
    history.push(`/roles/${role.id}/permissions`);
  };

  const handleRoleClicks = () => {
    setRoleList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const handleValidDate = (date) => {
    return moment(new Date(date)).format('DD MMM Y');
  };

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRole}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Role | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Roles')} breadcrumbItem={props.t('Roles')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={RoleColumns}
                    data={roles}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={roles || []}
                        columns={RoleColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_roles']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={handleRoleClicks}>
                                      <i className="mdi mdi-plus me-1" />
                                      {props.t('New Role')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <div className="float-sm-end">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div> */}
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Role' : 'Add Role'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Name Role')}
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row form>

                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Description')}
                                            </Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.description || ''}
                                              invalid={
                                                validation.touched.description &&
                                                  validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.description &&
                                              validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-role">
                                              {props.t('Save')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </Col>
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Roles.propTypes = {
  roles: PropTypes.array,
  onGetRoles: PropTypes.func,
  onAddNewRole: PropTypes.func,
  onDeleteRole: PropTypes.func,
  onUpdateRole: PropTypes.func
};

export default withTranslation()(Roles);
