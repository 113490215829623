import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  NavItem
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { currencyMask, sendBancoDadosPrice } from '../../utils/inputMask';

import Message from 'components/Message';

import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getFarms as onGetFarms,
  addNewFarm as onAddNewFarm,
  updateFarm as onUpdateFarm,
  deleteFarm as onDeleteFarm
} from 'store/farms/actions';

import { showMessage, hideMessage } from 'store/message/actions';

import { useSelector, useDispatch } from 'react-redux';
import ActionTable from 'components/ActionTable';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const Farms = (props) => {
  const dispatch = useDispatch();

  const { farms } = useSelector((state) => ({
    farms: state.Farm.farms
  }));

  const { error } = useSelector((state) => ({ error: state.Farm.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [_farmList, setFarmList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [farm, setFarm] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetFarms());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (farm && farm.name) || '',
      active: farm && farm.active == 1 ? 1 : farm && farm.active == 0 ? 0 : 1,
      quantity_land: (farm && farm.quantity_land) || '',
      quantity_unit_land: (farm && farm.quantity_unit_land) || '',
      legal_reservation_amount: (farm && farm.legal_reservation_amount) || '',
      top_index: (farm && farm.top_index) || '',
      lower_index: (farm && farm.lower_index) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name'),
      active: Yup.boolean().required('Please Enter Your Active'),
      quantity_land: Yup.string().required('Please Enter Your Quantity Land'),
      quantity_unit_land: Yup.string().required('Please Enter Your Quantity Unit Land'),
      legal_reservation_amount: Yup.string().required('Please Enter Your Legal Reservation Amount'),
      top_index: Yup.string().required('Please Enter Your Top Index'),
      lower_index: Yup.string().required('Please Enter Your Lower Index')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateFarm = {
          id: farm ? farm.id : 0,
          name: values.name,
          active: values.active,
          quantity_land: sendBancoDadosPrice(values.quantity_land),
          quantity_unit_land: sendBancoDadosPrice(values.quantity_unit_land),
          legal_reservation_amount: sendBancoDadosPrice(values.legal_reservation_amount),
          top_index: sendBancoDadosPrice(values.top_index),
          lower_index: sendBancoDadosPrice(values.lower_index)
        };

        dispatch(onUpdateFarm(updateFarm));
        validation.resetForm();

        handleAsyncActions('Update');
      } else {
        const newFarm = {
          name: values.name,
          active: values.active,
          quantity_land: sendBancoDadosPrice(values.quantity_land),
          quantity_unit_land: sendBancoDadosPrice(values.quantity_unit_land),
          legal_reservation_amount: sendBancoDadosPrice(values.legal_reservation_amount),
          top_index: sendBancoDadosPrice(values.top_index),
          lower_index: sendBancoDadosPrice(values.lower_index)
        };

        dispatch(onAddNewFarm(newFarm));
        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  const pageOptions = {
    totalSize: farms.length,
    custom: true
  };

  const FarmColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,

      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,
      sort: true
    },
    {
      dataField: 'active',
      text: `${props.t('status')}`,
      sort: true,
      formatter: (_cellContent, row) => (row.active == 1 ? <>Ativo</> : <>Inativo</>)
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: `${props.t('action')}`,

      formatter: (_cellContent, farm) => (
        <ActionTable
          editPermissions={['edit_farms']}
          deletePermissions={['destroy_farms']}
          handleDelete={() => handleDeleteFarm(farm)}
          handleEdit={() => handleFarmClick(farm)}
        />
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setFarm(null);
    } else {
      setModal(true);
    }
  };

  const handleFarmClick = (arg) => {
    const farm = arg;

    setFarm({
      id: farm.id,
      name: farm.name,
      active: farm.active,
      quantity_land: farm.quantity_land,
      quantity_unit_land: farm.quantity_unit_land,
      legal_reservation_amount: farm.legal_reservation_amount,
      top_index: farm.top_index,
      lower_index: farm.lower_index
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteFarm = (data) => {
    if (data.id) {
      dispatch(onDeleteFarm(data));
      onPaginationPageChange(1);

      handleAsyncActions('Deleted');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Farm ${action} Successfully`, 'success');
    }
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setFarmList(
      farms.filter((farm) =>
        Object.keys(farm).some((key) => farm[key].toLowerCase().includes(searchText.toLowerCase()))
      )
    );
  };

  const handleFarmClicks = () => {
    setFarmList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  /** set Date formate */
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />

        <MetaTags>
          <title>Farm | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Farms')} breadcrumbItem={props.t('Farms')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={FarmColumns}
                    data={farms}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={farms || []}
                        columns={FarmColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <div>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => handleTableChange('search', e)}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_farms']}>
                                    <Button
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={handleFarmClicks}>
                                      <i className="mdi mdi-plus me-1" /> {props.t('New Farm')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>

                            <Row>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                      <SizePerPageDropdownStandalone {...paginationProps} />
                                    </Col>
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone {...paginationProps} />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? 'Edit Farm' : 'Add Farm'}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
              <Row form>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ''}
                      invalid={validation.touched.name && validation.errors.name ? true : false}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Quantity Land</Label>
                    <Input
                      name="quantity_land"
                      type="text"
                      className="form-control"
                      placeholder="Enter Quantity Land"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.quantity_land || ''}
                      invalid={
                        validation.touched.quantity_land && validation.errors.quantity_land
                          ? true
                          : false
                      }
                    />
                    {validation.touched.quantity_land && validation.errors.quantity_land ? (
                      <FormFeedback type="invalid">{validation.errors.quantity_land}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Quantity Unit Land</Label>
                    <Input
                      name="quantity_unit_land"
                      type="text"
                      className="form-control"
                      placeholder="Enter Quantity Unit Land"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.quantity_unit_land || ''}
                      invalid={
                        validation.touched.quantity_unit_land &&
                          validation.errors.quantity_unit_land
                          ? true
                          : false
                      }
                    />
                    {validation.touched.quantity_unit_land &&
                      validation.errors.quantity_unit_land ? (
                      <FormFeedback type="invalid">
                        {validation.errors.quantity_unit_land}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Legal Reservation Amount</Label>
                    <Input
                      name="legal_reservation_amount"
                      type="text"
                      className="form-control"
                      placeholder="Enter Legal Reservation Amount"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.legal_reservation_amount || ''}
                      invalid={
                        validation.touched.legal_reservation_amount &&
                          validation.errors.legal_reservation_amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.legal_reservation_amount &&
                      validation.errors.legal_reservation_amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.legal_reservation_amount}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Top Index</Label>
                    <Input
                      name="top_index"
                      type="text"
                      className="form-control"
                      placeholder="Enter Top Index"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.top_index || ''}
                      invalid={
                        validation.touched.top_index && validation.errors.top_index ? true : false
                      }
                    />
                    {validation.touched.top_index && validation.errors.top_index ? (
                      <FormFeedback type="invalid">{validation.errors.top_index}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Lower Index</Label>
                    <Input
                      name="lower_index"
                      type="text"
                      className="form-control"
                      placeholder="Enter Lower Index"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lower_index || ''}
                      invalid={
                        validation.touched.lower_index && validation.errors.lower_index
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lower_index && validation.errors.lower_index ? (
                      <FormFeedback type="invalid">{validation.errors.lower_index}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Input
                      name="active"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.active || '1'}
                      invalid={
                        validation.touched.active && validation.errors.active ? true : false
                      }>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </Input>
                    {validation.touched.active && validation.errors.active ? (
                      <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

Farms.propTypes = {
  farms: PropTypes.array,
  onGetFarms: PropTypes.func,
  onAddNewFarm: PropTypes.func,
  onUpdateFarm: PropTypes.func,
  onDeleteFarm: PropTypes.func
};

export default withTranslation()(Farms);
