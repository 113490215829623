import { useCallback, useEffect } from 'react';
import { useTenantStore } from '.';
import axios from 'axios';
import { isExistsModelByHostname } from './services/api';

export const useTenantByHostname = () => {
  const { setTenant } = useTenantStore();

  const tryTenantOnApi = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('host', window.btoa(window.location.host));

    if (!isExistsModelByHostname()) {
      return;
    }

    try {
      const tenantData = await axios.get(
        `${process.env.REACT_APP_SYSTEM_URL}/tenants/${window.location.host.split('.')[0]}`,
        params
      );

      sessionStorage.setItem('tenant_data', JSON.stringify(tenantData.data.data));

      if (tenantData) {
        setTenant(tenantData.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [axios, setTenant]);

  useEffect(() => {
    tryTenantOnApi();
  }, [tryTenantOnApi]);

  return useTenantStore;
};
