import React, { useEffect, useState, useRef, Button } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import { formatPrice, formatDateBr, formaIntBr } from '../../../utils/inputMask';
import pdfSvg from './Icons/pdf.svg';
import sheetSvg from './Icons/sheet.svg';
import Logo from '../../../assets/images/wshare.png';

import Message from 'components/Message';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import {
  getSyntheticGains as onGetSyntheticGains,
  setisLoadingGains
} from 'store/reports/gains/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import DroopSynthetic from './Filters/DroopFilterSynthetic/DroopSynthetic';
import { withTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { createWorkbook, createWorksheet } from 'lib/exceljs/Services';

const SyntheticGains = (props) => {
  const dispatch = useDispatch();

  const [itens, setItens] = useState([]);

  const { syntheticGains } = useSelector((state) => ({
    syntheticGains: state.ReportGains.syntheticGains
  }));

  const { syntheticFilterValues } = useSelector((state) => ({
    syntheticFilterValues: state.ReportGains.syntheticFilterValues
  }));

  const { isLoadingGains } = useSelector((state) => ({
    isLoadingGains: state.ReportGains.isLoadingGains
  }));

  const { syntheticFilteredGains } = useSelector((state) => ({
    syntheticFilteredGains: state.ReportGains.syntheticFilteredGains
  }));

  const { syntheticFiltered } = useSelector((state) => ({
    syntheticFiltered: state.ReportGains.syntheticFiltered
  }));

  const { error } = useSelector((state) => ({ error: state.ReportGains.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      // showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(setisLoadingGains(true));
    dispatch(onGetSyntheticGains({}));
  }, []);

  useEffect(() => {
    if (syntheticGains && syntheticGains.length > 0) {
      if (syntheticFiltered) {
        let syntheticFilteredGainsOperation = filter(syntheticFilterValues);
        if (!syntheticFilterValues.collect_id2 && !syntheticFilterValues.collect_id3) {
          syntheticFilteredGainsOperation = filterOperation(syntheticFilteredGainsOperation);
        }
        if (
          syntheticFilterValues.collect_id2 == 'Selecione' &&
          syntheticFilterValues.collect_id3 == 'Selecione'
        ) {
          syntheticFilteredGainsOperation = filterOperation(syntheticFilteredGainsOperation);
        }
        setItens(syntheticFilteredGainsOperation);
      } else {
        let syntheticGainsOperation = filterOperation(syntheticGains);
        setItens(syntheticGainsOperation);
      }
    }
  }, [syntheticGains, syntheticFiltered]);

  //pagination customization
  const pageOptions = {
    totalSize: itens.length, // replace later with size(orders),
    custom: true
  };

  const filterOperation = (gains) => {
    return gains.filter((row) => {
      return row.operation !== 'E' && row.operation !== 'TS' && row.operation !== 'TE'
        ? true
        : false;
    });
  };

  function compare(a, op, b) {
    switch (op) {
      case '>':
        if (b > a) {
          return true;
        } else {
          return false;
        }
      case '<':
        if (b < a) {
          return true;
        } else {
          return false;
        }
      case '<=':
        if (b <= a) {
          return true;
        } else {
          return false;
        }
      case '>=':
        if (b >= a) {
          return true;
        } else {
          return false;
        }
      default:
        false;
    }
  }

  const handleSaveAsXlsx = (row) => {
    const dataHoraAtual = row.start_date ? new Date(row.start_date) : undefined;
    const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
    const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
    const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, `Relatório Análise Sintética ${row.collect_name}`);
    worksheet.columns = [
      { header: 'Descrição', key: 'desc', width: 100 },
      { header: 'valor', key: 'value', width: 20 }
    ];

    const formatSyntheticXlsxReport = [
      {
        desc: 'Dias da medição total',
        value: formaIntBr(row.difference_days)
      },
      {
        desc: 'Meses da medição média',
        value: Math.round(parseFloat(formatPrice(row.avg_month)))
      },
      {
        desc: '',
        value: ''
      },
      {
        desc: 'Peso total em kg - final',
        value: formatPrice(row.final_weight)
      },
      {
        desc: 'Peso total em kg - inicial',
        value: formatPrice(row.start_weight)
      },
      {
        desc: 'Diferença peso total final menos peso total inicial',
        value: formatPrice(row.gains_kg)
      },
      {
        desc: '',
        value: ''
      },
      {
        desc: 'Quantidade de gados total',
        value: formaIntBr(row.qtd_total_animals)
      },
      {
        desc: 'Quantidade de gados com brinco',
        value: formaIntBr(row.qtd_total_animals_com_brinco)
      },
      {
        desc: 'Quantidade de gados sem brinco',
        value: formaIntBr(row.qtd_total_animals_sem_brinco)
      },
      {
        desc: '',
        value: ''
      },
      {
        desc: 'Peso total em @ - Final  com brinco',
        value: formatPrice(row.final_weight_arroba)
      },
      {
        desc: 'Peso total em @ - Inicial  com brinco',
        value: formatPrice(row.start_weight_arroba)
      },
      {
        desc: 'Ganho total peso final @ menos inicial @ com brinco',
        value: formatPrice(row.gains_arroba)
      },
      {
        desc: '',
        value: ''
      },
      {
        desc: 'Ganho médio por gado em @ por dia ',
        value: formatPrice(row.gains_day_arroba)
      },
      {
        desc: 'Ganho médio por gado em @ por mês',
        value: formatPrice(row.gains_month_arroba)
      },
      {
        desc: 'Ganho médio por gado em @ por ano',
        value: formatPrice(row.gains_year_arroba)
      }
    ];

    formatSyntheticXlsxReport.map((item) =>
      worksheet.addRow({
        desc: item.desc,
        value: item.value
      })
    );
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `synthetic-${row.collect_name}-${ano}-${mes}-${dia}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleSaveAsPDF = (row) => {
    const doc = new jsPDF();
    const dataHoraAtual = row.start_date ? new Date(row.start_date) : undefined;
    const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
    const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
    const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';
    const dataHoraAtualEnd = row.start_date ? new Date(row.final_date) : undefined;
    const diaEnd = dataHoraAtualEnd ? String(dataHoraAtualEnd.getDate()).padStart(2, '0') : '00';
    const mesEnd = dataHoraAtualEnd
      ? String(dataHoraAtualEnd.getMonth() + 1).padStart(2, '0')
      : '00';
    const anoEnd = dataHoraAtualEnd ? dataHoraAtualEnd.getFullYear() : '00';
    const logoImg = new Image();
    logoImg.onload = function () {
      const width = 40; // Largura desejada em pixels
      const aspectRatio = logoImg.height / logoImg.width;
      const height = width * aspectRatio;

      doc.addImage(logoImg, 'PNG', 10, 10, width, height);

      // Adiciona a data com espaçamento
      const currentDate = formatDateBr(row.start_date);
      const currentDateEnd = formatDateBr(row.final_date);
      doc.setFontSize(13); // Define o tamanho da fonte para 13px
      // doc.text(`Data Inicial: ${currentDate}`, 134, 20);
      doc.text(`Data: ${currentDateEnd}`, 134, 26);

      // Adiciona o título com espaçamento
      doc.text('Relatório Análise Sintética', 105, 37, null, null, 'center');
      if (syntheticFiltered) {
        doc.text(
          `${row.collect2_name} ${syntheticFiltered ? '- ' + row.collect1_name : ''}`,
          105,
          43,
          null,
          null,
          'center'
        );
      } else {
        doc.text(
          `${row.collect1_name}`,
          105,
          43,
          null,
          null,
          'center'
        );
      }

      const formatSyntheticPdfReport = [
        {
          desc: 'Dias da medição total',
          value: formaIntBr(row.difference_days)
        },
        {
          desc: 'Meses da medição média',
          value: Math.round(parseFloat(formatPrice(row.avg_month)))
        },
        {
          desc: '',
          value: ''
        },
        {
          desc: 'Peso total em kg - final',
          value: formatPrice(row.final_weight)
        },
        {
          desc: 'Peso total em kg - inicial',
          value: formatPrice(row.start_weight)
        },
        {
          desc: 'Diferença peso total final menos peso total inicial',
          value: formatPrice(row.gains_kg)
        },
        {
          desc: '',
          value: ''
        },
        {
          desc: 'Quantidade de gados total',
          value: formaIntBr(row.qtd_total_animals)
        },
        {
          desc: 'Quantidade de gados com brinco',
          value: formaIntBr(row.qtd_total_animals_com_brinco)
        },
        {
          desc: 'Quantidade de gados sem brinco',
          value: formaIntBr(row.qtd_total_animals_sem_brinco)
        },
        {
          desc: '',
          value: ''
        },
        {
          desc: 'Peso total em @ - Final  com brinco',
          value: formatPrice(row.final_weight_arroba)
        },
        {
          desc: 'Peso total em @ - Inicial  com brinco',
          value: formatPrice(row.start_weight_arroba)
        },
        {
          desc: 'Ganho total peso final @ menos inicial @ com brinco',
          value: formatPrice(row.gains_arroba)
        },
        {
          desc: '',
          value: ''
        },
        {
          desc: 'Ganho médio por gado em @ por dia ',
          value: formatPrice(row.gains_day_arroba)
        },
        {
          desc: 'Ganho médio por gado em @ por mês',
          value: formatPrice(row.gains_month_arroba)
        },
        {
          desc: 'Ganho médio por gado em @ por ano',
          value: formatPrice(row.gains_year_arroba)
        }
      ];

      // Adiciona a tabela
      doc.autoTable({
        head: [['Descrição ', 'Valor']],
        body: formatSyntheticPdfReport.map((item) => [item.desc, item.value]),
        startY: 50 // Posição inicial da tabela
      });
      doc.setProperties({
        title: `synthetic-${ano}-${mes}-${dia}-${row.collect_name}.pdf`
      });
      doc.output('dataurlnewwindow');
      //  doc.save(`synthetic-${ano}-${mes}-${dia}-${row.collect_name}.pdf`);
    };
    logoImg.src = Logo;
  };

  const filter = (values) => {
    console.log('te', syntheticGains);

    if (syntheticGains.length > 0) {
      let syntheticGainsFilteredFilter = syntheticGains;
      if (values.collect_id) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter(
          (filter) => filter.collect_id == parseInt(values.collect_id)
        );
      }

      if (values.difference_daysQuantity !== null && values.difference_daysQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.difference_daysQuantity),
            values.difference_days,
            parseInt(filter.difference_days)
          )
        );
      }

      if (values.final_weightQuantity !== null && values.final_weightQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.final_weightQuantity),
            values.final_weight,
            parseInt(filter.final_weight)
          )
        );
      }
      if (
        values.final_weight_arrobaQuantity !== null &&
        values.final_weight_arrobaQuantity !== ''
      ) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.final_weight_arrobaQuantity),
            values.final_weight_arroba,
            parseInt(filter.final_weight_arroba)
          )
        );
      }
      if (values.gains_day_arrobaQuantity !== null && values.gains_day_arrobaQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.gains_day_arrobaQuantity),
            values.gains_day_arroba,
            parseInt(filter.gains_day_arroba)
          )
        );
      }
      if (values.gains_kgQuantity !== null && values.gains_kgQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(parseInt(values.gains_kgQuantity), values.gains_kg, parseInt(filter.gains_kg))
        );
      }
      if (values.gains_month_arrobaQuantity !== null && values.gains_month_arrobaQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.gains_month_arrobaQuantity),
            values.gains_month_arroba,
            parseInt(filter.gains_month_arroba)
          )
        );
      }

      if (values.gains_year_arrobaQuantity !== null && values.gains_year_arrobaQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.gains_year_arrobaQuantity),
            values.gains_year_arroba,
            parseInt(filter.gains_year_arroba)
          )
        );
      }

      if (values.operation) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          filter.operation.toLowerCase().includes(values.operation.toLowerCase())
        );
      }

      if (
        values.qtd_total_animals_com_brincoQuantity !== null &&
        values.qtd_total_animals_com_brincoQuantity !== ''
      ) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.qtd_total_animals_com_brincoQuantity),
            values.qtd_total_animals_com_brinco,
            parseInt(filter.qtd_total_animals_com_brinco)
          )
        );
      }

      if (
        values.qtd_total_animals_sem_brincoQuantity !== null &&
        values.qtd_total_animals_sem_brincoQuantity !== ''
      ) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.qtd_total_animals_sem_brincoQuantity),
            values.qtd_total_animals_sem_brinco,
            parseInt(filter.qtd_total_animals_sem_brinco)
          )
        );
      }

      if (values.start_weightQuantity !== null && values.start_weightQuantity !== '') {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.start_weightQuantity),
            values.start_weight,
            parseInt(filter.start_weight)
          )
        );
      }

      if (
        values.start_weight_arrobaQuantity !== null &&
        values.start_weight_arrobaQuantity !== ''
      ) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.start_weight_arrobaQuantity),
            values.start_weight_arroba,
            parseInt(filter.start_weight_arroba)
          )
        );
      }

      if (
        values.start_weight_arrobaQuantity !== null &&
        values.start_weight_arrobaQuantity !== ''
      ) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter((filter) =>
          compare(
            parseInt(values.start_weight_arrobaQuantity),
            values.start_weight_arroba,
            parseInt(filter.start_weight_arroba)
          )
        );
      }

      if (values.de && values.ate) {
        syntheticGainsFilteredFilter = syntheticGainsFilteredFilter.filter(
          (filter) => filter.final_date > values.de && filter.final_date < values.ate
        );
      }
      return syntheticGainsFilteredFilter;
    }
  };

  const SyntheticGainColumns = [
    {
      dataField: 'collect_id',
      text: 'id',
      sort: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.collect_id}</>
    },
    {
      dataField: 'collect_name',
      text: `${props.t('Collect Name')}`,
      //   sort: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.collect_name}</>
    },

    {
      dataField: 'difference_days',
      text: `${props.t('Total measurement days')}`, //'Dias da medição total'
      //   sort: true
      formatter: (cellContent, row) => <>{formaIntBr(row.difference_days)}</>
    },
    {
      dataField: 'start_date',
      text: `${props.t('Start date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.start_date)}</>
    },
    {
      dataField: 'final_date',
      text: `${props.t('Final date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.final_date)}</>
    },

    {
      dataField: 'avg_month',
      text: `${props.t('Average measurement months')}`, //'Meses da medição média',
      // sort: true
      formatter: (cellContent, row) => <>{Math.round(parseFloat(formatPrice(row.avg_month)))}</>
    },
    {
      dataField: 'final_weight',
      text: `${props.t('Total weight in kg - final')}`, // 'Peso total em kg - final',
      // sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.final_weight)}</>
    },
    {
      dataField: 'start_weight',
      text: `${props.t('Total weight in kg - initial')}`, // 'Peso total em kg - inicial',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.start_weight)}</>
    },
    {
      dataField: 'gains_kg',
      text: `${props.t('Difference final total weight minus initial total weight')}`, // 'Diferença peso total final menos peso total inicial',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.gains_kg)}</>
    },

    {
      dataField: 'qtd_total_animals',
      text: `${props.t('Total number of cattle')}`, //'Quantidade de gados total',

      //  sort: true
      formatter: (cellContent, row) => <>{row.qtd_total_animals}</> // alterar para intMask
    },
    {
      dataField: 'qtd_total_animals_com_brinco',
      text: `${props.t('Number of cattle with earring')}`, // 'Quantidade de gados com brinco',
      //   sort: true
      formatter: (cellContent, row) => <>{row.qtd_total_animals_com_brinco}</>
    },
    {
      dataField: 'qtd_total_animals_sem_brinco',
      text: `${props.t('Number of cattle without earring')}`, // 'Quantidade de gados sem brinco',
      //   sort: true
      formatter: (cellContent, row) => <>{row.qtd_total_animals_sem_brinco}</>
    },
    {
      dataField: 'final_weight_arroba',
      text: `${props.t('Total weight in @ - End with earring')}`, // 'Peso total em @ - Final com brinco',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.final_weight_arroba)}</>
    },
    {
      dataField: 'start_weight_arroba',
      text: `${props.t('Total weight in @ - Initial with earring')}`, // 'Peso total em @ - Inicial com brinco',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.start_weight_arroba)}</>
    },
    {
      dataField: 'gains_arroba',
      text: `${props.t('Total weight gain final @ minus initial @ with earring')}`, // 'Ganho total peso final @ menos inicial @ com brinco',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.gains_arroba)}</>
    },
    {
      dataField: 'gains_day_arroba',
      text: `${props.t('Average earnings per livestock in @ per day')}`, // 'Ganho médio por gado em @ por dia',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.gains_day_arroba)}</>
    },
    {
      dataField: 'gains_month_arroba',
      text: `${props.t('Average earnings per livestock in @ per month')}`, // 'Ganho médio por gado em @ por mês',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.gains_month_arroba)}</>
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Average earnings per livestock in @ per year')}`, // 'Ganho médio por gado em @ por ano',
      //   sort: true
      formatter: (cellContent, row) => <>{formatPrice(row.gains_year_arroba)}</>
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Actions')}`, // 'Ganho médio por gado em @ por ano',
      headerStyle: (colum, colIndex) => {
        return { textAlign: 'center' };
      },
      //   sort: true
      formatter: (cellContent, row) => (
        <div style={{ width: '90px' }}>
          <Row>
            <Col>
              <img
                src={pdfSvg}
                style={{ cursor: 'pointer' }}
                width={'32px'}
                height={'32px'}
                onClick={() => handleSaveAsPDF(row)}></img>
            </Col>
            <Col className="">
              <img
                src={sheetSvg}
                style={{ cursor: 'pointer' }}
                width={'32px'}
                height={'32px'}
                onClick={() => handleSaveAsXlsx(row)}></img>
            </Col>
          </Row>
        </div>
      )
    }
  ];

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setSyntheticGainList(
      itens.filter((syntheticGain) =>
        Object.keys(syntheticGain).some((key) =>
          syntheticGain[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const defaultSorted = [
    {
      dataField: 'collect_id',
      order: 'desc'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SyntheticGain | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Report')} breadcrumbItem={props.t('SyntheticGains')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {isLoadingGains == true ? (
                    <Loading isLoading={isLoadingGains}></Loading>
                  ) : (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={SyntheticGainColumns}
                      data={itens}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={itens || []}
                          columns={SyntheticGainColumns}
                          bootstrap4
                          search>
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <DroopSynthetic />
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive mb-0"
                                      data-pattern="priority-columns">
                                      <Table id="itens-1">
                                        <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={'table table-striped table-bordered'}
                                          keyField="id"
                                          {...toolkitProps.baseProps}
                                          onTableChange={handleTableChange}
                                          {...paginationTableProps}
                                          ref={node}
                                        />
                                      </Table>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                  <SizePerPageDropdownStandalone
                                    onPageChange={(value) => console.log(value)}
                                    {...paginationProps}
                                  />
                                </Col>
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SyntheticGains.propTypes = {
  syntheticGains: PropTypes.array,
  onGetSyntheticGains: PropTypes.func
};

export default withTranslation()(SyntheticGains);
