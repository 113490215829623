/* ANIMALS */
export const GET_ANIMALS = "GET_ANIMALS"
export const GET_ANIMALS_SUCCESS = "GET_ANIMALS_SUCCESS"
export const GET_ANIMALS_FAIL = "GET_ANIMALS_FAIL"

/**
 * Edit ANIMAL
 */
export const UPDATE_ANIMAL = "UPDATE_ANIMAL"
export const UPDATE_ANIMAL_SUCCESS = "UPDATE_ANIMAL_SUCCESS"
export const UPDATE_ANIMAL_FAIL = "UPDATE_ANIMAL_FAIL"



