import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Button,
  Input
} from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// users
import user1 from '../../../../src/assets/UserPerfilescuro.png';

// flag icons
import usFlag from '../../../assets/images/flags/en.png';
import ptFlag from '../../../assets/images/flags/pt.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useProfileStore } from 'zustand/profile/profile-store';

const ProfileMenu = (props) => {
  const { user: authUser, get: getUser, setOpenModal } = useProfileStore();
  const [menu, setMenu] = useState(false);
  const [langDropdown, setLangDropdown] = useState(false);
  const [username, setUsername] = useState('Admin');
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [editingUsername, setEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState('');

  const tryProfileStore = useCallback(async () => {
    await getUser();
  }, []);

  useEffect(() => {
    tryProfileStore();
  }, [tryProfileStore]);

  const history = useHistory();
  const toggleProfileCard = () => {
    setOpenModal(true);
    history.push(`/users?edit=profile`);
  };

  const toggleEditUsername = () => {
    setEditingUsername(!editingUsername);
    // Reset new username when editing is toggled off
    if (!editingUsername) {
      setNewUsername('');
    }
  };

  const handleUsernameChange = (event) => {
    setNewUsername(event.target.value);
  };

  const handleSaveUsername = () => {
    // Perform save username logic here
    toggleEditUsername();
  };

  const changeLanguage = (lang) => {
    props.i18n.changeLanguage(lang);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        {/* Language Dropdown */}
        <Dropdown
          isOpen={langDropdown}
          toggle={() => setLangDropdown(!langDropdown)}
          className="d-inline-block ms-2">
          <DropdownToggle className="btn header-item" tag="button">
            {props.i18n.language === 'en' ? (
              <img src={usFlag} alt="US Flag" />
            ) : (
              <img src={ptFlag} alt="PT Flag" />
            )}
            <i style={{ paddingLeft: '15px' }} className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => props.i18n.changeLanguage('en')}>
              <img src={usFlag} alt="US Flag" /> EN
            </DropdownItem>
            <DropdownItem onClick={() => props.i18n.changeLanguage('pt')}>
              <img src={ptFlag} alt="PT Flag" /> PT
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="rounded-circle header-profile-user">
              <img style={{ width: 30 }} src={user1} alt="Header Avatar" />
            </div>
            <span className="d-none d-xl-inline-block ms-2 me-1">{authUser?.user.name}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={toggleProfileCard}>
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t('Profile')}{' '}
          </DropdownItem>
          <Link to="/assinatura">
            <DropdownItem tag="a">
              {' '}
              <i className="bx bx-card font-size-16 align-middle me-1" />
              {props.t('Subscript')}{' '}
            </DropdownItem>
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Card
        className="w-50"
        style={{ position: 'absolute', marginRop: '50%', left: '25%' }}
        hidden={!showProfileCard}>
        <CardBody>
          <h5 className="card-title">User Profile</h5>
          <p className="card-text">User Details Here</p>
          {editingUsername ? (
            <Input
              type="text"
              value={newUsername}
              onChange={handleUsernameChange}
              placeholder="New Username"
            />
          ) : (
            <p>Username: {username}</p>
          )}
          <Button onClick={editingUsername ? handleSaveUsername : toggleEditUsername}>
            {editingUsername ? 'Save' : 'Edit Username'}
          </Button>
          <Button onClick={toggleProfileCard}>Close</Button>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
ProfileMenu.displayName = 'Eduardo';

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  i18n: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
