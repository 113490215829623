/* MOVEMENT_TYPES */
export const GET_MOVEMENT_TYPES = "GET_MOVEMENT_TYPES"
export const GET_MOVEMENT_TYPES_SUCCESS = "GET_MOVEMENT_TYPES_SUCCESS"
export const GET_MOVEMENT_TYPES_FAIL = "GET_MOVEMENT_TYPES_FAIL"

/**
 * add MOVEMENT_TYPE
 */
export const ADD_NEW_MOVEMENT_TYPE = "ADD_NEW_MOVEMENT_TYPE"
export const ADD_MOVEMENT_TYPE_SUCCESS = "ADD_MOVEMENT_TYPE_SUCCESS"
export const ADD_MOVEMENT_TYPE_FAIL = "ADD_MOVEMENT_TYPE_FAIL"

/**
 * Edit MOVEMENT_TYPE
 */
export const UPDATE_MOVEMENT_TYPE = "UPDATE_MOVEMENT_TYPE"
export const UPDATE_MOVEMENT_TYPE_SUCCESS = "UPDATE_MOVEMENT_TYPE_SUCCESS"
export const UPDATE_MOVEMENT_TYPE_FAIL = "UPDATE_MOVEMENT_TYPE_FAIL"

/**
 * Delete MOVEMENT_TYPE
 */
export const DELETE_MOVEMENT_TYPE = "DELETE_MOVEMENT_TYPE"
export const DELETE_MOVEMENT_TYPE_SUCCESS = "DELETE_MOVEMENT_TYPE_SUCCESS"
export const DELETE_MOVEMENT_TYPE_FAIL = "DELETE_MOVEMENT_TYPE_FAIL"

