import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_PROFILE_PERMISSIONS,
  GET_PROFILE_PERMISSIONS_AVAILABLES,
  ADD_PROFILE_PERMISSION,
  DELETE_PROFILE_PERMISSION,
} from "./actionTypes";

import {
  getProfilePermissionsSuccess,
  getProfilePermissionsFail,

  getProfilePermissionsAvailablesSuccess,
  getProfilePermissionsAvailablesFail,

  addNewProfilePermissionSuccess,
  addNewProfilePermissionFail,

  deleteProfilePermissionSuccess,
  deleteProfilePermissionFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProfilePermissions,
  getProfilePermissionsAvailable,
  addNewProfilePermission,
  deleteProfilePermission,
} from "helpers/backend_fazenda_helper";

function* onGetProfilePermissions({profileId}) {
  try {
    const response = yield call(getProfilePermissions, profileId);
    yield put(getProfilePermissionsSuccess(response.data));
  } catch (error) {
    yield put(getProfilePermissionsFail(error));
  }
}

function* onGetProfilePermissionsAvailable({profileId}) {
  try {
    const response = yield call(getProfilePermissionsAvailable, profileId);
    yield put(getProfilePermissionsAvailablesSuccess(response.data));
  } catch (error) {
    yield put(getProfilePermissionsAvailablesFail(error));
  }
}

function* onDeleteProfilePermission({profileId, permissionId, payload}) {
  try {
    const response = yield call(deleteProfilePermission, profileId, permissionId, payload);
    yield put(deleteProfilePermissionSuccess(response.data));
  } catch (error) {
    yield put(deleteProfilePermissionFail(error));
  }
}

function* onAddNewProfilePermission({profileId, payload}) {
  try {
    const response = yield call(addNewProfilePermission, profileId, payload);
    yield put(addNewProfilePermissionSuccess(response.data));
  } catch (error) {
    yield put(addNewProfilePermissionFail(error));
  }
}

function* ProfilePermissionsSaga() {
  yield takeEvery(GET_PROFILE_PERMISSIONS, onGetProfilePermissions);
  yield takeEvery(GET_PROFILE_PERMISSIONS_AVAILABLES, onGetProfilePermissionsAvailable);
  yield takeEvery(ADD_PROFILE_PERMISSION, onAddNewProfilePermission);
  yield takeEvery(DELETE_PROFILE_PERMISSION, onDeleteProfilePermission);
}

export default ProfilePermissionsSaga;
