import {
  GET_PLANS,
  GET_PLANS_FAIL,
  GET_PLANS_SUCCESS,
  ADD_NEW_PLAN,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAIL,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL,
  ATTACH_PLAN_PROFILE,
  ATTACH_PLAN_SUCCESS,
  ATTACH_PLAN_FAIL,

} from "./actionTypes"

export const getPlans = () => ({
  type: GET_PLANS,
})

export const getPlanByProfile = (id) => ({
    type: GET_PLANS,
  })

export const getPlansSuccess = plans => ({
  type: GET_PLANS_SUCCESS,
  payload: plans,
})

export const getPlansFail = error => ({
  type: GET_PLANS_FAIL,
  payload: error,
})

export const addNewPlan = plan => ({
  type: ADD_NEW_PLAN,
  payload: plan,
})

export const addPlanSuccess = plan => ({
  type: ADD_PLAN_SUCCESS,
  payload: plan,
})

export const addPlanFail = error => ({
  type: ADD_PLAN_FAIL,
  payload: error,
})

export const updatePlan = plan => ({
  type: UPDATE_PLAN,
  payload: plan,
})

export const updatePlanSuccess = plan => ({
  type: UPDATE_PLAN_SUCCESS,
  payload: plan,
})

export const updatePlanFail = error => ({
  type: UPDATE_PLAN_FAIL,
  payload: error,
})

export const attachPlanProfile = (planId,profiles) => ({
    type: ATTACH_PLAN_PROFILE,
    planId : planId,
    payload: profiles
  })

  export const attachPlanProfileSuccess = plan => ({
    type: ATTACH_PLAN_SUCCESS,
    payload: plan,
  })

  export const attachPlanProfileFail = error => ({
    type: ATTACH_PLAN_FAIL,
    payload: error,
  })


export const deletePlan = plan => ({
  type: DELETE_PLAN,
  payload: plan,
})

export const deletePlanSuccess = plan => ({
  type: DELETE_PLAN_SUCCESS,
  payload: plan,
})

export const deletePlanFail = error => ({
  type: DELETE_PLAN_FAIL,
  payload: error,
})

