import {
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAIL,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  profiles: [],
  error: {},
};

const Profile = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
      };

    case GET_PROFILES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: [...state.profiles, action.payload],
      };

    case ADD_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.map(profile =>
          profile.id.toString() === action.payload.id.toString()
            ? { profile, ...action.payload }
            : profile
        ),
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.filter(
          profile => profile.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Profile;
