import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Table,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from 'reactstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CardsComponent = ({
  cardList,
  handleEdit,
  handleDelete,
  handleSetDefault,
  defaultCardIndex,
  t,
  updateCardList
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [showManualAddressInputs, setShowManualAddressInputs] = useState(false);
  const [newCard, setNewCard] = useState({
    cardNumber: '',
    expirationDate: '',
    cardHolderName: '',
    address: '',
    manualAddress: false,
    postalCode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    complement: ''
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleManualAddressInputs = () => {
    setShowManualAddressInputs(!showManualAddressInputs);
  };

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string().required('Número do cartão é obrigatório'),
    expirationDate: Yup.string().required('Data de validade é obrigatória'),
    securityCode: Yup.string().required('Codigo de sergurança é obrigatória'),
    cardHolderName: Yup.string().required('Nome do portador é obrigatório'),
    postalCode: Yup.string(), //.required(t('CEP é obrigatório')),
    street: Yup.string(), //.required(t('Rua é obrigatória')),
    number: Yup.string(), //.required(t('Número é obrigatório')),
    neighborhood: Yup.string(), //.required(t('Bairro é obrigatório')),
    city: Yup.string(), //.required(t('Cidade é obrigatória')),
    state: Yup.string(), //.required(t('Estado é obrigatório')),
    complement: Yup.string()
  });

  const handleSaveCard = (values) => {
    let updatedCardList;
    let newDefaultIndex;
    if (isEditing && editIndex !== null) {
      updatedCardList = cardList.map((card, index) => (index === editIndex ? values : card));
      newDefaultIndex = editIndex;
    } else {
      updatedCardList = Array.isArray(cardList) ? [...cardList, values] : [values];
      newDefaultIndex = updatedCardList.length - 1;
    }

    updateCardList(updatedCardList, newDefaultIndex);
    setNewCard({
      cardNumber: '',
      expirationDate: '',
      cardHolderName: '',
      securityCode: '',
      address: '',
      manualAddress: false,
      postalCode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      complement: ''
    });
    setIsEditing(false);
    setEditIndex(null);
    toggleModal();
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setIsEditing(true);
    setNewCard(cardList[index]);
    toggleModal();
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <div>
              <h5 className="text-muted d-flex justify-content-between">{t('Card')}</h5>
            </div>
            <Col md={12} className="d-flex justify-content-end">
              <FormGroup>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    setNewCard({
                      cardNumber: '',
                      expirationDate: '',
                      securityCode: '',
                      cardHolderName: '',
                      manualAddress: false,
                      postalCode: '',
                      street: '',
                      number: '',
                      neighborhood: '',
                      city: '',
                      state: '',
                      complement: ''
                    });
                    setIsEditing(false);
                    toggleModal();
                  }}>
                  {t('Register card')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Col>
            <div className="table-responsive mt-4">
              <Table className="table-light table-bordered" style={{ marginBottom: 40 }}>
                <thead>
                  <tr>
                    <th>{t('Card number')}</th>
                    <th>{t('Expiration date')}</th>
                    <th>{t('Nome do portador')}</th>
                    <th>{t('Address')}</th>
                    <th>{t('Standard')}</th>
                    <th>{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {cardList &&
                    cardList.map((card, index) => (
                      <tr key={index}>
                        <td>{card.cardNumber}</td>
                        <td>{card.expirationDate}</td>
                        <td>{card.cardHolderName}</td>
                        <td>
                          {card.city}, {card.neighborhood}, {card.number}
                        </td>
                        <td onClick={() => handleSetDefault(index)}>
                          {index === defaultCardIndex ? (
                            <FaCheckCircle color="green" size={15} />
                          ) : (
                            <FaTimesCircle color="red" size={15} />
                          )}
                        </td>
                        <td>
                          <div>
                            <MdOutlineEdit
                              size={20}
                              style={{ marginRight: 9, color: '#0f52a4', cursor: 'pointer' }}
                              onClick={() => handleEditClick(index)}
                            />
                            <RiDeleteBin2Fill
                              size={20}
                              style={{ color: '#db0b0d', cursor: 'pointer' }}
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader>{isEditing ? t('Edit card') : t('Register card')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={newCard}
            validationSchema={validationSchema}
            onSubmit={handleSaveCard}>
            <Form>
              <FormGroup>
                <Label for="cardNumber">{t('Card number')}</Label>
                <Field type="text" id="cardNumber" name="cardNumber" as={Input} />
                <ErrorMessage name="cardNumber" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <Label for="expirationDate">{t('Expiration date')}</Label>
                <Field type="month" id="expirationDate" name="expirationDate" as={Input} />
                <ErrorMessage name="expirationDate" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <Label for="securityCode">{t('Seecuritycode')}</Label>
                <Field type="number" id="securityCode" name="securityCode" as={Input} />
                <ErrorMessage name="securityCode" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <Label for="cardHolderName">{t('Nome do portador')}</Label>
                <Field type="text" id="cardHolderName" name="cardHolderName" as={Input} />
                <ErrorMessage name="cardHolderName" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <select
                  className="form-control"
                  id="selectAddress"
                  placeholder="Selecione um endereço"
                  disabled={showManualAddressInputs}
                  value={newCard.address}
                  onChange={(e) => setNewCard({ ...newCard, address: e.target.value })}>
                  <option value="selecioner" hidden>
                    Selecione um endereço
                  </option>
                </select>
              </FormGroup>

              <div className="form-check form-check-inline">
                <Label check>
                  <input
                    type="checkbox"
                    name="  "
                    onClick={(e) => {
                      setNewCard({ ...newCard, manualAddress: e.target.checked });
                      toggleManualAddressInputs();
                    }}
                  />{' '}
                  {t('Register manually')}
                </Label>
              </div>
              {showManualAddressInputs && (
                <>
                  <FormGroup>
                    <Label for="postalCode">{t('Zip code')}</Label>
                    <Field type="text" id="postalCode" name="postalCode" as={Input} />
                    <ErrorMessage name="postalCode" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="street">{t('Road')}</Label>
                    <Field type="text" id="street" name="street" as={Input} />
                    <ErrorMessage name="street" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="number">{t('Number')}</Label>
                    <Field type="text" id="number" name="number" as={Input} />
                    <ErrorMessage name="number" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="neighborhood">{t('Neighborhood')}</Label>
                    <Field type="text" id="neighborhood" name="neighborhood" as={Input} />
                    <ErrorMessage name="neighborhood" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="city">{t('City')}</Label>
                    <Field type="text" id="city" name="city" as={Input} />
                    <ErrorMessage name="city" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="state">{t('State')}</Label>
                    <Field type="text" id="state" name="state" as={Input} />
                    <ErrorMessage name="state" component="div" className="text-danger" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="complement">{t('Complement')}</Label>
                    <Field type="text" id="complement" name="complement" as={Input} />
                    <ErrorMessage name="complement" component="div" className="text-danger" />
                  </FormGroup>
                </>
              )}
              <ModalFooter>
                <Button type="submit" color="primary">
                  {t('Save')}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  {t('Cancel')}
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(CardsComponent);
