import {

  GET_MOVEMENT_TYPES,
  GET_MOVEMENT_TYPES_FAIL,
  GET_MOVEMENT_TYPES_SUCCESS,
  ADD_NEW_MOVEMENT_TYPE,
  ADD_MOVEMENT_TYPE_SUCCESS,
  ADD_MOVEMENT_TYPE_FAIL,
  UPDATE_MOVEMENT_TYPE,
  UPDATE_MOVEMENT_TYPE_SUCCESS,
  UPDATE_MOVEMENT_TYPE_FAIL,
  DELETE_MOVEMENT_TYPE,
  DELETE_MOVEMENT_TYPE_SUCCESS,
  DELETE_MOVEMENT_TYPE_FAIL,

} from "./actionTypes"

export const getMovementTypes = () => ({
  type: GET_MOVEMENT_TYPES,
})

export const getMovementTypesSuccess = movementTypes => ({
  type: GET_MOVEMENT_TYPES_SUCCESS,
  payload: movementTypes,
})

export const getMovementTypesFail = error => ({
  type: GET_MOVEMENT_TYPES_FAIL,
  payload: error,
})

export const addNewMovementType = movementType => ({
  type: ADD_NEW_MOVEMENT_TYPE,
  payload: movementType,
})

export const addMovementTypeSuccess = movementType => ({
  type: ADD_MOVEMENT_TYPE_SUCCESS,
  payload: movementType,
})

export const addMovementTypeFail = error => ({
  type: ADD_MOVEMENT_TYPE_FAIL,
  payload: error,
})

export const updateMovementType = movementType => ({
  type: UPDATE_MOVEMENT_TYPE,
  payload: movementType,
})

export const updateMovementTypeSuccess = movementType => ({
  type: UPDATE_MOVEMENT_TYPE_SUCCESS,
  payload: movementType,
})

export const updateMovementTypeFail = error => ({
  type: UPDATE_MOVEMENT_TYPE_FAIL,
  payload: error,
})

export const deleteMovementType = movementType => ({
  type: DELETE_MOVEMENT_TYPE,
  payload: movementType,
})

export const deleteMovementTypeSuccess = movementType => ({
  type: DELETE_MOVEMENT_TYPE_SUCCESS,
  payload: movementType,
})

export const deleteMovementTypeFail = error => ({
  type: DELETE_MOVEMENT_TYPE_FAIL,
  payload: error,
})

