import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postFakeRegister, postJwtRegister } from '../../../helpers/backend_fazenda_helper';
import axiosApi from '../../../helpers/api_helper'

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtRegister, user);

   const authUser = {
     token: response.token,
     user: {
       email: user.email,
       password: user.password
     }
   };

    localStorage.setItem('authUser', JSON.stringify(authUser));
    axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.token;
    yield put(registerUserSuccessful(response));

    history.push('/dashboard');
  } catch (error) {
    let errors = '';

    if (error.response) {
      errors = error.response.data.message;
      console.log('error register: ', error.response);
    } else {
      console.log('error register: ', error);
      errors = error;
    }
    yield put(registerUserFailed(errors));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

// function* accountSaga() {
//   yield takeEvery(REGISTER_USER, registerUser)
// }

export default accountSaga;
