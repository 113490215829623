/* TENANTS */
export const GET_TENANTS = "GET_TENANTS"
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS"
export const GET_TENANTS_FAIL = "GET_TENANTS_FAIL"

/**
 * add TENANT
 */
export const ADD_NEW_TENANT = "ADD_NEW_TENANT"
export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS"
export const ADD_TENANT_FAIL = "ADD_TENANT_FAIL"

/**
 * Edit TENANT
 */
export const UPDATE_TENANT = "UPDATE_TENANT"
export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS"
export const UPDATE_TENANT_FAIL = "UPDATE_TENANT_FAIL"

/**
 * Delete TENANT
 */
export const DELETE_TENANT = "DELETE_TENANT"
export const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS"
export const DELETE_TENANT_FAIL = "DELETE_TENANT_FAIL"

