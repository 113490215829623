/* get all PERMISSIONS */
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS"
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS"
export const GET_ALL_PERMISSIONS_FAIL = "GET_ALL_PERMISSIONS_FAIL"

export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS"
export const GET_ROLE_PERMISSIONS_SUCCESS = "GET_ROLE_PERMISSIONS_SUCCESS"
export const GET_ROLE_PERMISSIONS_FAIL = "GET_ROLE_PERMISSIONS_FAIL"

export const GET_ROLE_PERMISSIONS_AVAILABLES = "GET_ROLE_PERMISSIONS_AVAILABLES"
export const GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS = "GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS"
export const GET_ROLE_PERMISSIONS_AVAILABLES_FAIL = "GET_ROLE_PERMISSIONS_AVAILABLES_FAIL"

export const ADD_ROLE_PERMISSION = "ADD_ROLE_PERMISSION"
export const ADD_ROLE_PERMISSION_SUCCESS = "ADD_ROLE_PERMISSION_SUCCESS"
export const ADD_ROLE_PERMISSION_FAIL = "ADD_ROLE_PERMISSION_FAIL"

export const DELETE_ROLE_PERMISSION = "DELETE_ROLE_PERMISSION"
export const DELETE_ROLE_PERMISSION_SUCCESS = "DELETE_ROLE_PERMISSION_SUCCESS"
export const DELETE_ROLE_PERMISSION_FAIL = "DELETE_ROLE_PERMISSION_FAIL"

