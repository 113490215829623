/* get all PERMISSIONS */
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS"
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS"
export const GET_ALL_PERMISSIONS_FAIL = "GET_ALL_PERMISSIONS_FAIL"

export const GET_PROFILE_PERMISSIONS = "GET_PROFILE_PERMISSIONS"
export const GET_PROFILE_PERMISSIONS_SUCCESS = "GET_PROFILE_PERMISSIONS_SUCCESS"
export const GET_PROFILE_PERMISSIONS_FAIL = "GET_PROFILE_PERMISSIONS_FAIL"

export const GET_PROFILE_PERMISSIONS_AVAILABLES = "GET_PROFILE_PERMISSIONS_AVAILABLES"
export const GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS = "GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS"
export const GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL = "GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL"

export const ADD_PROFILE_PERMISSION = "ADD_PROFILE_PERMISSION"
export const ADD_PROFILE_PERMISSION_SUCCESS = "ADD_PROFILE_PERMISSION_SUCCESS"
export const ADD_PROFILE_PERMISSION_FAIL = "ADD_PROFILE_PERMISSION_FAIL"

export const DELETE_PROFILE_PERMISSION = "DELETE_PROFILE_PERMISSION"
export const DELETE_PROFILE_PERMISSION_SUCCESS = "DELETE_PROFILE_PERMISSION_SUCCESS"
export const DELETE_PROFILE_PERMISSION_FAIL = "DELETE_PROFILE_PERMISSION_FAIL"

