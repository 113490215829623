import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_PLAN_PROFILES,
  GET_PLAN_PROFILES_AVAILABLES,
  ADD_PLAN_PROFILE,
  DELETE_PLAN_PROFILE,
} from "./actionTypes";

import {
  getPlanProfilesSuccess,
  getPlanProfilesFail,

  getPlanProfilesAvailablesSuccess,
  getPlanProfilesAvailablesFail,

  addNewPlanProfileSuccess,
  addNewPlanProfileFail,

  deletePlanProfileSuccess,
  deletePlanProfileFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getPlanProfiles,
  getPlanProfilesAvailable,
  addNewPlanProfile,
  deletePlanProfile,
} from "helpers/backend_fazenda_helper";

function* onGetPlanProfiles({planId}) {
  try {
    const response = yield call(getPlanProfiles, planId);
    yield put(getPlanProfilesSuccess(response.data));
  } catch (error) {
    yield put(getPlanProfilesFail(error));
  }
}

function* onGetPlanProfilesAvailable({planId}) {
  try {
    const response = yield call(getPlanProfilesAvailable, planId);
    yield put(getPlanProfilesAvailablesSuccess(response.data));
  } catch (error) {
    yield put(getPlanProfilesAvailablesFail(error));
  }
}

function* onDeletePlanProfile({planId, profileId, payload}) {
  try {
    const response = yield call(deletePlanProfile, planId, profileId, payload);
    yield put(deletePlanProfileSuccess(response.data));
  } catch (error) {
    yield put(deletePlanProfileFail(error));
  }
}

function* onAddNewPlanProfile({planId, payload}) {
  try {
    const response = yield call(addNewPlanProfile, planId, payload);
    yield put(addNewPlanProfileSuccess(response.data));
  } catch (error) {
    yield put(addNewPlanProfileFail(error));
  }
}

function* PlanProfilesSaga() {
  yield takeEvery(GET_PLAN_PROFILES, onGetPlanProfiles);
  yield takeEvery(GET_PLAN_PROFILES_AVAILABLES, onGetPlanProfilesAvailable);
  yield takeEvery(ADD_PLAN_PROFILE, onAddNewPlanProfile);
  yield takeEvery(DELETE_PLAN_PROFILE, onDeletePlanProfile);
}

export default PlanProfilesSaga;
