import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_USERS,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
} from "./actionTypes";
import {
  getUsersFail,
  getUsersSuccess,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "helpers/backend_fazenda_helper";

function* fetchUsers() {
  try {
    const response = yield call(getUsers);
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* onUpdateUser({ payload: order }) {
  try {
    const response = yield call(updateUser, order);
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onDeleteUser({ payload: order }) {
  try {
    const response = yield call(deleteUser, order);
    console.log("response",response)
    yield put(deleteUserSuccess(response));
  } catch (error) {
    console.log("error",error)
    yield put(deleteUserFail(error));
  }
}

function* onAddNewUser({ payload: order }) {
  try {
    const response = yield call(addNewUser, order);
    yield put(addUserSuccess(response));
  } catch (error) {
    yield put(addUserFail(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export default userSaga;
