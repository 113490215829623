import { call, put, takeEvery } from "redux-saga/effects"

// Stocks Redux States
import { GET_STOCKS } from "./actionTypes"
import {
  getStocksSuccess,
  getStocksFail
} from "./actions"

//Include Both Helper File with needed methods
import { onGetStocks } from "helpers/backend_fazenda_helper"

function* fetchGetStocks() {
  try {
    const response = yield call(onGetStocks)
    yield put(getStocksSuccess(response.data))
  } catch (error) {
    yield put(getStocksFail(error))
  }
}


function* stocksSaga() {
  yield takeEvery(GET_STOCKS, fetchGetStocks)
}

export default stocksSaga
