import {
  GET_PROFILE_PERMISSIONS_SUCCESS,
  GET_PROFILE_PERMISSIONS_FAIL,

  GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS,
  GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL,

  ADD_PROFILE_PERMISSION_SUCCESS,
  ADD_PROFILE_PERMISSION_FAIL,
  
  DELETE_PROFILE_PERMISSION_SUCCESS,
  DELETE_PROFILE_PERMISSION_FAIL
} from "./actionTypes";

const INIT_STATE = {
  profilePermissions: [],
  permissionsAvailable: [],
  error: {},
};

const ProfilePermissions = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PROFILE_PERMISSIONS_SUCCESS:
      return {
        state,
        profilePermissions: action.payload,
      };

    case GET_PROFILE_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS:
      return {
        state,
        permissionsAvailable: action.payload,
      };

    case GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PROFILE_PERMISSION_SUCCESS:
      return {
        ...state,
        profilePermissions: [...state.profilePermissions, action.payload],
      };

    case ADD_PROFILE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PROFILE_PERMISSION_SUCCESS:
      return {
        ...state,
          profilePermissions: state.profilePermissions.filter(
          profilePermissions => profilePermissions.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PROFILE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ProfilePermissions;
