import React, { useEffect, useState, useRef, useMemo } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Table } from 'react-super-responsive-table';
import * as Yup from 'yup';
import { move, useFormik } from 'formik';
import classnames from 'classnames';
import DeleteModal from '../../components/Common/DeleteModal';
import * as url from '../../helpers/url_helper';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Form,
  Input,
  FormFeedback,
  Label,
  NavItem,
  NavLink
} from 'reactstrap';

import { currencyMask, sendBancoDadosPrice } from 'utils/inputMask';
import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import Message from 'components/Message';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import {
  getMovements as onGetMovements,
  addNewMovement as onAddNewMovement,
  updateMovement as onUpdateMovement,
  deleteMovement as onDeleteMovement,
  addProfileFail
} from 'store/actions';

import MovementsModal from './MovementsModal';
import axiosApi from 'helpers/api_helper';
import { formatPrice, formatDateBr } from 'utils/inputMask';
import { usePerPageList } from 'hooks/usePageList';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';
import { useFarmsStore } from 'zustand/farms/farms-store';

import { getAnimalsByFarm as onGetAnimalsByFarm } from 'store/actions';

const Movements = (props) => {
  const dispatch = useDispatch();

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [selectedAnimals, setSelectedAnimals] = useState(null);
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [movementList, setMovementList] = useState([]);
  const [movement, setMovement] = useState(null);
  const [collect, setCollect] = useState(null);
  const [collects, setCollects] = useState([]);
  const history = useHistory();
  const { movements } = useSelector((state) => ({ movements: state.Movement.movements }));
  const { animals } = useSelector((state) => ({ animals: state.Movement.animals }));
  const { error } = useSelector((state) => ({ error: state.Movement.error }));
  const [currentItem, setCurrentItem] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { allIrons: ironsWhenStore, getAllIron } = useFarmsStore();

  const optionsIron = useMemo(() => {
    return ironsWhenStore?.map((iron) => ({ value: iron.id, label: iron.name })) || [];
  }, [ironsWhenStore]);

  const configHeaderFile = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  useEffect(() => {
    if (currentItem && currentItem.farm_id) {
      dispatch(onGetAnimalsByFarm(currentItem.farm_id));
    }
  }, [currentItem]);

  useEffect(() => {
    setSelectedAnimals([
      {
        label: props.t('Animals'),
        options: animals.map((item) => ({
          label: `${item.earring}${item.earring_eletric ? ` - ${item.earring_eletric}` : ''}`,
          value: item.id
        }))
      }
    ]);
  }, [animals]);

  const toggleEditModal = () => setEditModalOpen(!editModalOpen);

  function handleSelectAnimal(selectedAnimal) {
    setSelectedAnimal(selectedAnimal);
  }

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setIsCreating(false);
    toggleEditModal();
  };

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    if (movements && !movements.length) {
      dispatch(onGetMovements());
    }
  }, []);

  useEffect(() => {
    if (collects && !collects.length) {
      getCollects();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(movements)) {
      setMovementList(movements);
    }
  }, [movements]);

  async function getCollects() {
    try {
      const response = await axiosApi.get(url.GET_COLLECTS);
      const { data } = response.data;

      setCollects(data);
    } catch (error) {
      console.log(error);
    }
  }

  const formatNumber = (value) => {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(cleanedValue / 100);
    return formattedValue;
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (movement && movement.id) || '',
      farm_id: (movement && movement.farm_id) || '',
      animal_id: (movement && movement.animal_id) || '',
      collect_id: (movement && movement.collect_id) || '',
      movement_type_id: (movement && movement.movement_type_id) || '',
      origin_farm_id: (movement && movement.origin_farm_id) || '',
      movement_name: (movement && movement.movement_name) || '',
      weight: (movement && movement.weight) || '',
      price: (movement && movement.price) || '',
      note: (movement && movement.note) || '',
      is_animal: (movement && movement.is_animal) || '',
      operation: (movement && movement.operation) || '',
      moves_animals: (movement && movement.moves_animals) || '',
      is_active: (movement && movement.is_active) || '',
      deleted_at: (movement && movement.deleted_at) || '',
      created_at: (movement && movement.created_at) || '',
      updated_at: (movement && movement.updated_at) || '',
      animal: (movement && movement.animal) || '',
      name: (movement && movement.name) || '',
      amount: (movement && formatPrice(movement.amount)) || '',
      freight: (movement && formatPrice(movement.freight)) || '',
      other_values: (movement && formatPrice(movement.other_values)) || '',
      arroba_price: (movement && formatPrice(movement.arroba_price)) || ''
      //   movementdate: (movement && movement.movementdate) || '',
      //   total: (movement && movement.total) || '',
      //   paymentStatus: (movement && movement.paymentStatus) || 'Paid',
      //   badgeclass: (movement && movement.badgeclass) || 'success',
      //   paymentMethod: (movement && movement.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Movement Id')

      //   billingName: Yup.string().required("Please Enter Your Billing Name"),
      //   movementdate: Yup.string().required("Please Enter Your Movement Date"),
      //   total: Yup.string().required("Total Amount"),
      //   paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      //   badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      //   paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateMovement = {
          id: movement ? movement.id : 0,
          farm_id: values.farm_id,
          animal_id: values.animal_id,
          collect_id: values.collect_id,
          movement_type_id: values.movement_type_id,
          origin_farm_id: values.origin_farm_id,
          movement_name: values.movement_name,
          weight: values.weight,
          price: values.price,
          note: values.note,
          is_animal: values.is_animal,
          operation: values.operation,
          moves_animals: values.moves_animals,
          name: values.name,
          amount: sendBancoDadosPrice(values.amount),
          freight: sendBancoDadosPrice(values.freight),
          other_values: sendBancoDadosPrice(values.other_values),
          arroba_price: sendBancoDadosPrice(values.arroba_price)
        };
        // update movement
        dispatch(onUpdateMovement(updateMovement));
        validation.resetForm();
        showingMessage('Moviment Updated successfully', 'success');
      } else {
        const newMovement = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name'],
          farm_id: ['farm_id'],
          animal_id: ['animal_id'],
          collect_id: ['collect_id'],
          movement_type_id: ['movement_type_id'],
          origin_farm_id: ['origin_farm_id'],
          movement_name: ['movement_name'],
          weight: ['weight'],
          price: ['price'],
          note: ['note'],
          is_animal: ['is_animal'],
          operation: ['operation'],
          moves_animals: ['moves_animals']
          //   is_active: ['is_active'],
          //   deleted_at: ['deleted_at'],
          //   created_at: ['created_at'],
          //   updated_at: ['updated_at'],

          //   billingName: values["billingName"],
          //   movementdate: values["movementdate"],
          //   total: values["total"],
          //   paymentStatus: values["paymentStatus"],
          //   paymentMethod: values["paymentMethod"],
          //   badgeclass: values["badgeclass"],
        };
        // save new movement
        dispatch(onAddNewMovement(newMovement));
        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  const selectRow = {
    mode: 'checkbox'
  };

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedItemCollect, setSelectedItemCollect] = useState(null);
  //pagination customization

  const { SearchBar } = Search;

  // const toggleModal = () => {
  //   setModal1(!modal1)
  // }
  const toggleViewModal = async (movement) => {
    if (movement.id) {
      setSelectedItemCollect(movement);
    }

    setModal1(!modal1);
  };

  const toLowerCase1 = (str) => {
    return str === '' || str === undefined ? '' : str.toLowerCase();
  };

  const MovementColumns = (toggleModal) => [
    {
      dataField: 'id',
      text: `${props.t('Id')}`,
      sort: true
    },
    {
      dataField: 'collect_id',
      text: `${props.t('Id collect')}`,
      sort: true
    },
    {
      dataField: 'earring',
      text: `${props.t('Earring')}`,

      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <> {row.earring || '--'} </>
    },
    {
      dataField: 'earring_eletric',
      text: `${props.t('Earring Eletric')}`,

      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <> {row.earring_eletric || '--'} </>
    },
    {
      dataField: 'movement_name',
      text: `${props.t('Movement Name')}`,
      sort: true,
      formatter: (cellContent, row) => <> {`${row.operation} - ${row.movement_name ?? ''}`} </>
    },
    {
      dataField: 'collect_name',
      text: `${props.t('Collect Name')}`,

      sort: true,
      formatter: (cellContent, row) => <> {`${row.collect_name ?? ''}`} </>
    },
    {
      dataField: 'note',
      text: `${props.t('Note')}`,

      sort: true
    },
    {
      dataField: 'weight',
      text: `${props.t('Weight')}`,

      sort: true,
      formatter: (cellContent, row) => formatPrice(row.weight, 'decimal')
    },
    {
      dataField: 'price',
      text: `${props.t('Price')}`,

      sort: true,
      formatter: (cellContent, row) => formatPrice(row.price)
    },
    {
      dataField: 'movement_date',
      text: `${props.t('Movement Date')}`,

      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at)
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: `${props.t('action')}`,

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, movement) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success" onClick={() => handleEditClick(movement)}>
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {props.t('Edit')}
              </UncontrolledTooltip>
            </Link>
            <Link to="#" className="text-danger" onClick={() => onClickDelete(movement)}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                {props.t('Delet')}
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      )
    }
  ];

  const columnsCollects = (toggleModal) => [
    {
      dataField: 'id',
      text: `${props.t('Id')}`
    },
    {
      dataField: 'farm',
      isDummyField: true,
      text: `${props.t('Farm')}`,
      formatter: (_cellContent, row) => {
        console.log({ row });
        return <>{row?.farm_name || 'Unknown'}</>;
      }
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`
    },
    {
      dataField: 'movement_name',
      text: `${props.t('Movement Name')}`,
      sort: true,
      formatter: (cellContent, row) => <> {`${row.operation} - ${row.name ?? ''}`} </>
    },
    {
      dataField: 'collect',
      text: `${props.t('Total Collect')}`,
      formatter: (cellContent, row) => {
        return row?.total_movements || 0;
      }
    },
    {
      dataField: 'amount',
      text: `${props.t('Amount')}`,

      formatter: (cellContent, row) => formatPrice(row.amount)
    },
    {
      dataField: 'freight',
      text: `${props.t('Freight')}`,

      formatter: (cellContent, row) => formatPrice(row.freight)
    },
    {
      dataField: 'other_values',
      text: `${props.t('Other values')}`,

      formatter: (cellContent, row) => formatPrice(row.other_values)
    },
    {
      dataField: 'arroba_price',
      text: `${props.t('Arroba price')}`,

      formatter: (cellContent, row) => formatPrice(row.arroba_price)
    },
    {
      dataField: 'movement_date',
      text: `${props.t('Movement date')}`,

      formatter: (cellContent, row) => formatDateBr(row.movement_date)
    },
    {
      dataField: 'view',
      isDummyField: true,
      text: `${props.t('Details')}`,
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, movement) => (
        <Can permissions={['detail_movements']}>
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => toggleViewModal(movement)}>
            {props.t('Details')}
          </Button>
        </Can>
      )
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, movement) => (
        <>
          <div className="d-flex gap-3">
            <Can permissions={['edit_movements']}>
              <Link to="#" className="text-success" onClick={() => handleMovementClick(movement)}>
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t('Edit')},
                </UncontrolledTooltip>
              </Link>
            </Can>
            <Can permissions={['destroy_movements']}>
              <Link to="#" className="text-danger" onClick={() => onClickDelete(movement)}>
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t('delet')},
                </UncontrolledTooltip>
              </Link>
            </Can>
          </div>
        </>
      )
    }
  ];

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Movement ${action} Successfully`, 'success');
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setMovement(null);
    } else {
      setModal(true);
    }
  };

  const handleMovementClick = (arg) => {
    const movement = arg;

    setMovement({
      id: movement.id,
      name: movement.name,
      amount: movement.amount,
      freight: movement.freight,
      other_values: movement.other_values,
      arroba_price: movement.other_values
    });

    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete movement
  const [deleteModal, setDeleteModal] = useState(false);

  const handleSaveEdit = async () => {
    if (isCreating) {
      // setLocalMovements((prevMovements) => [
      //   ...prevMovements,
      //   { ...currentItem, id: Date.now() } // Assigning a temporary ID for new item
      // ]);
    } else {
      console.log(currentItem);
      if (currentItem.operation != 'E') {
        const animalFilter = animals.filter((animal) => animal.id == currentItem.animal_id);
        currentItem.earring = animalFilter[0].earring;
        currentItem.earring_eletric = animalFilter[0].earring_eletric;
      }
      const currentItemConverted = {
        ...currentItem,
        weight: sendBancoDadosPrice(currentItem.weight)
      };
      setMovementList((prevMovements) =>
        prevMovements.map((item) => (item.id === currentItem.id ? currentItemConverted : item))
      );
      console.log(currentItemConverted);
      try {
        const response = await axiosApi.post(
          `${url.UPDATE_MODAL_MOVEMENTS}/${currentItemConverted.id}`,
          currentItemConverted,
          configHeaderFile
        );
        const { message } = response.data;
        toast.success(message);
      } catch (error) {
        console.log(error);
        const { message } = error.response.data;
      }
    }
    toggleEditModal();
  };

  const onClickDelete = (movement) => {
    setMovement(movement);
    setDeleteModal(true);
  };

  const handleDeleteMovement = () => {
    if (movement.id) {
      dispatch(onDeleteMovement(movement));
      onPaginationPageChange(1);
      setDeleteModal(false);

      showingMessage('Movement Deleted successfully', 'success');
    }
  };
  const handleMovementClicks = () => {
    //link to /movements/create
    history.push('/movements/create');

    // setMovementList("");
    // setIsEdit(false);
    // toggle();
  };

  const handleImportMovement = () => {
    history.push('/movements/import');
  };

  const handleMovementMonitoring = () => {
    //link to /movements/create
    history.push('/movements/monitoring');

    // setMovementList("");
    // setIsEdit(false);
    // toggle();
  };

  const handleMovementExit = () => {
    //link to /movements/create
    history.push('/movements/exit');

    // setMovementList("");
    // setIsEdit(false);
    // toggle();
  };

  const handleMovementTrasnfer = () => {
    //link to /movements/create
    history.push('/movements/transfer');

    // setMovementList("");
    // setIsEdit(false);
    // toggle();
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD/MM/Y');
    return date1;
  };

  const defaultSorted = [
    {
      dataField: 'name',
      movement: 'desc'
    }
  ];

  const pageOptions = {
    totalSize: activeTab === '1' ? collects.length : movements.length, // replace later with size(movements),
    custom: true
  };

  return (
    <React.Fragment>
      <Toaster />
      <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>
          {isCreating ? props.t('Add Item') : props.t('Edit Item')}
        </ModalHeader>
        <ModalBody>
          {currentItem && (
            <Form>
              {currentItem.operation == 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring')}</Label>
                  <Input
                    type="text"
                    id="earring"
                    value={currentItem.earring}
                    onChange={(e) => setCurrentItem({ ...currentItem, earring: e.target.value })}
                  />
                </FormGroup>
              )}
              {currentItem.operation != 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring')}</Label>
                  <Select
                    defaultValue={{
                      value: currentItem.animal_id,
                      label: `${currentItem.earring} ${
                        currentItem.earring_eletric ? currentItem.earring_eletric : ''
                      }`
                    }}
                    onChange={(e) => {
                      handleSelectAnimal();
                      setCurrentItem({ ...currentItem, animal_id: e.value });
                    }}
                    options={selectedAnimals}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              )}
              {currentItem.operation == 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring Eletric')}</Label>
                  <Input
                    type="text"
                    id="earring"
                    value={currentItem.earring_eletric}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, earring_eletric: e.target.value })
                    }
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Label for="weight">{props.t('Weight')}</Label>
                <Input
                  type="text"
                  id="weight"
                  value={formatNumber(currentItem.weight)}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, weight: formatNumber(e.target.value) })
                  }
                />
              </FormGroup>
              {currentItem.operation == 'E' && (
                <FormGroup>
                  <Label for="iron_name">{props.t('Iron')}</Label>
                  <Select
                    defaultValue={{ value: currentItem.iron_id, label: currentItem.iron_name }}
                    onChange={(e) => setCurrentItem({ ...currentItem, iron_id: e.value })}
                    options={optionsIron}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Label for="note">{props.t('Note')}</Label>
                <Input
                  type="textarea"
                  id="note"
                  value={currentItem.note}
                  onChange={(e) => setCurrentItem({ ...currentItem, note: e.target.value })}
                />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditModal}>
            {props.t('Cancel')}
          </Button>
          <Button color="primary" onClick={handleSaveEdit}>
            {props.t('Save')}
          </Button>
        </ModalFooter>
      </Modal>

      <Message title={messageAlert} type={typeMessage} />

      <MovementsModal isOpen={modal1} toggle={toggleViewModal} movement={selectedItemCollect} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteMovement}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <MetaTags>
          <title>Movements | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Movements')} breadcrumbItem={props.t('Movements')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={activeTab === '1' ? columnsCollects(toggle) : MovementColumns(toggle)}
                    data={activeTab === '1' ? collects : movementList}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={
                          activeTab === '1' ? columnsCollects(toggle) : MovementColumns(toggle)
                        }
                        data={activeTab === '1' ? collects : movementList}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['entry_movements']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleMovementClicks}>
                                      {props.t('Entry')}
                                    </Button>
                                  </Can>
                                  <Can permissions={['exit_movements']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleMovementExit}>
                                      {props.t('Exit')}
                                    </Button>
                                  </Can>
                                  <Can permissions={['transfer_movements']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleMovementTrasnfer}>
                                      {props.t('Transfer')}
                                    </Button>
                                  </Can>
                                  <Can permissions={['available_movements']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleMovementMonitoring}>
                                      {props.t('Evaluate')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <ul className="nav nav-tabs nav-tabs-custom" style={{ zIndex: 1 }}>
                              <NavItem style={{ marginRight: '10px' }}>
                                {/* <div className="text-sm-end">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                              </NavItem>
                              <NavItem>
                                <Can permissions={['colletc_movements']}>
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === '1'
                                    })}
                                    onClick={() => {
                                      toggleTab('1');
                                    }}>
                                    {props.t('Collects')}
                                  </NavLink>
                                </Can>
                              </NavItem>
                              <NavItem>
                                <Can permissions={['breed_movements']}>
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === '2'
                                    })}
                                    onClick={() => {
                                      toggleTab('2');
                                    }}>
                                    {props.t('Earring')}
                                  </NavLink>
                                </Can>
                              </NavItem>
                            </ul>

                            <Row>
                              <Col xl="12">
                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns">
                                  <Table id="farms-1">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bmovemented={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={'table table-striped table-bordered'}
                                      headerWrapperClasses={'table-light'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      ref={node}
                                    />
                                  </Table>
                                </div>
                                <div></div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Movement' : 'Add Movement'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Name Collect')}
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Amount')}
                                            </Label>
                                            <Input
                                              name="amount"
                                              type="text"
                                              validate={{
                                                required: { value: true }
                                              }}
                                              onChange={(e) =>
                                                validation.handleChange(currencyMask(e))
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.amount
                                                  ? currencyMask(validation.values.amount)
                                                  : ''
                                              }
                                              invalid={
                                                validation.touched.amount &&
                                                validation.errors.amount
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.amount &&
                                            validation.errors.amount ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.amount}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Freight')}
                                            </Label>
                                            <Input
                                              name="freight"
                                              type="text"
                                              // value={movementList.movementdate || ""}
                                              onChange={(e) =>
                                                validation.handleChange(currencyMask(e))
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.freight
                                                  ? currencyMask(validation.values.freight)
                                                  : ''
                                              }
                                              invalid={
                                                validation.touched.freight &&
                                                validation.errors.freight
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.freight &&
                                            validation.errors.freight ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.freight}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Other Values')}
                                            </Label>
                                            <Input
                                              name="other_values"
                                              type="text"
                                              onChange={(e) =>
                                                validation.handleChange(currencyMask(e))
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.other_values
                                                  ? currencyMask(validation.values.other_values)
                                                  : ''
                                              }
                                              invalid={
                                                validation.touched.other_values &&
                                                validation.errors.other_values
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.other_values &&
                                            validation.errors.other_values ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.other_values}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Arroba Price')}
                                            </Label>
                                            <Input
                                              name="arroba_price"
                                              type="text"
                                              onChange={(e) =>
                                                validation.handleChange(currencyMask(e))
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.arroba_price
                                                  ? currencyMask(validation.values.arroba_price)
                                                  : ''
                                              }
                                            />
                                            {validation.touched.arroba_price &&
                                            validation.errors.arroba_price ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.arroba_price}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user">
                                              {props.t('Save')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </Col>
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Movements.propTypes = {
  movements: PropTypes.array,
  onGetMovements: PropTypes.func,
  onAddNewMovement: PropTypes.func,
  onDeleteMovement: PropTypes.func,
  onUpdateMovement: PropTypes.func
};

export default withTranslation()(Movements);
